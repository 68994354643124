import axios from "axios";

import Helpers from "../Components/Helpers/Helpers";

const servicesFetch = axios.create({
  baseURL: Helpers.config.api.url,
  params: { jwt: Helpers.localStorage.getJWT() },
});

export const servicesRequests = {
  get: (id = '') => {
    return servicesFetch
      .get(
        `/services/${id}`
      )
      .then((response) => {
        return response.data;
      });
  },

  post: (service) => {
    return servicesFetch
      .post(
        `/services/`,
        service
      )
      .then((response) => {
        return response.data;
      });
  },

  put: (id, service) => {
    return servicesFetch
      .put(
        `/services/${id}`,
        service
      )
      .then((response) => {
        return response.data;
      });
  },

  delete: (id) => {
    return servicesFetch
      .delete(
        `/services/${id}`
      )
      .then((response) => {
        return response.data;
      });
  },
};

export default servicesFetch;
