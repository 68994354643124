/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { RenderModalImage } from "../modals/NewMessage";
import Controllers from "../../Api/Controllers";
import { Select } from "../Form/Form";
import SwitchTemplates from "./SwitchTemplates";
import ProgramButton from "../../Views/MultiAgent/program-button";
import useGetTemplates from "../../Views/templates/hooks/use-get-templates";

export default function Reactivate(props) {
  const [switchActive, setSwitchActive] = useState("1");
  const [idTemplate, setIdTemplate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reactivate, setReactivate] = useState(false);
  const [viewImage, setViewImage] = useState({
    enabled: false,
    imageUrl: "",
  });
  const [templateEdit, setTemplateEdit] = useState({
    value: "",
    label: "",
  });

  const {data: listTemplates} = useGetTemplates({
    templateType: switchActive === '1' ? 'all' : 'rich_text_params',
    arrayMap: true,
  })

  const { sendMessageReact, currentChat, updateData } = props;

  const _handleSubmitReactivate = async (p_params) => {
    try {
      setReactivate(false);
      setIsLoading(true);
      let body = {
        p_number: currentChat.number_chat,
      };
      if (p_params) {
        body.p_params = p_params;
        body.p_id_plantilla = templateEdit.value;
      } else {
        body.p_id_plantilla = idTemplate;
      }
      await Controllers.chat.whatsapp_new_message(body);
    } catch (error) {
      console.error(error);
    } finally {
      handleClose();
      updateData(() => setIsLoading(false));
    }
  };

  const _handleSubmitReactEdit = () => {
    const params = document.querySelectorAll(".template-div-input");
    const p_params = Array.from(params).map((item) => ({
      data: item.innerText,
    }));
    _handleSubmitReactivate(p_params);
  };

  const handleChangeTemplate = (template) => {
    setTemplateEdit(template);
    const element = document.getElementById("template-edit-input");
    let message = template.label.replaceAll(
      "{{",
      "<div contenteditable class='template-div-input' >"
    );
    message = message.replaceAll("}}", "</div>");
    element.innerHTML = message;
  };

  const template = useCallback(() => {
    return listTemplates.find((item) => item.value === idTemplate);
  }, [idTemplate, listTemplates]);

  const handleClose = (cb) => {
    setTemplateEdit({
      value: "",
      label: "",
    });
    setReactivate(false);
    setSwitchActive("1");
    setIdTemplate("");
    if (cb) {
      cb();
    }
  };

  if (sendMessageReact === 1) {
    return (
      <label className="text text-info">Esperando respuesta del cliente</label>
    );
  }

  if (reactivate) {
    return (
      <>
        <RenderModalImage
          {...viewImage}
          onClose={() => {
            setViewImage({
              enabled: false,
              imageUrl: "",
            });
          }}
        />
        <div className="reactivate-switch">
          {/* {templatesEdit().length > 0 && ( */}
            <SwitchTemplates value={switchActive} onChange={setSwitchActive} />
          {/* )} */}
        </div>
        {switchActive === "1" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginRight: "auto",
            }}
          >
            {template()?.imagen && (
              <img
                onClick={() => {
                  setViewImage({
                    enabled: true,
                    imageUrl: template().imagen,
                  });
                }}
                style={{
                  width: 45,
                  height: 45,
                  cursor: "pointer",
                  objectFit: "contain",
                }}
                src={template().imagen}
                alt="image preview"
              />
            )}
            {template()?.files && (
              <strong>
                <a
                  href={template().files}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    cursor: "pointer",
                    padding: 5,
                    margin: 5,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    class="bi bi-filetype-pdf"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                    />
                  </svg>
                </a>
              </strong>
            )}
            <Select
              options={listTemplates}
              menuPlacement="top"
              stylesProps={{
                width: "100%",
                margin: "0 10px",
              }}
              placeholder="Seleccionar plantilla para reactivar"
              onChange={(e) => setIdTemplate(e.value)}
              value={listTemplates.find((item) => item.value === idTemplate)}
            />
            <Button
              style={{
                width: 35,
                minWidth: 35,
                maxWidth: 35,
                height: 35,
                minHeight: 35,
                maxHeight: 35,
                padding: 0,
                margin: "auto 3px",
                borderRadius: 5,
              }}
              onClick={() => _handleSubmitReactivate()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-send-fill"
                viewBox="0 0 16 16"
              >
                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
              </svg>
            </Button>
            <Button
              style={{
                width: 35,
                minWidth: 35,
                maxWidth: 35,
                height: 35,
                minHeight: 35,
                maxHeight: 35,
                padding: 0,
                margin: "auto 3px",
                borderRadius: 5,
              }}
              onClick={() => handleClose(setIsLoading(false))}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Button>
            <ProgramButton
              currentChat={currentChat}
              templates={listTemplates}
              messages={currentChat.messages}
            />
          </div>
        ) : (
          <div style={{ display: "grid", position: "relative" }}>
            <Select
              options={listTemplates}
              classNameParent="col-12 mb-2 px-0"
              menuPlacement="top"
              placeholder="Seleccionar Plantilla para editar"
              onChange={(value) => {
                handleChangeTemplate(value);
              }}
              value={templateEdit}
            />
            <div className={templateEdit.label ? "contain-templates-ab" : ""}>
              {templateEdit.label && (
                <Select
                  options={listTemplates}
                  classNameParent="col-12 mb-2 px-0"
                  menuPlacement="top"
                  placeholder="Seleccionar Plantilla para editar"
                  onChange={(value) => {
                    handleChangeTemplate(value);
                  }}
                  value={templateEdit}
                />
              )}
              <div
                id="template-edit-input"
                className={
                  templateEdit.label ? "contain-template-absolute" : ""
                }
              ></div>
              {templateEdit.label && (
                <>
                  <Button
                    style={{
                      padding: "0 10px",
                      margin: "5px 3px",
                      borderRadius: 5,
                    }}
                    onClick={() => _handleSubmitReactEdit()}
                  >
                    Reactivar
                  </Button>
                  <Button
                    style={{
                      padding: "0 10px",
                      margin: "5px 3px",
                      borderRadius: 5,
                    }}
                    onClick={() => handleClose(setIsLoading(false))}
                  >
                    Cancelar
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <Button
      block
      style={{
        margin: "0 auto",
      }}
      disabled={isLoading}
      onClick={() => (isLoading ? null : setReactivate(true))}
    >
      {isLoading ? "Loading…" : "Reactivar conversación"}
    </Button>
  );
}
