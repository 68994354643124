import React, { useState, useEffect } from 'react'
// mui
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

export default function SavedAnswerButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const {options, onClick} = props;

  useEffect(() => {
    if (open) {
      setSearch('')
    }
  }, [open])

  const handleOpen = () => setOpen(true)
  
  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleOpen();
  };

  const handleChange = (event) => {
    setSearch(event.target.value)
  }

  return (
    <div>
      <IconButton
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.6)', 
          color: '#ffffff', 
          fontSize: 10, 
          px: 1, 
          height: 1, 
          borderRadius: 1,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)', 
          }
        }}
        onClick={handleClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
        </svg>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <TextField
          label="Buscar..."
          value={search}
          color="warning"
          onChange={handleChange}
          sx={{ my: 1, mx: 2, width: .9, minWidth: 250}}
          size="small"
          
        />
        <Stack
          sx={{
            overflow: 'auto',
            maxHeight: "70vh"
          }}
        >
          {options
            .filter(item => item.description_file.toLowerCase().includes(search.toLowerCase()))
            .map((file, i) => (
              <>
                <MenuItem 
                  onClick={() => {
                    handleClose();
                    onClick(file);
                  }} 
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)'
                    }
                  }}
                  key={i}
                >
                  {file.description_file}
                </MenuItem>
                <Divider sx={{}} />
              </>
          ))}
        </Stack>
      </Menu>
    </div>
  )
}
