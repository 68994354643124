import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
// import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
// import SweetAlert from '../../Components/SweetAlert/SweetAlert'
// import { useParams } from 'react-router-dom';
import { FormCustom, Input } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder/lib';
import { useSelector } from 'react-redux';

const Lead = ({ typeBot }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [ready, setReady] = useState(true)
    const [projects, setProjects] = useState([])
    const [formFilter, setFormFilter] = useState({
        p_id_project: '',
        p_date_start: Helpers.date.get({ format: '%Y-%m-%d' }),
        p_date_end: Helpers.date.get({ format: '%Y-%m-%d' }),
        p_type_bot: typeBot.toUpperCase()
    })
    const [file, setFile] = useState({
        file: '',
        filename: ''
    })

    const masterUser = useSelector(store => store.session.master_user)

    const project = useMemo(() => {
        if (formFilter.p_id_project == '') {
            return {
                label: 'Todas',
                value: ''
            }
        }
        
        let finded = projects.find(j => parseInt(j.id_project) === parseInt(formFilter.p_id_project))

        if (!finded) {
            return {
                label: 'Todas',
                value: ''
            }
        }

        return {
            label: finded.name_project,
            value: finded.id_project
        }
    }, [formFilter.p_id_project, projects])

    useEffect(() => {
        setFormFilter({
            p_id_project: '',
            p_date_start: Helpers.date.get({ format: '%Y-%m-%d' }),
            p_date_end: Helpers.date.get({ format: '%Y-%m-%d' }),
            p_type_bot: typeBot.toUpperCase()
        })
        setReload(true)
    }, [typeBot])
    
    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.lead.get_lead_option(formFilter).then(res => {
                setRows(res.data.data)
                setFile({
                    file: res.data.file,
                    filename: res.data.filename
                })
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload, formFilter])

    const dtRows = useMemo(() => {
        let headTable = [
            { name: 'date_created', text: 'Fecha Registro' },
            { name: 'name_lead', text: 'Nombres' },
            { name: 'id_project', text: 'Opción', align: 'center' },
            { name: 'extra_fields', text: 'Nombre Empresa', align: 'center' },
            { name: 'qty_rooms', text: 'Cantidad Proyectos', align: 'center' },
            { name: 'email_lead', text: 'Email', align: 'center' },
            { name: 'phone_lead', text: 'Telefono', align: 'center' },
            { name: 'comments', text: 'Comentarios', align: 'center' },
            /* { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                return <div>
                    <Button variant="info" className="mr-1" size="xs" data-id-customers-lead={u.id_customers_lead}>
                        <i className="fa fa-edit"></i>
                    </Button>
                </div>
            } }, */
        ]

        if (masterUser) {
            headTable.unshift({ name: 'name_customers', text: 'Cliente', align: 'center' })
        }
        return <ReactDataTableBootstrap
            head={[
                headTable
            ]}
            orderDirection="desc"
            rows={rows}

            isProcessing={isProcessing}
        />
    }, [rows, isProcessing])

    return <>
        <Card className="mb-4">
            <Card.Header className="justify-content-between">
                <span>Filtrar Por Fechas</span>
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} rows={5} showLoadingAnimation className="mb-4">
                    <FormCustom onSubmit={_ => setReload(true)} viewSubmit={false} className="mb-4">
                        <Input
                            icon="far fa-calendar-alt"
                            text="Fecha Inicio"
                            type="date"
                            required
                            classNameParent="col-12 col-md-6"
                            value={formFilter.p_date_start}
                            onChange={e => setFormFilter({ ...formFilter, p_date_start: e.currentTarget.value })}
                        />
                        <Input
                            icon="far fa-calendar-alt"
                            text="Fecha Fin"
                            type="date"
                            required
                            classNameParent="col-12 col-md-6"
                            append={[
                                <Button type="submit">
                                    <i className="fa fa-search mt-1"></i> Filtrar Información
                                </Button>
                            ]}
                            value={formFilter.p_date_end}
                            onChange={e => setFormFilter({ ...formFilter, p_date_end: e.currentTarget.value })}
                        />
                    </FormCustom>
                </ReactPlaceholder>
            </Card.Body>
        </Card>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Leads De {Helpers.string.toCamelCase(typeBot)}</span>
                {rows.length ? <Button size="sm" variant="success" download={file.filename} href={file.file}><i className="fa fa-file-excel"></i> Descargar Reporte</Button> : ''}
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
    </>
}

export default Lead