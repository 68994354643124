import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import Controllers from '../../Api/Controllers'
import { Input, SwitchToggle, FormCustom, Select } from '../../Components/Form/Form'
import ReactPlaceholder from 'react-placeholder'
import { useSelector } from 'react-redux'
import Button from 'react-bootstrap/esm/Button'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify'

const CustomersContactForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_customers_contact: data.id_customers_contact,
        p_name_contact: data.name_contact,
        p_email_contact: data.email_contact,
        p_phone_contact: data.phone_contact,
        p_enabled_contact: data.enabled_contact,
        p_id_customers: data.id_customers,
        p_password_contact: data.password_contact,
        p_id_profile: data.id_profile,
        p_id_job: data.id_job,
        p_is_agent: data.is_agent,
        p_name_short: data.name_short,
        p_master_user: data.master_user
    })

    const [typePassword, setTypePassword] = useState('password')
    const masterUser = useSelector(store => store.session.master_user)
    const [ready, setReady] = useState(false)
    const [customers, setCustomers] = useState([])
    const [jobs, setJobs] = useState([])
    const [profiles, setProfiles] = useState([])

    const store = useSelector(store => store.session)

    useEffect(() => {
        if (masterUser && isNaN(parseInt(data.id_customers_contact))) {
            Promise.all([
                Controllers.customers.get_customers_public(),
                Controllers.job.get_job(),
                Controllers.profile.get_profile()
            ])
            .then(res => {
                setCustomers(res[0].data)
                setJobs(res[1].data)
                setProfiles(res[2].data)
                
                setReady(true)
            })
        } else {
            Promise.all([
                Controllers.job.get_job(),
                Controllers.profile.get_profile()
            ])
            .then(res => {
                setJobs(res[0].data)
                setProfiles(res[1].data)

                setReady(true)
            })
        }
    }, [])

    const jobSelected = useCallback(() => {
        if (!row.p_id_job) {
            return ''
        }

        let finded = jobs.find(j => parseInt(j.id_job) === parseInt(row.p_id_job))

        if (!finded) {
            return ''
        }

        return {
            label: (masterUser ? finded.name_customers + ' - ' : '') + finded.description_job,
            value: finded.id_job
        }
    }, [row.p_id_job, jobs])

    const profileSelected = useCallback(() => {
        if (!row.p_id_profile) {
            return ''
        }

        let finded = profiles.find(j => parseInt(j.id_profile) === parseInt(row.p_id_profile))

        if (!finded) {
            return ''
        }

        return {
            label: (masterUser ? finded.name_customers + ' - ' : '') + finded.description_profile,
            value: finded.id_profile
        }
    }, [row.p_id_profile, profiles])

    return <ReactPlaceholder rows={15} showLoadingAnimation ready={ready}>
        <FormCustom dataSubmit={row} onSubmit={({ data }) => {
            if (!row.p_id_profile) {
                Helpers.toast.construct({ response: 'warning', message: 'Seleccionar perfil', toast })
                return
            }

            if (!row.p_id_job) {
                Helpers.toast.construct({ response: 'warning', message: 'Seleccionar puesto de trabajo', toast })
                return
            }

            onSubmit({ data })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            {masterUser ? (isNaN(parseInt(data.id_customers_contact))? <Select
                options={customers.map(c => {
                    return {
                        label: c.name_customers,
                        value: c.id_customers
                    }
                })}
                text="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                onChange={e => setRow({ ...row, p_id_customers: e.value })}
            /> : <Input
                text="Cliente"
                placeholder="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                readOnly
                defaultValue={data.name_customers}
            />) : ''}
            <Input
                text="Nombre corto"
                placeholder="Nombre corto"
                classNameParent="col-12 mb-2"
                icon="far fa-user"
                invalid="El campo es obligatorio."
                defaultValue={data.name_short}
                onChange={e => setRow({ ...row, p_name_short: e.currentTarget.value })}
                maxLength={255}
            />
            <Input
                text="Nombres"
                placeholder="Nombres"
                classNameParent="col-12 mb-2"
                icon="far fa-user"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.name_contact}
                onChange={e => setRow({ ...row, p_name_contact: e.currentTarget.value })}
                maxLength={255}
            />
            <Input
                text="Telefono"
                placeholder="Telefono"
                classNameParent="col-12 mb-2"
                icon="fa fa-phone"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.phone_contact}
                onChange={e => setRow({ ...row, p_phone_contact: e.currentTarget.value })}
                maxLength={255}
            />
            <Input
                type={masterUser ? 'text' : 'email'}
                text="Email"
                placeholder="Email"
                classNameParent="col-12 mb-2"
                icon="far fa-envelope"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.email_contact}
                onChange={e => setRow({ ...row, p_email_contact: e.currentTarget.value })}
                maxLength={255}
            />
            <Input
                type={typePassword}
                text="Password"
                placeholder="Password"
                classNameParent="col-12 mb-2"
                icon="fa fa-lock"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.password_contact}
                onChange={e => setRow({ ...row, p_password_contact: e.currentTarget.value })}
                maxLength={30}
                append={[
                    <Button onMouseEnter={_ => setTypePassword('text')}
                    onMouseLeave={_ => setTypePassword('password')}>
                        <i className="fa fa-eye"></i>
                    </Button>
                ]}
            />
            <Select
                text="Puesto de Trabajo"
                classNameParent="col-12 mb-2"
                value={jobSelected()}
                onChange={e => setRow({ ...row, p_id_job: e.value })}
                options={jobs.map(j => {
                    return {
                        label: (masterUser ? j.name_customers + ' - ' : '') + j.description_job,
                        value: j.id_job
                    }
                })}
            />
            <Select
                text="Perfil"
                classNameParent="col-12 mb-2"
                value={profileSelected()}
                onChange={e => setRow({ ...row, p_id_profile: e.value })}
                options={profiles.map(j => {
                    return {
                        label: (masterUser ? j.name_customers + ' - ' : '') + j.description_profile,
                        value: j.id_profile
                    }
                })}
            />
            <SwitchToggle
                id="enabled-contact"
                defaultChecked={parseInt(data.enabled_contact) === 1}
                onChange={e => setRow({ ...row, p_enabled_contact: e.currentTarget.checked ? 1 : 0 })}
            />
            <SwitchToggle
                id="agent-contact"
                defaultChecked={parseInt(data.is_agent) === 1}
                textCheck="Agente"
                textUnChecked="No es agente"
                onChange={e => setRow({ ...row, p_is_agent: e.currentTarget.checked ? 1 : 0 })}
            />
            {parseInt(store.id_customers) === 1 ? <SwitchToggle
                id="master-customers"
                defaultChecked={parseInt(data.master_user) === 1}
                onChange={e => setRow({ ...row, p_master_user: e.currentTarget.checked ? 1 : 0 })}
                textCheck="Es revendedor"
                textUnChecked="No es revendedor"
            /> : ''}
        </FormCustom>
    </ReactPlaceholder>
}

CustomersContactForm.defaultProps = {
    data: {
        id_customers_contact: '',
        name_contact: '',
        email_contact: '',
        phone_contact: '',
        enabled_contact: '0',
        id_customers: '',
        name_customers: '',
        password_contact: '',
        id_profile: '',
        id_job: '',
        is_agent: '0',
        name_short: '',
        master_user: 0
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default CustomersContactForm;