import React, { useCallback, useState } from "react";
import Controllers from "../../Api/Controllers";

export default function Pending(props) {
  const {
    message,
    date_message,
    id_template,
    templates,
    id_schedule_message,
    update,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const template = useCallback(() => {
    return (
      templates.find((item) => item.value === id_template)?.label || message
    );
  }, [id_template, message, templates]);

  const deleteMsgPending = async () => {
    setIsLoading(true);
    Controllers.chat
      .delete_pending({ pending_id: id_schedule_message })
      .then(() => update(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="d-flex justify-content-end">
      <div
        style={{
          backgroundColor: "#FFF5CC",
          justifyContent: "end",
          position: "relative",
          alignItems: "center",
          // textAlign: "center",
          display: "flex",
          // width: "80%",
          marginLeft: "auto",
        }}
        className="p-2 rounded mb-2"
      >
        <span
          className="text-muted"
          style={{
            color: "#5b5b5b",
            fontSize: "9px",
            marginRight: 5,
          }}
        >
          {isLoading
            ? "Eliminado mensaje programado"
            : `${template()} ${date_message}`}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          fill="currentColor"
          className="bi bi-clock"
          viewBox="0 0 16 16"
        >
          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
        </svg>
        {isLoading ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-clockwise"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
            />
            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
          </svg>
        ) : (
          <div
            onClick={() => deleteMsgPending()}
            style={{
              color: "red",
              borderRadius: "50%",
              padding: "2px 5px",
              marginLeft: 4,
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
