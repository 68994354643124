import React from "react";

export default function ButtonArrow(props) {
  const { onClick, classNameParent } = props;

  const styles = {
    width: "35px",
    maxWidth: "35px",
    minWidth: "35px",
    border: "0",
    backgroundColor: "transparent",
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <button onClick={onClick} style={styles} className={classNameParent}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-arrow-left"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
        />
      </svg>
    </button>
  );
}
