import React, { useState, useEffect, useCallback } from "react";
import { servicesRequests } from "../../Api/servicesRequests";
import AddService from "../../Components/services/AddService";
import EditService from "../../Components/services/EditService";
import TableServiceItem from "../../Components/services/TableServiceItem";
import TableServices from "../../Components/services/TableServices";
import ServicesContainer from "./ServicesContainer";
import ServicesHeader from "./ServicesHeader";

function Services() {
  const [services, setServices] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [serviceToEdit, setServiceToEdit] = useState({});

  const deleteService = useCallback(
    (id) => {
      servicesRequests.delete(id).then((res) => {
        setServices(services.filter((service) => service.id !== id));
      });
    },
    [services]
  );

  const editService = (e) => {
    e.preventDefault();
    setShowEdit(false);
    servicesRequests.put(serviceToEdit.id, serviceToEdit).then((res) => {
      setServices(
        services.map((service) =>
          service.id === serviceToEdit.id ? serviceToEdit : service
        )
      );
    });
  };

  useEffect(() => {
    servicesRequests.get().then((res) => {
      setServices(res);
    });
  }, []);

  return (
    <>
      <ServicesContainer>
        <div className={`${showAdd || showEdit ? "col-md-8" : "col-md-12"}`}>
          <div className="card">
            <div className="card-body">
              <ServicesHeader>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setShowEdit(false);
                    setShowAdd((pv) => !pv)
                  }}
                >
                  <i className="fas fa-plus"></i>
                </button>
              </ServicesHeader>
              <TableServices>
                {services?.map((service) => (
                  <TableServiceItem
                    key={service.id}
                    service={service}
                    deleteService={deleteService}
                    setShowEdit={setShowEdit}
                    setServiceToEdit={setServiceToEdit}
                    setShowAdd={setShowAdd}
                  />
                ))}
              </TableServices>
            </div>
          </div>
        </div>
        {showAdd || showEdit ? (
          <div className="col-md-4">
            {showEdit ? (
              <EditService service={serviceToEdit} setService={setServiceToEdit} editService={editService} />
            ) : (
              <AddService setServices={setServices} />
            )}
          </div>
        ) : null}
      </ServicesContainer>
    </>
  );
}

export default Services;
