import ReactDOM from "react-dom";

export default function Portal(props) {
  return ReactDOM.createPortal(
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        justifyContent: "center",
        zIndex: 1000,
        alignItems: "center",
        position: "fixed",
        display: "flex",
        bottom: 0,
        right: 0,
        left: 0,
        top: 0,
      }}
    >
      {props.children}
    </div>,
    document.getElementById("portal")
  );
}
