import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { Link, useParams } from 'react-router-dom';
import { FormCustom, Input, Select } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder/lib';
import { useSelector } from 'react-redux';
import { PieChart } from 'react-minimal-pie-chart';
import Feed from './Feed';

const Comment = _ => {
    const [rows, setRows] = useState([])
    const [ready, setReady] = useState(false)
    const [rowsSliceNumber, setRowsSliceNumber] = useState(0)

    const [projects, setProject] = useState([])
    const [groups, setGroups] = useState([])
    const [contacts, setContacts] = useState([])

    useEffect(() => {
        if (!ready) {
            Promise.all([
                Controllers.comment.get_feed_customers(),
                Controllers.project.get_project(),
                Controllers.group.get_group_public(),
                Controllers.customers_contact.get_customers_contact(),
            ]).then(res => {
                setRows([
                    ...res[0].data
                ])

                let half = res[0].data.length / 2
                if (half - parseInt(half) !== 0) {
                    half++
                    half = parseInt(half)
                }
                
                setRowsSliceNumber(half)
                setProject(res[1].data)
                setGroups(res[2].data)
                setContacts(res[3].data)
                setReady(true)
            }).catch(_ => setReady(true))
        }
    }, [ready])

    const changeResponse = e => {
        let { response, id } = e.currentTarget.dataset

        Controllers.comment.feed_response_update({
            p_response: response,
            p_id_feed: id
        })

        setRows(rows.map(f => {
            if (parseInt(f.id_feed) === parseInt(id)) {
                f.response = response
            }
            return f
        }))
    }

    const changeProject = ({ e, id }) => {
        Controllers.comment.feed_project_update({
            p_id_project: e.value,
            p_id_feed: id
        })

        setRows(rows.map(f => {
            if (parseInt(f.id_feed) === parseInt(id)) {
                f.id_project = e.value
            }
            return f
        }))
    }

    const changeFlow = ({ e, idGroup, id }) => {
        Controllers.comment.feed_group_insert({
            p_id_group: idGroup,
            p_id_feed: id
        }).then(_ => {
            Controllers.feed.get_feed_groups({
                p_id_feed: id
            }).then(res => {
                setRows(rows.map(f => {
                    if (parseInt(f.id_feed) === parseInt(id)) {
                        f.groups = res.data
                    }
                    return f
                }))
            })
        })
    }

    const changeDate = ({ id, p_date_start, p_time_start, p_date_end, p_time_end, type }) => {
        setRows(rows.map(f => {
            if (parseInt(f.id_feed) === parseInt(id)) {
                if (type == 'p_date_start') {
                    f.date_start = p_date_start
                } else if (type == 'p_time_start') {
                    f.time_start = p_time_start
                } else if (type == 'p_date_end') {
                    f.date_end = p_date_end
                } else if (type == 'p_time_end') {
                    f.time_end = p_time_end
                }
            }
            return f
        }))

        Controllers.feed.feed_date_active_update({
            p_id_feed: id,
            p_date_start,
            p_time_start,
            p_date_end,
            p_time_end,
            p_type: type
        })
    }

    const deleteGroup = ({ idFeedGroup, id }) => {
        Controllers.feed.feed_group_delete({
            p_id_feed_group: idFeedGroup
        }).then(_ => {
            Controllers.feed.get_feed_groups({
                p_id_feed: id
            }).then(res => {
                setRows(rows.map(f => {
                    if (parseInt(f.id_feed) === parseInt(id)) {
                        f.groups = res.data
                    }
                    return f
                }))
            })
        })
    }

    const changeContact = ({ e, idContact, id }) => {
        Controllers.feed.feed_contact_insert({
            p_id_contact: idContact,
            p_id_feed: id
        }).then(_ => {
            Controllers.feed.get_feed_contacts({
                p_id_feed: id
            }).then(res => {
                setRows(rows.map(f => {
                    if (parseInt(f.id_feed) === parseInt(id)) {
                        f.contacts = res.data
                    }
                    return f
                }))
            })
        })
    }

    const deleteContact = ({ idFeedContact, id }) => {
        Controllers.feed.feed_contact_delete({
            p_id_feed_contact: idFeedContact
        }).then(_ => {
            Controllers.feed.get_feed_contacts({
                p_id_feed: id
            }).then(res => {
                setRows(rows.map(f => {
                    if (parseInt(f.id_feed) === parseInt(id)) {
                        f.contacts = res.data
                    }
                    return f
                }))
            })
        })
    }

    return <>
        <Card className="mb-4">
            <Card.Header className="justify-content-between">
                <span>Posts</span>
            </Card.Header>
        </Card>
        <div className="form-row">
            <ReactPlaceholder ready={ready} showLoadingAnimation rows={5}>
                {rows.length > 0 ? <>
                    <div className="col-12 col-md-6">
                        {React.Children.toArray(rows.slice(0, rowsSliceNumber).map(f => <Feed deleteContact={deleteContact} contacts={contacts} changeDate={changeDate} feed={f} deleteGroup={deleteGroup} changeResponse={changeResponse} projects={projects} groups={groups} changeProject={changeProject} changeFlow={changeFlow} changeContact={changeContact} /> ))}
                    </div>
                    <div className="col-12 col-md-6">
                        {React.Children.toArray(rows.slice(rowsSliceNumber, rows.length).map(f => <Feed deleteContact={deleteContact} contacts={contacts} changeDate={changeDate} feed={f} deleteGroup={deleteGroup} changeResponse={changeResponse} projects={projects} groups={groups} changeProject={changeProject} changeFlow={changeFlow} changeContact={changeContact} /> ))}
                    </div>
                </> : <div className="col-12">
                    <div className="alert alert-warning">
                        No hay feed
                    </div>
                </div>}
            </ReactPlaceholder>
        </div>
    </>
}

export default Comment