import { useState, useEffect } from 'react'


export default function useWS() {
  // const [url, setUrl] = useState('wss://multiagentest.tecnicom.pe/wss2/')
  const [url, setUrl] = useState('wss://dashboardbot.tecnicom.pe/wss2/')

  const wss = process.env.REACT_APP_API_WSS || 'wss://dashboardbot.tecnicom.pe/wss2/'

  useEffect(() => {
    setUrl(wss)
  }, [wss])

  return url
}
