import React from 'react'
import cacheSend from '../../assets/clock.png'

export default function SendMessange(props) {
  const {message} = props

  return (
    <div className={'d-flex justify-content-end'}>
      <div style={{
        backgroundColor: '#FFFFFF',
        width: '50%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
      }} className="p-3 rounded mb-4">
        <div style={{ marginBottom: 10, whiteSpace: 'pre-line' }}>
          {message}
        </div>
        <span
          className="text-muted"
          style={{
            position: 'absolute',
            right: '5%',
            bottom: '5%',
            fontSize: '9px',
            fontStyle: 'italic'
          }}
        >
          <img
            src={cacheSend}
            alt="cache send"
            width={7}
            height={7}
            style={{ marginRight: 5 }}
          />
          enviando..
        </span>
      </div>
    </div>
  )
}
