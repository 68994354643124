import React from "react";

function ServicesHeader({children}) {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="card-title">Servicios</h4>
        <div className="d-flex justify-content-between">
          {children}
        </div>
      </div>
    </>
  );
}

export default ServicesHeader;
