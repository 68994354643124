import React, { useState, useMemo, useCallback } from 'react'
import { useEffect } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, SwitchToggle, Select, CheckBox } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const ProfileForm = ({ data, onSubmit, viewSubmit, loadSubmit, idCustomers }) => {
    const [row, setRow] = useState({
        p_id_profile: data.id_profile,
        p_description_profile: data.description_profile,
        p_enabled_profile: data.enabled_profile,
        p_main_profile: data.main_profile,
        p_id_customers: data.id_customers
    })

    const [ready, setReady] = useState(false)
    const [permissions, setPermissions] = useState([])
    const [checkedAll, setCheckedAll] = useState(false)
    
    useEffect(() => {
        Controllers.tags_items[isNaN(parseInt(idCustomers)) ? 'get_tags_items_profile' : 'get_tags_items_customers']({
            p_id_profile: data.id_profile,
            p_id_customers: isNaN(parseInt(idCustomers)) ? data.id_customers : idCustomers
        }).then(res => {
            setPermissions(res.data)
            setReady(true)
        })
    }, [data.id_profile, data.id_customers, idCustomers])

    const [customers, setCustomers] = useState([])
    useEffect(() => {
        if (masterUser && isNaN(parseInt(data.id_profile))) {
            Controllers.customers.get_customers_public()
            .then(res => {
                setReady(true)
                setCustomers(res.data)
            })
        } else {
            setReady(true)
        }
    }, [])

    const masterUser = useSelector(store => store.session.master_user)
    //const masterUser = 0

    const handlEnabledTagsItems = ({ e, type }) => {
        let { idTagsItems } = e.currentTarget.dataset
        setPermissions(permissions.map(tpm => {
            tpm.childrens = tpm.childrens.map(tp => {
                tp.childrens = tp.childrens.map(t => {
                    if (type === 'all') {
                        t.enabled = e.currentTarget.checked ? 1 : 0
                    } else if (type === 'main') {
                        if (t.id_tags_items === idTagsItems) {
                            setRow({ ...row, p_main_profile: t.a_href })
                            t.main = 1
                            t.enabled = 1
                        } else {
                            t.main = 0
                        }
                    } else {
                        if (t.id_tags_items === idTagsItems) {
                            t.enabled = parseInt(t.main) === 1 ? 1 : (e.currentTarget.checked ? 1 : 0)
                        }
                    }
                    return t
                })
                return tp
            })
            return tpm
        }))
    }

    const isCheckedAll = () => {
        let qtyTagsItems = 0
        let qtyTagsItemsChecked = 0
        permissions.forEach(tpm => {
            tpm.childrens.forEach(tp => {
                tp.childrens.forEach(t => {
                    qtyTagsItems++
                    if (parseInt(t.enabled) === 1) {
                        qtyTagsItemsChecked++
                    }
                })
            })
        })

        return qtyTagsItems === qtyTagsItemsChecked
    }

    const dataSubmit = useCallback(() => {
        let p_permissions = []

        permissions.forEach(tpm => {
            tpm.childrens.forEach(tp => {
                tp.childrens.forEach(t => {
                    p_permissions.push({
                        p_id_tags_items_parent_master: t.id_tags_items_parent_master, 
                        p_id_tags_items_parent: t.id_tags_items_parent, 
                        p_id_tags_items: t.id_tags_items,
                        p_enabled: t.enabled,
                        p_main: t.main
                    })
                })
            })
        })

        return {
            ...row,
            p_permissions
        }
    }, [row, permissions])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={({ data }) => {
                if (isNaN(parseInt(idCustomers))) {
                    if (!row.p_main_profile) {
                        Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar página principal.', toast })
                        return
                    }
                }
                onSubmit({ data })
            }} viewSubmit={viewSubmit} loadSubmit={loadSubmit}>
            {masterUser && isNaN(parseInt(idCustomers)) ? (isNaN(parseInt(data.id_profile)) ? <Select
                options={customers.map(c => {
                    return {
                        label: c.name_customers,
                        value: c.id_customers
                    }
                })}
                text="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                onChange={e => {
                    setRow({ ...row, p_id_customers: e.value })
                    Controllers.tags_items.get_tags_items_customers_public({
                        p_id_customers: e.value
                    }).then(res => {
                        let perm = res.data.map(r => {
                            r.childrens = r.childrens.map(rr => {
                                rr.childrens = rr.childrens.map(rrr => {
                                    rrr.enabled = 0
                                    return rrr
                                })

                                return rr
                            })
                            return r
                        })
                        setPermissions(perm)
                    })
                }}
            /> : <Input
                text="Cliente"
                placeholder="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                readOnly
                defaultValue={data.name_customers}
            />) : ''}
            {isNaN(parseInt(idCustomers)) ? <>
                <Input
                    text="Descripción"
                    placeholder="Descripción"
                    classNameParent="col-12 mt-2 mb-2"
                    required
                    invalid="El campo es obligatorio."
                    defaultValue={data.description_profile}
                    onChange={e => setRow({ ...row, p_description_profile: e.currentTarget.value })}
                    maxLength={255}
                    disabled={!viewSubmit}
                />
                <SwitchToggle
                    classNameParent="col-12 mb-2"
                    id="profile_state"
                    defaultChecked={parseInt(row.p_enabled_profile) === 1}
                    onChange={e => setRow({ ...row, p_enabled_profile: e.currentTarget.checked ? 1 : 0 })}
                    disabled={!viewSubmit}
                />
            </> : ''}

            <div className="table-responsive">
            <table className="dt-react table table-sm table-hover table-bordered">
                <thead>
                    <tr>
                        <th>Privilegio</th>
                        <th>
                            {viewSubmit ? <CheckBox
                                id="chk-all-privileges"
                                onChange={e => handlEnabledTagsItems({ e, type: 'all' })}
                                checked={checkedAll || isCheckedAll()}
                            /> : ''}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {React.Children.toArray(permissions.map(tpm => {
                        {/* <tr>
                            <td>{tpm.parent.description}</td>
                            <td></td>
                        </tr> */}
                        let trs = [
                            
                        ]
                        
                        tpm.childrens.forEach(tp => {
                            trs.push(<tr>
                                <td>
                                    <span>{tp.parent.description}</span>
                                </td>
                                <td></td>
                            </tr>)

                        
                            tp.childrens.forEach(t => {
                                trs.push(<tr>
                                    <td>
                                        <span className="ml-3">{t.description}</span>
                                        {isNaN(parseInt(idCustomers)) ? <>
                                            {parseInt(t.main) === 1 ? <i className="fas fa-check-square ml-2 text-success"></i> : (viewSubmit ? <i className="far fa-check-square ml-2 cur-pointer" style={{
                                                opacity: .4
                                            }} data-id-tags-items={t.id_tags_items} onClick={e => handlEnabledTagsItems({ e, type: 'main' })}></i> : '')}
                                        </> : ''}
                                    </td>
                                    <td align="center">
                                        {viewSubmit ? <CheckBox
                                            id={'chk-item-' + t.id_tags_items}
                                            checked={parseInt(t.enabled) === 1}
                                            onChange={e => handlEnabledTagsItems({ e, type: 'one' })}
                                            data-id-tags-items={t.id_tags_items}
                                        /> : <i className={parseInt(t.enabled) === 1 ? 'fa fa-check text-success' : 'fa fa-times text-danger'}></i>}
                                    </td>
                                </tr>)
                            })
                        })

                        return <>
                            {React.Children.toArray(trs)}
                        </>
                    }))}
                </tbody>
            </table>
            </div>
        </FormCustom>
    </ReactPlaceholder>
}

ProfileForm.defaultProps = {
    data: {
        id_profile: '',
        description_profile: '',
        enabled_profile: 0,
        main_profile: ''
    },
    onSubmit: () => {},
    loadSubmit: false,
    viewSubmit: true,
    idCustomers: ''
}

export default ProfileForm;