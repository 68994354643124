import React, { useState, useEffect } from "react";
import ReactPlaceholder from "react-placeholder/lib";
import { PieChart } from "react-minimal-pie-chart";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import PercentageCard from "../../Components/common/PercentageCard";
import { FormCustom, Input } from "../../Components/Form/Form";
import TextValue from "../../Components/common/TextValue";
import Helpers from "../../Components/Helpers/Helpers";
import Controllers from "../../Api/Controllers";

export default function Dashboard() {
  const [rowsPercentage, setRowsPercentage] = useState([1, 2, 3, 4]);
  // const [dataPieEfectividad, setDataPieEfectividad] = useState({});
  const [dataPieMultiagente, setDataPieMultiagente] = useState([]);
  const [dataPieComments, setDataPieComments] = useState([]);
  const [dataPieLeads, setDataPieLeads] = useState([]);
  const [ready, setReady] = useState(false);
  const [rows, setRows] = useState({});
  const [formFilter, setFormFilter] = useState({
    p_date_start: Helpers.date.get({ format: "%Y-%m-%d" }),
    p_date_end: Helpers.date.get({ format: "%Y-%m-%d" }),
  });

  // ? buildPercentage ( datos, nombre, color )
  const buildPercentage = (socialNetwork, name, color) => ({
    percentage: socialNetwork?.percentage || "0.00%",
    qty: socialNetwork?.qty || 0,
    title: name,
    color,
  });

  const buildEffectiveness = (data, name, title, color) => {
    return {
      percentage: buildPercentage(data[name], title, color),
      pie: {
        title,
        value: data[name]?.qty || 0,
        color,
      },
      effectiveness: {
        [name]: {
          ...data.bot_lead[name],
          pie: [
            {
              title: "Convertien",
              value: parseFloat(data.bot_lead[name].qty_leads),
              color,
            },
            {
              title: "No Convierten",
              value: parseFloat(data.bot_lead[name].qty_conversation),
              color: "#343A40",
            },
          ],
        },
      },
    };
  };

  const getLeadPercentage = (data) => {
    setRows(data);
    let dataPercentage = [],
      dataPie = [];
    // dataEfectividad = {};
    if (data?.whatsapp) {
      const whatsapp = buildEffectiveness(
        data,
        "whatsapp",
        "Whatsapp",
        "#28A745"
      );
      dataPercentage.push(whatsapp.percentage);
      dataPie.push(whatsapp.pie);
      // dataEfectividad = {
      //   ...dataEfectividad,
      //   ...whatsapp.effectiveness,
      // };
    }
    if (data?.facebook) {
      const facebook = buildEffectiveness(
        data,
        "facebook",
        "Facebook",
        "#24A4CC"
      );
      dataPercentage.push(facebook.percentage);
      dataPie.push(facebook.pie);
      // dataEfectividad = {
      //   ...dataEfectividad,
      //   ...facebook.effectiveness,
      // };
    }
    if (data?.web) {
      const web = buildEffectiveness(data, "web", "Web", "#F1672B");
      dataPercentage.push(web.percentage);
      dataPie.push(web.pie);
      // dataEfectividad = {
      //   ...dataEfectividad,
      //   ...web.effectiveness,
      // };
    }
    if (data?.instagram) {
      const instagram = buildEffectiveness(
        data,
        "instagram",
        "Instagram",
        "#833AB4"
      );
      dataPercentage.push(instagram.percentage);
      dataPie.push(instagram.pie);
      // dataEfectividad = {
      //   ...dataEfectividad,
      //   ...instagram.effectiveness,
      // };
    }
    setRowsPercentage(dataPercentage);
    setDataPieLeads(dataPie);
    // setDataPieEfectividad(dataEfectividad);
    setDataPieComments([
      {
        title: "Whatsapp",
        value: data.comments.qty_bot,
        color: "#28A745",
      },
      {
        title: "Facebook",
        value: data.comments.qty_cm,
        color: "#F1672B",
      },
    ]);
    setDataPieMultiagente([
      {
        title: "Whatsapp",
        value: data.multiagente.qty_whatsapp,
        color: "#28A745",
      },
    ]);
    setReady(true);
  };

  useEffect(() => {
    if (!ready) {
      Controllers.lead.get_lead_percentage(formFilter).then((res) => {
        getLeadPercentage(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, formFilter]);

  return (
    <>
      <Card className="mb-4">
        <Card.Header className="justify-content-between">
          <span>Filtrar Por Fechas</span>
        </Card.Header>
        <Card.Body>
          <FormCustom
            onSubmit={(_) => setReady(false)}
            viewSubmit={false}
            className="mb-4"
          >
            <Input
              icon="far fa-calendar-alt"
              text="Fecha Inicio"
              type="date"
              required
              classNameParent="col-12 col-md-6"
              value={formFilter.p_date_start}
              onChange={(e) =>
                setFormFilter({
                  ...formFilter,
                  p_date_start: e.currentTarget.value,
                })
              }
            />
            <Input
              icon="far fa-calendar-alt"
              text="Fecha Fin"
              type="date"
              required
              classNameParent="col-12 col-md-6"
              append={[
                <Button type="submit">
                  <i className="fa fa-search mt-1"></i> Filtrar Información
                </Button>,
              ]}
              value={formFilter.p_date_end}
              onChange={(e) =>
                setFormFilter({
                  ...formFilter,
                  p_date_end: e.currentTarget.value,
                })
              }
            />
          </FormCustom>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body className="d-flex justify-content-between">
          <ReactPlaceholder ready={ready} rows={2} showLoadingAnimation>
            <span>
              Total: <strong>{rows?.total}</strong>
            </span>
            <span>
              Filtrado desde: <strong>{rows?.filter?.date_start}</strong> hasta{" "}
              <strong>{rows?.filter?.date_end}</strong>
            </span>
          </ReactPlaceholder>
        </Card.Body>
      </Card>
      <div className="form-row mt-3 mb-3">
        {rowsPercentage.map((item, key) => (
          <PercentageCard key={key} item={item} ready={ready} />
        ))}
      </div>
      <div className="form-row">
        <div className="col-12 col-md-4 mb-4">
          <Card>
            <Card.Header>Participación por Plataforma</Card.Header>
            <Card.Body className="d-flex">
              <ReactPlaceholder ready={ready} rows={2} showLoadingAnimation>
                <div className="position-relative">
                  <PieChart
                    data={dataPieLeads}
                    lineWidth={15}
                    paddingAngle={5}
                    style={{
                      width: 100,
                    }}
                  />
                  <div
                    className="position-absolute"
                    style={{
                      top: 31,
                      left:
                        parseInt(rows.total) <= 9
                          ? 41
                          : parseInt(rows.total) <= 99
                          ? 31
                          : 24,
                      fontSize: 30,
                    }}
                  >
                    <strong className="text-primary">{rows.total}</strong>
                  </div>
                </div>
                <div className="pl-4 d-flex flex-column">
                  {rows?.whatsapp && (
                    <TextValue
                      title="Whatsapp"
                      className="text-success"
                      value={rows?.whatsapp?.qty}
                    />
                  )}
                  {rows?.web && (
                    <TextValue
                      title="Web"
                      className="text-primary"
                      value={rows?.web?.qty}
                    />
                  )}
                  {rows?.facebook && (
                    <TextValue
                      title="Facebook"
                      className="text-blue"
                      value={rows?.facebook?.qty}
                    />
                  )}
                  {rows?.instagram && (
                    <TextValue
                      title="Instagram"
                      className="text-inst"
                      value={rows?.instagram?.qty}
                    />
                  )}
                </div>
              </ReactPlaceholder>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12 col-md-4 mb-4">
          <Card>
            <Card.Header>Comentarios</Card.Header>
            <Card.Body className="d-flex">
              <ReactPlaceholder ready={ready} rows={2} showLoadingAnimation>
                <div className="position-relative">
                  <PieChart
                    data={dataPieComments}
                    lineWidth={15}
                    paddingAngle={5}
                    style={{
                      width: 100,
                    }}
                  />
                  <div
                    className="position-absolute"
                    style={{
                      top: 31,
                      left:
                        parseInt(rows.total_comments) <= 9
                          ? 41
                          : parseInt(rows.total_comments) <= 99
                          ? 31
                          : 24,
                      fontSize: 30,
                    }}
                  >
                    <strong className="text-primary">
                      {rows.total_comments}
                    </strong>
                  </div>
                </div>
                <div className="pl-4 d-flex flex-column">
                  <TextValue
                    title="ChatBot"
                    className="text-info"
                    value={rows?.comments?.qty_bot}
                  />
                  <TextValue
                    title="CM"
                    className="text-primary"
                    value={rows?.comments?.qty_cm}
                  />
                </div>
              </ReactPlaceholder>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12 col-md-4 mb-4">
          <Card>
            <Card.Header>Mensajes enviados por Whatsapp</Card.Header>
            <Card.Body className="d-flex">
              <ReactPlaceholder ready={ready} rows={2} showLoadingAnimation>
                <div className="position-relative">
                  <PieChart
                    data={dataPieMultiagente}
                    lineWidth={15}
                    paddingAngle={5}
                    style={{
                      width: 100,
                    }}
                  />
                  <div
                    className="position-absolute"
                    style={{
                      top: 31,
                      left:
                        parseInt(rows.multiagente?.qty_whatsapp) <= 9
                          ? 41
                          : parseInt(rows.multiagente?.qty_whatsapp) <= 99
                          ? 31
                          : 24,
                      fontSize: 30,
                    }}
                  >
                    <strong className="text-primary">
                      {rows.multiagente?.qty_whatsapp || 0}
                    </strong>
                  </div>
                </div>
                <div className="pl-4 d-flex flex-column">
                  <TextValue
                    title="Whatsapp"
                    className="text-success"
                    value={rows?.multiagente?.qty_whatsapp}
                  />
                </div>
              </ReactPlaceholder>
            </Card.Body>
          </Card>
        </div>
        {/* {rows?.whatsapp &&  <div className="col-12 col-md-4 mb-4">
                <Card>
                    <Card.Header>
                        Efectividad de Chatbot Whatsapp
                    </Card.Header>
                    <Card.Body className="d-flex">
                        <ReactPlaceholder ready={ready} rows={2} showLoadingAnimation>
                            <div className="position-relative">
                                <PieChart
                                    data={dataPieEfectividad?.whatsapp?.pie}
                                    lineWidth={15} paddingAngle={5}
                                    style={{
                                        width: 100
                                    }}
                                />
                                <div className="position-absolute" style={{
                                        top: 31,
                                        left: parseInt(dataPieEfectividad?.whatsapp?.total) <= 9 ? 41 : (parseInt(dataPieEfectividad?.whatsapp?.total) <= 99 ? 31 : 24),
                                        fontSize: 30
                                }}>
                                    <strong className="text-primary">{dataPieEfectividad?.whatsapp?.total}</strong>
                                </div>
                            </div>
                            <div className="pl-4 d-flex flex-column">
                                <div className="text-info mb-2">
                                    <i className="fa fa-circle mr-1"></i>Convierten ({dataPieEfectividad?.whatsapp?.qty_leads}) ({parseFloat(dataPieEfectividad?.whatsapp?.percentage_lead).toFixed(2)}%)
                                </div>
                                <div className="text-dark mb-2">
                                    <i className="fa fa-circle mr-1"></i>No Convierten ({dataPieEfectividad?.whatsapp?.qty_conversation}) ({parseFloat(dataPieEfectividad?.whatsapp?.percentage_conversation).toFixed(2)}%)
                                </div>
                            </div>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>}
            {rows?.facebook && <div className="col-12 col-md-4 mb-4">
                <Card>
                    <Card.Header>
                        Efectividad de Chatbot Facebook
                    </Card.Header>
                    <Card.Body className="d-flex">
                        <ReactPlaceholder ready={ready} rows={2} showLoadingAnimation>
                            <div className="position-relative">
                                <PieChart
                                    data={dataPieEfectividad?.facebook?.pie}
                                    lineWidth={15} paddingAngle={5}
                                    style={{
                                        width: 100
                                    }}
                                />
                                <div className="position-absolute" style={{
                                        top: 31,
                                        left: parseInt(dataPieEfectividad?.facebook?.total) <= 9 ? 41 : (parseInt(dataPieEfectividad?.facebook?.total) <= 99 ? 31 : 24),
                                        fontSize: 30
                                }}>
                                    <strong className="text-primary">{dataPieEfectividad?.facebook?.total}</strong>
                                </div>
                            </div>
                            <div className="pl-4 d-flex flex-column">
                                <div className="text-primary mb-2">
                                    <i className="fa fa-circle mr-1"></i>Convierten ({dataPieEfectividad?.facebook?.qty_leads}) ({parseFloat(dataPieEfectividad?.web?.percentage_lead).toFixed(2)}%)
                                </div>
                                <div className="text-dark mb-2">
                                    <i className="fa fa-circle mr-1"></i>No Convierten ({dataPieEfectividad?.facebook?.qty_conversation}) ({parseFloat(dataPieEfectividad?.web?.percentage_conversation).toFixed(2)}%)
                                </div>
                            </div>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>}
            {rows?.web && <div className="col-12 col-md-4 mb-4">
                <Card>
                    <Card.Header>
                        Efectividad de Chatbot Web
                    </Card.Header>
                    <Card.Body className="d-flex">
                        <ReactPlaceholder ready={ready} rows={2} showLoadingAnimation>
                            <div className="position-relative">
                                <PieChart
                                    data={dataPieEfectividad?.web?.pie}
                                    lineWidth={15} paddingAngle={5}
                                    style={{
                                        width: 100
                                    }}
                                />
                                <div className="position-absolute" style={{
                                        top: 31,
                                        left: parseInt(dataPieEfectividad?.web?.total) <= 9 ? 41 : (parseInt(dataPieEfectividad?.web?.total) <= 99 ? 31 : 24),
                                        fontSize: 30
                                }}>
                                    <strong className="text-primary">{dataPieEfectividad?.web?.total}</strong>
                                </div>
                            </div>
                            <div className="pl-4 d-flex flex-column">
                                <div className="text-blue mb-2">
                                    <i className="fa fa-circle mr-1"></i>Convierten ({dataPieEfectividad?.web?.qty_leads}) ({parseFloat(dataPieEfectividad?.web?.percentage_lead).toFixed(2)}%)
                                </div>
                                <div className="text-dark mb-2">
                                    <i className="fa fa-circle mr-1"></i>No Convierten ({dataPieEfectividad?.web?.qty_conversation}) ({parseFloat(dataPieEfectividad?.web?.percentage_conversation).toFixed(2)}%)
                                </div>
                            </div>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>}
            {rows?.instagram && <div className="col-12 col-md-4 mb-4">
                <Card>
                    <Card.Header>
                        Efectividad de Chatbot Instagram
                    </Card.Header>
                    <Card.Body className="d-flex">
                        <ReactPlaceholder ready={ready} rows={2} showLoadingAnimation>
                            <div className="position-relative">
                                <PieChart
                                    data={dataPieEfectividad?.web?.pie}
                                    lineWidth={15} paddingAngle={5}
                                    style={{
                                        width: 100
                                    }}
                                />
                                <div className="position-absolute" style={{
                                        top: 31,
                                        left: parseInt(dataPieEfectividad?.instagram?.total) <= 9 ? 41 : (parseInt(dataPieEfectividad?.instagram?.total) <= 99 ? 31 : 24),
                                        fontSize: 30
                                }}>
                                    <strong className="text-primary">{dataPieEfectividad?.instagram?.total}</strong>
                                </div>
                            </div>
                            <div className="pl-4 d-flex flex-column">
                                <div className="text-blue mb-2">
                                    <i className="fa fa-circle mr-1"></i>Convierten ({dataPieEfectividad?.instagram?.qty_leads}) ({parseFloat(dataPieEfectividad?.instagram?.percentage_lead).toFixed(2)}%)
                                </div>
                                <div className="text-dark mb-2">
                                    <i className="fa fa-circle mr-1"></i>No Convierten ({dataPieEfectividad?.instagram?.qty_conversation}) ({parseFloat(dataPieEfectividad?.web?.percentage_conversation).toFixed(2)}%)
                                </div>
                            </div>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>} */}
      </div>
    </>
  );
}

// !!!!!!!!!!!!!!!!!!!!!

/* 
<div className="col-12 col-md-4 mb-4">
                <Card>
                    <Card.Body className="pl-5 pr-5">
                        <ReactPlaceholder ready={ready} rows={3} showLoadingAnimation>
                            <a className="d-flex justify-content-between text-decoration-none" href={Helpers.config.convertUrl({ url: '/leads-whatsapp' })}>
                                <div className="text-info d-flex align-self-center">
                                    <i className="fab fa-whatsapp mr-2 fa-2x align-self-center"></i>
                                    <strong className="align-self-center">Whatsapp</strong>
                                </div>
                                <div style={{
                                    fontSize: 30
                                }}>
                                    |
                                </div>
                                <div className="text-info align-self-center">
                                    {rows.whatsapp?.qty} - {rows.whatsapp?.percentage}
                                </div>
                            </a>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-12 col-md-4 mb-4">
                <Card>
                    <Card.Body className="pl-5 pr-5">
                        <ReactPlaceholder ready={ready} rows={3} showLoadingAnimation>
                            <a className="d-flex justify-content-between text-decoration-none" href={Helpers.config.convertUrl({ url: '/leads-facebook' })}>
                                <div className="text-warning d-flex align-self-center">
                                    <i className="fab fa-whatsapp mr-2 fa-2x align-self-center"></i>
                                    <strong className="align-self-center">Facebook</strong>
                                </div>
                                <div style={{
                                    fontSize: 30
                                }}>
                                    |
                                </div>
                                <div className="text-warning align-self-center">
                                    {rows.facebook?.qty} - {rows.facebook?.percentage}
                                </div>
                            </a>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-12 col-md-4">
                <Card>
                    <Card.Body className="pl-5 pr-5">
                        <ReactPlaceholder ready={ready} rows={3} showLoadingAnimation>
                            <a className="d-flex justify-content-between text-decoration-none" href={Helpers.config.convertUrl({ url: '/leads-web' })}>
                                <div className="text-blue d-flex align-self-center">
                                    <i className="fab fa-whatsapp mr-2 fa-2x align-self-center"></i>
                                    <strong className="align-self-center">Web</strong>
                                </div>
                                <div style={{
                                    fontSize: 30
                                }}>
                                    |
                                </div>
                                <div className="text-blue align-self-center">
                                    {rows.web?.qty} - {rows.web?.percentage}
                                </div>
                            </a>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>
*/
