import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import ProfileForm from './ProfileForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { useSelector } from 'react-redux';

const Profile = ({ nameSingle, namePlural, separator }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})

    const isMasterUser = useSelector(store => store.session.master_user)
    //const isMasterUser = 0

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.profile.get_profile().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.profile.profile_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
            setReload(true)
        })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idProfile) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let { idProfile } = $this.dataset

        Controllers.profile.get_profile_id({ p_id_profile: idProfile }).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idProfile}`)
            setDataUpdated({ data: res.data })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
        .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.profile.profile_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idProfile, descriptionProfile } = e.currentTarget.dataset
        setDataDeleted({
            p_id_profile: idProfile,
            p_description_profile: descriptionProfile
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const dtRows = useMemo(() => {
        let columns = [
            { name: 'description_profile', text: 'Descripción', align: 'center' },
            { name: 'main_profile', text: 'Principal', align: 'center' },
            { name: 'enabled_profile', text: 'Estado', align: 'center', render: r => <div className={'badge badge-' + (parseInt(r.enabled_profile) === 1 ? 'success' : 'danger')}>
                {parseInt(r.enabled_profile) === 1 ? 'Activo' : 'Inactivo'}
            </div> },
            { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                return <div>
                    <Button className="mr-1" size="xs" data-id-profile={u.id_profile} onClick={handleGet}>
                        <i className="fa fa-edit"></i> Editar
                    </Button>
                    <Button variant="danger" size="xs" data-id-profile={u.id_profile} data-description-profile={u.description_profile} onClick={handleShowModalDelete}>
                        <i className="fa fa-trash"></i> Eliminar
                    </Button>
                </div>
            } },
        ]

        if (isMasterUser) {
            columns.unshift({ name: 'name_customers', text: 'Cliente', align: 'center' })
        }

        return <ReactDataTableBootstrap
            head={[
                columns
            ]}
            rows={rows}
            isProcessing={isProcessing}
        />
    }, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="d-flex justify-content-between">
                <span>Listado De {namePlural}</span>
                <Button size="sm" onClick={handleGet}>
                    <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                </Button>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <ProfileForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} />
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_profile} - {dataDeleted.p_description_profile}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default Profile