import React from "react";

export default function TextValue(props) {
  const { className, title, value } = props;

  return (
    <div className={`${className} mb-2`}>
      <i className="fa fa-circle mr-1"></i>
      {`${title} (${value || 0})`}
    </div>
  );
}
