import React, { useState } from 'react'
import { Input, FormCustom, SwitchToggle } from '../../Components/Form/Form'

const ProjectRoomsForm = ({ data, onSubmit, disablebSubmit, loadSubmit, idCustomers, idProject }) => {
    const [row, setRow] = useState({
        p_id_project_room: data.id_project_room,
        p_name_room: data.name_room,
        p_number_room: data.number_room,
        p_enabled_room: data.enabled_room,
        p_id_project: idProject,
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Nombre"
            placeholder="Nombre"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_room}
            onChange={e => setRow({ ...row, p_name_room: e.currentTarget.value })}
            maxLength={255}
        />
        <Input
            text="Cantidad Habitaciones"
            placeholder="Cantidad Habitaciones"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            type="number"
            invalid="El campo es obligatorio."
            defaultValue={data.number_room}
            onChange={e => setRow({ ...row, p_number_room: e.currentTarget.value })}
            maxLength={255}
        />
        <SwitchToggle
            id="enabled-room"
            defaultChecked={parseInt(data.enabled_room) === 1}
            onChange={e => setRow({ ...row, p_enabled_room: e.currentTarget.checked ? 1 : 0 })}
        />
    </FormCustom>
}

ProjectRoomsForm.defaultProps = {
    data: {
        id_project_room: '',
        name_room: '',
        number_room: '',
        image_room: '',
        enabled_room: '0',
        id_project: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default ProjectRoomsForm;