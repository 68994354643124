import React, { useState } from 'react'
import { useEffect } from 'react'
import Controllers from '../../Api/Controllers'
import { Input, SwitchToggle, FormCustom, Select } from '../../Components/Form/Form'
import ReactPlaceholder from 'react-placeholder'
import { useSelector } from 'react-redux'

const JobForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_job: data.id_job,
        p_description_job: data.description_job,
        p_id_customers: data.id_customers,
    })

    const masterUser = useSelector(store => store.session.master_user)
    const [ready, setReady] = useState(false)
    const [customers, setCustomers] = useState([])
    useEffect(() => {
        if (masterUser && isNaN(parseInt(data.id_job))) {
            Controllers.customers.get_customers_public()
            .then(res => {
                setReady(true)
                setCustomers(res.data)
            })
        } else {
            setReady(true)
        }
    }, [])

    return <ReactPlaceholder rows={15} showLoadingAnimation ready={ready}>
        <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            {masterUser ? (isNaN(parseInt(data.id_job))? <Select
                options={customers.map(c => {
                    return {
                        label: c.name_customers,
                        value: c.id_customers
                    }
                })}
                text="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                onChange={e => setRow({ ...row, p_id_customers: e.value })}
            /> : <Input
                text="Cliente"
                placeholder="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                readOnly
                defaultValue={data.name_customers}
            />) : ''}
            <Input
                text="Descripción"
                placeholder="Descripción"
                classNameParent="col-12 mb-2"
                icon="far fa-user"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.description_job}
                onChange={e => setRow({ ...row, p_description_job: e.currentTarget.value })}
                maxLength={255}
            />
        </FormCustom>
    </ReactPlaceholder>
}

JobForm.defaultProps = {
    data: {
        id_job: '',
        description_job: '',
        id_customers: '',
        name_customers: '',
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default JobForm;