import React, { useState, useEffect } from 'react';
import { TextRow } from 'react-placeholder/lib/placeholders';
import { Card, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Controllers from '../../Api/Controllers';

import EmailSelection from '../../Components/common/EmailSelection';
import FlatListSearch from '../../Components/common/FlatListSearch';
import SelectTime from '../../Components/common/SelectTime';
import FormCustom from '../../Components/Form/FormCustom';
import Helpers from '../../Components/Helpers/Helpers';
import CheckBox from '../../Components/Form/CheckBox';

export default function AlertChatBots() {
  const [socialNetwork, setSocialNetwork] = useState([])
  const [custome, setCustome] = useState(undefined);
  const [loadData, setLoadData] = useState(false)
  const [loadField, setLoadField] = useState('')
  // const [load, setLoad] = useState(false)
  const [rows, setRows] = useState([])

  useEffect(() => {
    // setLoad(true)
    Controllers.customers.get_customers()
      .then(res => setRows(res.data))
      .catch(req => Helpers.promise.catch({ req, toast }))
      // .finally(() => setLoad(false))
  }, [])

  const handleClick = cust => {
    setLoadData(true)
    setCustome(cust)
    Controllers.alert.get_alert_chatbot({ p_id_customers: cust.id_customers })
      .then((res) => setSocialNetwork(res.data))
      .finally(() => setLoadData(false))
  }

  const onChangeChecked = (type) => {
    setSocialNetwork(socialNetwork.map(item => 
      item.type === type
        ? ({ ...item, enabled: item.enabled === 1 ? 0 : 1 }) 
        : item
    ))
  }

  const onChangeTime = (type, name, value) => {
    setSocialNetwork(socialNetwork.map(item => {
      if (item.type === type) {
        return {
          ...item,
          time: [
            {
              ...item.time[0],
              [name]: value
            }
          ]          
        }
      }
      return item
    }))
  }

  const addEmail = (type, email) => {
    setSocialNetwork(socialNetwork.map(item => {
      if (item.type === type) {
        return {
          ...item,
          email: item?.email ? [...item.email, email] : [email],
        }
      }
      return item
    }))
  }

  const deleteEmail = (type, id) => {
    setSocialNetwork(socialNetwork.map(item => {
      if (item.type === type) {
        return {
          ...item,
          email: item.email.filter(it => it.id !== id),
        }
      }
      return item
    }))
  }

  const colorNetwork = type => {
    let color = '#F1672B';
    if (type.toLowerCase() === 'whatsapp') {
      color = "#28A745"
    } else if (type.toLowerCase() === 'instagram') {
      color = "#833AB4"
    } else if (type.toLowerCase() === 'facebook') {
      color = "#1E4696"
    } 
    return color;
  }

  const onSubmit = ({ data }) => {
    setLoadField(data.type)
    const form = {
      p_id_customers: custome.id_customers,
      p_data: {
        p_type: data.type,
        p_enabled: data.enabled,
        p_email: data.email,
        p_time: data.time
      }
    }
    Controllers.alert.alert_chatbot_insert_update(form)
      .then(res => Helpers.toast.construct({ ...res, toast }))
      .catch(req => Helpers.promise.catch({ req, toast }))
      .finally(() => setLoadField(''))
  }
  
  return (
    <div className="alert-chatbot">
      <FlatListSearch
        onClick={handleClick}
        select={custome?.name_customers || ''}
        fields={['name_customers']}
        rows={rows}
      />
      {custome?.name_customers && <div>
        <header>
          <h4 className="pt-2 pl-3">{custome?.name_customers}</h4>
        </header>
        {loadData ? (
          <div className="d-flex mt-4">
            <Card className="pt-4 col-6 ml-2 mr-2">
              <TextRow color='#E0E0E0' style={{borderRadius: 5, marginLeft: 10, height: 15, width: '150px' }} />
              <TextRow color='#E0E0E0' style={{borderRadius: 5, marginLeft: 10, height: 15, marginBottom: 15, marginTop: 15, width: '80px' }} />
              <TextRow color='#E0E0E0' style={{borderRadius: 5, height: 30, width: '100%' }} />
            </Card>
            <Card className="pt-4 pb-4 col-6 ml-2 mr-2">
              <TextRow color='#E0E0E0' style={{borderRadius: 5, marginLeft: 10, height: 15, width: '150px' }} />
              <TextRow color='#E0E0E0' style={{borderRadius: 5, marginLeft: 10, height: 15, marginBottom: 15, marginTop: 15, width: '80px', }} />
              <TextRow color='#E0E0E0' style={{borderRadius: 5, height: 30, width: '100%' }} />
            </Card>
          </div>
        ): (
          <div className="form-row mt-3 mb-3 pl-2 pr-2" style={{ height: 'max-content', overflow: 'auto', height: '80vh' }}>
            {socialNetwork.map((item, key) => (
              <div key={key} className="col-12 col-md-6 mb-3">
                <Card className="p-3">
                  <FormCustom
                    dataSubmit={item}
                    onSubmit={onSubmit}
                    loadSubmit={item.type === loadField}
                  >
                    <Card.Body>
                      <div
                        className="d-flex flex-column justify-content-between text-decoration-none"
                      >
                        <div className="d-flex" style={{color: colorNetwork(item.type)}}>
                          <i className={`fab fa-${item?.type?.toLowerCase() || ''} mr-2 fa-2x align-self-center`}></i>
                          <strong className="align-self-center">{item?.type || ''}</strong>
                        </div>
                        <div className="pt-3">
                          <Row>
                            <CheckBox
                              text="Activar"
                              classNameParent="col-12 pl-4"
                              id={`1${key}`}
                              checked={parseInt(item.enabled) === 1}
                              onChange={() => onChangeChecked(item.type)}
                            />
                          </Row>
                          {item.enabled === 1 && (
                            <SelectTime
                              value={item?.time?.length > 0 ? item.time[0] : item.time}
                              onClickMinutes={val => onChangeTime(item.type, 'minutes', val)}
                              onClickHours={val => onChangeTime(item.type, 'hours', val)}
                            />
                          )}
                          {item.enabled === 1 && (
                            <Row className="mx-0 col-12 mb-2 p-0" >
                              <EmailSelection
                                emails={item.email || []}
                                onClickAdd={email => addEmail(item.type, email)}
                                deleteEmail={id => deleteEmail(item.type, id)}
                              />
                            </Row>
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </FormCustom>
                </Card>
              </div>
            ))}
          </div>
        )}
      </div>}
    </div>
  )
}
