import React from "react";
import esLocale from "date-fns/locale/es";
import { format, formatDistanceToNowStrict } from "date-fns";

export default function FormatDate(props) {
  const { date } = props;

  const diff = date
    ? (new Date().getTime() - new Date(date).getTime()) / (1000 * 60 * 60 * 24)
    : 0;

  return (
    <div
      style={{
        position: "absolute",
        bottom: 4,
        right: 30,
      }}
    >
      <span style={{ color: "#6b6b6b", fontSize: 10 }}>
        {diff < 2
          ? formatDistanceToNowStrict(new Date(date), {
              addSuffix: true,
              locale: esLocale,
              includeSeconds: true,
            })
          : format(date ? new Date(date) : new Date(), "PP", {
              locale: esLocale,
            })}
      </span>
    </div>
  );
}
