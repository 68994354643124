import axios from "axios";

import Helpers from "../Components/Helpers/Helpers";

const flowChatFetch = axios.create({
  baseURL: Helpers.config.api.url,
  params: { jwt: Helpers.localStorage.getJWT() },
});

export const flowChatRequests = {
  deleteAgent: (id_agent, id_flow_chat_opt) => {
    return flowChatFetch
      .delete(
        `/flowchat/delete-agent?id_agent=${id_agent}&id_flow_chat_opt=${id_flow_chat_opt}`
      )
      .then((response) => {
        return response.data;
      });
  },

  deleteOpt: (id_flow_chat_opt) => {
    return flowChatFetch
      .delete(`/flowchat/delete-option?id_flow_chat_opt=${id_flow_chat_opt}`)
      .then((response) => {
        return response.data;
      });
  },
};

export default flowChatFetch;
