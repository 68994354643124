import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
// bootstrap
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// components
import Controllers from "../../Api/Controllers";
import Helpers from "../Helpers/Helpers";

function DialogAudio({ open, onClose, onClick, source }) {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={open}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Grabar audio</Modal.Title>
      </Modal.Header>
      <Modal.Body>{source && <audio controls src={source} />}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={onClick}>
          Enviar audio
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function ButtonMicFill({ currentChat }) {
  const [file, setFile] = useState(null);
  const [source, setSource] = useState(null);

  const [open, setOpen] = useState(false);

  const refMic = useRef(null);

  const handleSendAudio = () => {
    if (file) {
      const formData = new FormData();
      formData.append("p_id_file", file);
      formData.append("p_id_chat", currentChat.id_chat);
      Controllers.chat.send_message_audio(formData).then((res) => {
        handleClose();
        Helpers.toast.construct({ ...res, toast });
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setSource(null);
    refMic.current.file = null;
    refMic.current.value = "";
  };

  const handleChange = (e) => {
    const newFile =
      e.currentTarget.files.length > 0 ? e.currentTarget.files[0] : null;
    if (newFile) {
      const newSource = URL.createObjectURL(newFile);
      setSource(newSource);
      setFile(newFile);
      setOpen(true);
    }
  };

  const handleRecordAudio = () => {
    Helpers.toast.construct({
      message: "Próximamente",
      response: "info",
      toast,
    });
  };

  return (
    <>
      <DialogAudio
        onClose={handleClose}
        onClick={handleSendAudio}
        source={source}
        open={open}
      />
      <div
        style={{
          borderRadius: 5,
          width: 35,
          height: 35,
          padding: 0,
        }}
        onClick={handleRecordAudio}
      >
        <label
          // htmlFor="txt_audio_add_send"
          style={{
            backgroundColor: "#D8572B",
            display: "inline-block",
            fontWeight: 400,
            textAlign: "center",
            verticalAlign: "middle",
            userSelect: "none",
            border: "1px solid #D8572B",
            padding: "0.375rem 0.55rem",
            fontSize: "1rem",
            lineHeight: 1.5,
            cursor: "pointer",
            borderRadius: "0.25rem",
            color: "#ffffff",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-mic-fill"
            viewBox="0 0 16 16"
          >
            <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
            <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
          </svg>
          {/* <input
            ref={refMic}
            type="file"
            style={{ display: "none" }}
            id="txt_audio_add_send"
            onChange={handleChange}
            accept="audio/*"
          /> */}
        </label>
      </div>
    </>
  );
}
