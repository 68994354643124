import React, {useState, useEffect} from 'react'
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';

import AgentSelection from './AgentSelection';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { FormCustom, TextArea, CheckBox, Select } from '../../Components/Form/Form'
import { Row } from 'react-bootstrap';
import SelectTime from './SelectTime';

export default function FieldWelcome(props) {
  const [title, setTitle] = useState('Respuesta Automática');
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [transfer, setTransfer] = useState(undefined);
  const [frequency, setFrequency] = useState('')
  const [agents, setAgents] = useState([])
  const [value, setValue] = useState('')
  const [time, setTime] = useState('')
  const [id, setId] = useState('');

  const listFrequency = [
    { value: "1", label: "Por Única vez", },
    { value: "2", label: "Programar Frecuencia", },
    { value: "3", label: "Siempre se muestra", },
  ]

  const listDay = [
    { value: "1", label: "1 Día", },
    { value: "7", label: "7 Días", },
    { value: "15", label: "15 Días", },
    { value: "30", label: "30 Días", },
  ]

  const {
    item,
    itemFlowChat,
    onChangeChecked,
  } = props

  const { type, message, enabled, id_answer } = item

  useEffect(() => {
    if (type) {
      const label = type?.charAt(0)?.toUpperCase() + type?.slice(1)?.toLowerCase();
      setTitle(`Respuesta Automática ${label || ''}`)
      setId(`enabled-${type?.toLowerCase() || ''}`)
    }
  }, [type])

  useEffect(() => {
    setValue(message)
    setTime(String(item.time) || '')
    setFrequency(String(item.frequency) || '')
    setTransfer(item?.transfer || {
      enabled: 0,
      hours: '00',
      minutes: '00'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setAgents(item?.agents || [])
  }, [ item.agents ])
  
  const onChangChatBotFlow = async (enabled) => {
    try {
      const form = {
        p_type: itemFlowChat?.type,
        p_options: itemFlowChat?.options,
        p_message: itemFlowChat?.text_init,
        p_flow_chat: itemFlowChat?.id_flow_chat,
        p_enabled: enabled,
        p_transfer: itemFlowChat?.transfer,
      }
      const res = await Controllers.flow_chat.flow_chat_insert_update(form);
      Helpers.toast.construct({ ...res, toast })
      if (res.response === 'success') {
        if (parseInt(enabled) === 1) {
          Helpers.toast.construct({
            toast,
            response: "info",
            message: `Se a habilito Flujo Chat Box de ${itemFlowChat.type}`,
          });
        } else {
          Helpers.toast.construct({
            toast,
            response: "info",
            message: `Se a deshabilitado Flujo Chat Box de ${itemFlowChat.type}`,
          });
        }
      }
    } catch (req) {
      Helpers.promise.catch({ req, toast })
    } finally {
      setLoadSubmit(false)
    }
  }

  const onSubmit = ({ data }) => {
    setLoadSubmit(true)
    const form = {
      p_message: value,                     
      p_frequency: parseInt(frequency),               
      p_time: parseInt(frequency === "2" ? time : 0),
      p_id_answer: data.id_answer,
      p_type: data.type,
      p_enabled: data.enabled,
      p_agents: agents,
      p_transfer: transfer
    }
    Controllers.answer.answer_insert_update(form)
      .then(res => {
        Helpers.toast.construct({ ...res, toast })
        if (res.response === 'success') {
          if (itemFlowChat?.type && itemFlowChat?.type.toLowerCase() === data.type.toLowerCase()) {
            onChangChatBotFlow(data.enabled === 1 ? 0 : 1)
          }
        }
      })
      .catch(req => Helpers.promise.catch({ req, toast }))
      .finally(() =>setLoadSubmit(false))
  }

  const addAgent = (agent) => {
    const exit = agents.filter(ag => ag.id === agent.id).length > 0;
    if (!exit) {
      setAgents([ ...agents, agent ])
    }
  }

  const deleteAgent = (id) => {
    setAgents(agents.filter(a => a.id !== id))
  }

  return (
    <div className="col-md-6 mb-4">
      <Card style={{
        boxShadow: '10px 5px 12px #d4d4d4'
      }}>
        <Card.Header className="justify-content-between">
          <span>{title}</span>
        </Card.Header>
        <Card.Body>
          <FormCustom dataSubmit={item} onSubmit={onSubmit} loadSubmit={loadSubmit}>
            <TextArea
              rows="10"
              text="Mensaje"
              disabled={loadSubmit}
              defaultValue={value}
              placeholder="Mensaje"
              classNameParent="col-12 mb-2"
              invalid="El campo es obligatorio."
              onChange={e => setValue(e.currentTarget.value)}
            />
            <div className="container">
              <Row>
                <CheckBox
                  id={id}
                  classNameParent="col-4 pl-2"
                  checked={parseInt(enabled) === 1}
                  onChange={() => onChangeChecked(id_answer)}
                  text="Activar"
                />
              </Row>
            </div>
            <Card.Header className="justify-content-beetwen pl-2 pb-0 h-25 pt-1">
              <span>Programación de mensaje</span>
            </Card.Header>
            <Row className="mx-0 mt-2 col-12 mb-2 d-flex justify-content-beetween">
              <Select
                text="Frecuencia"
                value={listFrequency.find(f => f.value === frequency)}
                options={listFrequency}
                classNameParent="w-100 mb-2"
                onChange={(e) => setFrequency(e.value)}
              />
              {frequency === '2' && (
                <Select
                  text="Tiempo"
                  value={listDay.find(d => d.value === time)}
                  options={listDay}
                  classNameParent="w-100 mb-2"
                  onChange={(e) => setTime(e.value)}
                />
              )}
            </Row>
            
            <Row className="col-12 mt-2">
              <CheckBox
                text="Tiempo de transferencia"
                classNameParent="col-12"
                id={`1${id}`}
                checked={parseInt(transfer?.enabled) === 1}
                onChange={() => setTransfer({
                  ...transfer,
                  enabled: transfer?.enabled === 1 ? 0 : 1,
                })}
              />
            </Row>
              {transfer?.enabled === 1 && (
                <SelectTime
                  value={transfer}
                  onClickMinutes={value => setTransfer({
                    ...transfer,
                    minutes: value,
                  })}
                  onClickHours={value => setTransfer({
                    ...transfer,
                    hours: value,
                  })}
                />
              )}
            <Card.Header className="justify-content-beetwen pl-2 pb-0 h-25 pt-1">
              <span>Seleccionar agente</span>
            </Card.Header>
            <Row className="mx-0 col-12 mb-2" >
              <AgentSelection
                onChangeAgent
                agents={agents}
                onClickAdd={addAgent}
                deleteAgent={deleteAgent}
              />
            </Row>
          </FormCustom>
        </Card.Body>
      </Card>
    </div>
  )
};
