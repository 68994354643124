/* eslint-disable no-lone-blocks */
import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Row } from "react-bootstrap";
import { format } from "date-fns";
import * as XLSX from "xlsx";

import { CheckBox, FormCustom, Input } from "../../Components/Form/Form";
import SwitchTemplates from "../../Components/buttons/SwitchTemplates";
import { RenderModalImage } from "../../Components/modals/NewMessage";
import AgentSelection from "../../Components/common/AgentSelection";
// import {
//   cantabriaEdit,
//   sanCharbelEdit,
//   tiempoCasaEdit,
//   grupoTycMamaniEdit,
//   barquerosEdit,
//   dKasaEdit,
// } from "../../db/state";
import { getDateEndOfMonth, getDateStartOfMonth } from "../../utils/date";
import InputDateTime from "../../Components/modals/InputDateTime";
import Helpers from "../../Components/Helpers/Helpers";
import Select from "../../Components/Form/Select";
// import useTemplate from "../../hooks/useTemplate";
import Controllers from "../../Api/Controllers";
import Loading from "../Loading/Loading";
import useWindowSize from "../../hooks/useWindowSize";
import useGetTemplates from "../templates/hooks/use-get-templates";

const STATE = {
  template: {
    label: "",
    value: "",
  },
  phone: {
    code: 51,
    number: "",
  },
};

export default function SendBulkTemplate() {
  const [listTemplates, setListTemplates] = useState([STATE.template])
  const [listTemplatesEdit, setListTemplatesEdit] = useState([STATE.template])
  const [dataTemplates, setDataTemplates] = useState([])

  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [agentsSelect, setAgentsSelect] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [btnActive, setBtnActive] = useState("1");
  const [nameSend, setNameSend] = useState("");
  const [bot, setBot] = useState(false);
  const [viewImage, setViewImage] = useState({
    enabled: false,
    imageUrl: "",
  });

  const fileRef = useRef();
  const { width } = useWindowSize();

  // const { id_customers, username } = useSelector((store) => store.session);
  // const templates = useTemplate(id_customers, username);
  const { id_customers } = useSelector((store) => store.session);

  const {data: rowsTemplatesEdit } = useGetTemplates({
    templateType:'rich_text_params',
    arrayMap: true,
  })

  const {data: rowsTemplates } = useGetTemplates({
    templateType: 'all',
    arrayMap: true,
  })

  const resetForm = () => {
    setListTemplates([STATE.template]);
    setPhoneNumbers([]);
    setAgentsSelect([]);
    setOpenDate(false);
    setBtnActive("1");
    setNameSend("");
    setBot(false);
    setViewImage({
      enabled: false,
      imageUrl: "",
    });
    setListTemplatesEdit([STATE.template])
  };

  const helpersToast = (message) => {
    Helpers.toast.construct({
      response: "warning",
      message,
      toast,
    });
  };

  const sendTemplate = async (templateId, cb) => {
    try {
      setIsLoading(true);
      if (!nameSend) {
        helpersToast("Debe de escribir un nombre a canal de difusión");
      } else if (templateId === "") {
        helpersToast("Debe de seleccionar una plantilla");
      } else if (!bot && agentsSelect.length === 0) {
        helpersToast("Bot está desactivado, debe de seleccionar un agente");
      } else if (phoneNumbers.length === 0) {
        helpersToast("Debe de subir un archivo CSV");
      } else {
        const numbers = phoneNumbers.map((item) => ({
          p_number: `${item.number}`.includes("+") ? `${item.number}` : `+${item.number}`,
          p_params: []
        }));
        const agents = agentsSelect.map((item) => item.id);
        const dateStart = getDateStartOfMonth();
        const dateEnd = getDateEndOfMonth();
        const body = {
          p_date_start: format(new Date(dateStart), "yyyy-MM-dd"),
          p_date_end: format(new Date(dateEnd), "yyyy-MM-dd"),
          p_type_response: bot ? "BOT" : "AGENT",
          p_id_plantilla: templateId,
          p_numbers: numbers,
          p_agents: agents,
          p_name: nameSend,
        };
        const response = await Controllers.chat.send_csv(body);
        if (response.response === "success") {
          if (cb) cb(response)
        } else {
          Helpers.toast.construct({
            response: "warning",
            message: "Error! en enviar mensaje difusión",
            toast,
          });
        }
      }
    } catch (error) {
      Helpers.toast.construct({
        response: "warning",
        message: "Error! en enviar mensaje difusión",
        toast,
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const sendTemplateEdit = async (temp, idxTemplate, cb) => {
    try {
      setIsLoading(true);
      if (!nameSend) {
        helpersToast("Debe de escribir un nombre a canal de difusión");
      } else if (temp.value === "") {
        helpersToast("Debe de seleccionar una plantilla");
      } else if (!bot && agentsSelect.length === 0) {
        helpersToast("Bot está desactivado, debe de seleccionar un agente");
      } else if (phoneNumbers.length === 0) {
        helpersToast("Debe de subir un archivo CSV");
      } else {
        const params = dataTemplates[idxTemplate]?.params || []
        const numbers = phoneNumbers.map((item) => {
          let data = {
            p_number: `${item.number}`.includes("+") ? `${item.number}` : `+${item.number}`,
          };
          data.p_params =
            btnActive === "2"
              ? params?.filter((_, i) =>
                  temp.label.includes(`{{${i + 1}}}`)
                )
              : [];
          return data;
        });
        const agents = agentsSelect.map((item) => item.id);
        const dateStart = getDateStartOfMonth();
        const dateEnd = getDateEndOfMonth();
        const body = {
          p_date_start: format(new Date(dateStart), "yyyy-MM-dd"),
          p_date_end: format(new Date(dateEnd), "yyyy-MM-dd"),
          p_type_response: bot ? "BOT" : "AGENT",
          p_id_plantilla: temp.value,
          p_numbers: numbers,
          p_agents: agents,
          p_name: nameSend,
        };
        const response = await Controllers.chat.send_csv(body);
        if (response.response === "success") {
          if (cb) cb(response)
        } else {
          Helpers.toast.construct({
            response: "warning",
            message: "Error! en enviar mensaje difusión",
            toast,
          });
        }
      }
    } catch (error) {
      Helpers.toast.construct({
        response: "warning",
        message: "Error! en enviar mensaje difusión",
        toast,
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleSubmit = () => {
    if (btnActive === '2') {
      for (let i = 0; i < listTemplatesEdit.length; i++) {
        const temp = listTemplatesEdit[i];
        sendTemplateEdit(
          temp,
          i,
          (i + 1) === listTemplatesEdit.length 
            ? (response) => {
              Helpers.toast.construct({ ...response, toast });
              resetForm();
            }
            : null 
        )
      }
    } else {
      for (let i = 0; i < listTemplates.length; i++) {
        const temp = listTemplates[i];
        sendTemplate(
          temp.value,
          (i + 1) === listTemplates.length 
            ? (response) => {
              Helpers.toast.construct({ ...response, toast });
              resetForm();
            }
            : null
        )
      }
    }
  };

  const addAgent = (item) => {
    const exit = agentsSelect.find((ag) => ag.id === item.id);
    if (!exit) {
      setAgentsSelect((prev) => [...prev, item]);
    }
  };

  const deleteAgent = (id) => {
    const rows = agentsSelect.filter((a) => a.id !== id);
    setAgentsSelect(rows);
  };

  const acceptableFileName = ["xlsx", "xls"];

  const checkFileName = (name) =>
    acceptableFileName.includes(name.split(".").pop().toLowerCase());

  const readDataFromExcel = (data) => {
    const wb = XLSX.read(data);
    let rows = [];

    wb.SheetNames.forEach((sheetName) => {
      const worksheet = wb.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const data = jsonData.filter(item => item['data1']).map(item => ({
        params: [
          {
            data: String(item["data1"] || ""),
          },
          {
            data: String(item["data2"] || ""),
          },
          {
            data: String(item["data3"] || ""),
          },
          {
            data: String(item["data4"] || ""),
          },
          {
            data: String(item["data5"] || ""),
          },
        ]
      }))

      if (btnActive === '2') {
        listTemplatesEdit.forEach((temp, i) => {
          const element = document.getElementById(`template-edit-send-${i}`);
          let message = temp.label;
          const params = data[i]?.params || []
          params.forEach((element, i) => {
            if (element.data) {
              const textReplace = `{{${i + 1}}}`;
              message = message.replaceAll(
                textReplace,
                `<div class='template-div' >${element.data}</div>`
              );
            } else {
              message = message.replaceAll(
                `{{${i + 1}`,
                "<div class='template-div' >1"
              );
              message = message.replaceAll(`${i + 1}}}`, "</div>");
            }
          });
          element.innerHTML = message;
        })
      }


      setDataTemplates(data)
      const list = jsonData
        .filter((item) => item.numero)
        .map((item) => ({
          number: item["numero"] || item["número"],
          data,
        }));
      rows = [...rows, ...list];
    });

    rows = rows.filter(
      (item) =>
        phoneNumbers.filter((num) => num.number === item.number).length === 0
    );

    setPhoneNumbers((prev) => [...prev, ...rows]);
    fileRef.current.value = "";
  };

  const handleFile = async (e) => {
    const myFile = e.target.files[0];
    if (!myFile) return;

    if (!checkFileName(myFile.name)) return;

    const data = await myFile.arrayBuffer();
    readDataFromExcel(data);
  };

  const handleChangeTemplate = (newTemplate, idx) => {
    const newList = listTemplatesEdit.map((oldTemplate, ii) => ii === idx ? newTemplate : oldTemplate)
    setListTemplatesEdit(newList)
    newList.forEach((temp, i) => {
      const element = document.getElementById(`template-edit-send-${i}`);
      if (dataTemplates.length > 0) {
        let message = temp.label;
        const params = dataTemplates[i]?.params || []
        params.forEach((element, i) => {
          if (element.data) {
            const textReplace = `{{${i + 1}}}`;
            message = message.replaceAll(
              textReplace,
              `<div class='template-div' >${element.data}</div>`
            );
          } else {
            message = message.replaceAll(
              `{{${i + 1}`,
              "<div class='template-div' >1"
            );
            message = message.replaceAll(`${i + 1}}}`, "</div>");
          }
        });
        element.innerHTML = message;
      } else {
        let message = temp.label;
        message = message.replaceAll("{{", "<div class='template-div' >");
        message = message.replaceAll("}}", "</div>");
        element.innerHTML = message;
      }
    })
  };

  // const selectTemplateEdit = useCallback(() => {
  //   switch (parseInt(id_customers)) {
  //     case 3:
  //       return sanCharbelEdit || [];
  //     case 18:
  //       return username === "lflores@grupotyc.com"
  //         ? []
  //         : username === "amamani@grupotyc.com"
  //         ? grupoTycMamaniEdit
  //         : [];
  //     case 19:
  //       return cantabriaEdit;
  //     case 21:
  //       return tiempoCasaEdit;
  //     case 24:
  //       return barquerosEdit;
  //     case 25:
  //       return dKasaEdit;

  //     default:
  //       return [];
  //   }
  // }, [id_customers, username]);

  const handleTime = () => {
    Helpers.toast.construct({
      response: "info",
      message: "Proximamente",
      toast,
    });
  };

  const renderListTemplates = listTemplates.map((temp, i) => (
    <div
      className="col-12"
      key={i}
      style={{
        zIndex: 10000 - i,
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* Item File */}
      {temp.imagen ? (
        <img
          onClick={() => {
            setViewImage({
              enabled: true,
              imageUrl: temp.imagen,
            });
          }}
          style={{
            width: 60,
            height: 60,
            cursor: "pointer",
            objectFit: "contain",
          }}
          src={temp.imagen}
          alt={temp.value}
        />
      ) : temp.files ? (
        <strong>
          <a
            href={temp.files}
            target="_blank"
            rel="noreferrer"
            style={{
              cursor: "pointer",
              padding: 10,
              margin: 5,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-filetype-pdf"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
              />
            </svg>
          </a>
        </strong>
      ) : null}

      <Select
        options={rowsTemplates || []}
        // invalid="El campo es obligatorio."
        onChange={(newTemplate) => {
          const newList = listTemplates.map((oldTemplate, ii) => ii === i ? newTemplate : oldTemplate)
          setListTemplates(newList)
        }}
        classNameParent={
          `${
            temp.imagen || temp.files
              ? i >= 1
                ? "col-9"
                : "col-10"
              : i >= 1
              ? "col-11"
              : "col-12"
          } mb-2 px-0`
        }
        // stylesProps={{ padding: "5px 2px" }}
        text={`Plantilla ${i + 1}`}
        value={temp}
      />

      {i >= 1 && (
        <Button
          style={{
            borderRadius: 5,
            padding: 0,
            width: 30,
            height: 30,
            margin: "auto 7px 7px",
          }}
          onClick={() =>
            setListTemplates(
              listTemplates.filter((_, index) => index !== i)
            )
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </Button>
      )}
    </div>
  ))

  const renderListTemplatesEdit = listTemplatesEdit.map((itemTemplate, i) => (
    <div 
      key={i}
      style={{
        zIndex: 2000 - i,
        position: "relative",
        border: '1px solid #d4d4d4',
        padding: 4,
        borderRadius: 3,
        marginBottom: 10,
      }}
      className="col-12"
      >
      <div 
        className="col-12"
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: 0
        }}
      >
        <Select
          options={rowsTemplatesEdit}
          classNameParent={i >= 1
            ? "col-11"
            : "col-12"
          }
          stylesProps={{ padding: "5px 2px", zIndex: 9999 }}
          text={`Plantilla para editar ${i + 1}`}
          onChange={(value) => {
            handleChangeTemplate(value, i);
          }}
          value={itemTemplate}
        />
        {i >= 1 && (
          <Button
            style={{
              borderRadius: 5,
              padding: 0,
              width: 30,
              height: 30,
              margin: "auto 7px 7px",
            }}
            onClick={() =>
              setListTemplatesEdit(
                listTemplatesEdit.filter((_, index) => index !== i)
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </Button>
        )}
      </div>
      <div
        id={`template-edit-send-${i}`}
        className={
          itemTemplate.value !== "" ? "contain-template col-12 mt-2" : "col-12"
        }
      ></div>
    </div>
  ))

  return (
    <>
      <RenderModalImage
        {...viewImage}
        onClose={() => {
          setViewImage({
            enabled: false,
            imageUrl: "",
          });
        }}
      />
      {openDate ? <InputDateTime close={() => setOpenDate(false)} /> : null}
      {isLoading && <Loading />}
      <div
        style={{
          position: 'relative',
          zIndex: 5,
        }}
        className={
          width < 700
            ? "col-12"
            : width < 800
            ? "col-10"
            : width < 900
            ? "col-8"
            : "col-6"
        }
      >
        <FormCustom
          className="px-2 py-4 br-3"
          bg="white"
          loadSubmit={isLoading}
          onSubmit={handleSubmit}
          onClickTime={handleTime}
          textButton="Enviar difusión"
        >
          <Input
            text="Nombre del envío"
            placeholder="Nombre del envío"
            classNameParent="col-12 mb-2"
            defaultValue={nameSend}
            value={nameSend}
            onChange={(e) => setNameSend(e.currentTarget.value)}
          />
          <Row
            className="col-12"
            style={{ margin: "0 auto", padding: "5px 0 0" }}
          >
            <a
              style={{
                fontSize: 12,
                fontWeight: "600",
                paddingLeft: 10,
              }}
              href="documents/csv.xlsx"
              download="CSV Tecnicom"
            >
              Descargar CSV
            </a>
            <SwitchTemplates value={btnActive} onChange={setBtnActive} />
          </Row>
          <Row
            className="col-12"
            style={{ margin: "0 auto", padding: "5px 0 0" }}
          >
            {parseInt(id_customers) === 24 && (
              <CheckBox
                onChange={(val) => setBot(!bot)}
                text="Responde por Bot"
                defaultChecked={bot}
                checked={bot}
                id="response-bot"
                classNameParent="col-8"
              />
            )}
          </Row>
          {btnActive === "1" ? (
            <>
              {renderListTemplates}
              <Button
                style={{
                  borderRadius: 5,
                  padding: "3px 7px",
                  margin: "10px auto",
                  fontSize: 12,
                }}
                onClick={() =>
                  setListTemplates(pre => 
                    [
                      ...pre,
                      {
                        value: "",
                        label: "",
                      }
                    ])
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-plus"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
                <span>Agregar plantilla</span>
              </Button>
            </>
          ) : (
            <>
              {renderListTemplatesEdit}
              <Button
                style={{
                  borderRadius: 5,
                  padding: "3px 7px",
                  margin: "10px auto",
                  fontSize: 12,
                }}
                disabled={listTemplatesEdit.length > 2}
                onClick={() =>
                  setListTemplatesEdit(pre => 
                    [
                      ...pre,
                      {
                        value: "",
                        label: "",
                      }
                    ])
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-plus"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
                <span>Agregar plantilla</span>
              </Button>
            </>
          )}
          {!bot && (
            <div
              className="col-12"
              style={{ zIndex: 900, position: "relative" }}
            >
              <Row className="mx-0 col-12 mb-2" style={{ padding: "5px 2px" }}>
                <AgentSelection
                  invalid="El campo es obligatorio."
                  deleteAgent={deleteAgent}
                  agents={agentsSelect}
                  onClickAdd={addAgent}
                  placeholder="Seleccionar agente"
                />
              </Row>
            </div>
          )}
          <div
            className="col-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <label
              htmlFor="input_files"
              style={{
                backgroundColor: "#D8572B",
                display: "inline-block",
                fontWeight: 400,
                textAlign: "center",
                verticalAlign: "middle",
                userSelect: "none",
                border: "1px solid #D8572B",
                fontSize: "1rem",
                lineHeight: 1.5,
                borderRadius: "0.25rem",
                color: "#ffffff",
                padding: "2px 10px",
                marginLeft: 5,
                cursor: "pointer",
              }}
            >
              <i className="fa fa-paperclip"></i>
              <span style={{ fontSize: 12, fontWeight: "500", marginLeft: 10 }}>
                Subir CSV
              </span>
              <input
                type="file"
                style={{ display: "none" }}
                id="input_files"
                onChange={(e) => handleFile(e)}
                accept=".xlsx, .xls"
                ref={fileRef}
              />
            </label>
          </div>
          <div
            className="col-12"
            style={{ maxHeight: "35vh", height: "100%", overflow: "auto" }}
          >
            {phoneNumbers.map((p, i) => (
              <div
                key={i}
                className="flex-row col-12 d-flex justify-content-between p-2 align-items-center"
                style={{ borderBottom: "1px solid #d4d4d4" }}
              >
                <strong>{`Nº${i + 1}: ${p.number}`}</strong>
                <div>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      const rows = phoneNumbers.filter(
                        (item) => item.number !== p.number
                      );
                      setPhoneNumbers(rows);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </FormCustom>
      </div>
    </>
  );
}
