import React, { useState } from 'react'
import { Input, FormCustom, SwitchToggle } from '../../Components/Form/Form'

const EventForm = ({ data, onSubmit, disablebSubmit, loadSubmit, idCustomers, idProject }) => {
    const [row, setRow] = useState({
        p_id_event: data.id_event,
        p_title_event: data.title_event,
        p_description_event: data.description_event,
        p_enabled_event: data.enabled_event,
        p_image_event: data.image_event,
        p_id_project: idProject,
        p_id_customers: idCustomers
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Titulo"
            placeholder="Titulo"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.title_event}
            onChange={e => setRow({ ...row, p_title_event: e.currentTarget.value })}
            maxLength={255}
        />
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.description_event}
            onChange={e => setRow({ ...row, p_description_event: e.currentTarget.value })}
            maxLength={255}
        />
        <Input
            text="Image URL"
            placeholder="Image URL"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.image_event}
            onChange={e => setRow({ ...row, p_image_event: e.currentTarget.value })}
            maxLength={255}
        />
        <SwitchToggle
            id="enabled-event"
            defaultChecked={parseInt(data.enabled_event) === 1}
            onChange={e => setRow({ ...row, p_enabled_event: e.currentTarget.checked ? 1 : 0 })}
        />
    </FormCustom>
}

EventForm.defaultProps = {
    data: {
        id_event: '',
        title_event: '',
        description_event: '',
        image_event: '',
        enabled_event: '0',
        id_project: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default EventForm;