import { useCallback } from "react";
import {
  barquerosEdit,
  cantabriaEdit,
  grupoTycMamaniEdit,
  sanCharbelEdit,
  tiempoCasaEdit,
  dKasaEdit,
} from "../db/state";

export default function useTemplateEdit(id_customers, username) {
  const templatesEdit = useCallback(() => {
    switch (parseInt(id_customers)) {
      case 3:
        return sanCharbelEdit || [];
      case 18:
        return username === "lflores@grupotyc.com"
          ? []
          : username === "amamani@grupotyc.com"
          ? grupoTycMamaniEdit
          : [];
      case 19:
        return cantabriaEdit;
      case 21:
        return tiempoCasaEdit;
      case 24:
        return barquerosEdit;
      case 25:
        return dKasaEdit;

      default:
        return [];
    }
  }, [id_customers, username]);

  return templatesEdit();
}
