import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import {
  FormCustom,
  // SwitchToggle,
  Select,
  Input,
  // TextArea
} from "../../Components/Form/Form";
import { toast } from "react-toastify";
import Helpers from "../../Components/Helpers/Helpers";
import Controllers from "../../Api/Controllers";
// import SweetAlert from '../../Components/SweetAlert/SweetAlert'

const FormChat = (_) => {
  const [row, setRow] = useState({
    p_id_form: "",
    p_form_det: [],
  });
  const [ready, setReady] = useState(false);

  /* const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({}) */
  const [loadSubmit, setLoadSubmit] = useState(false);
  const optionsType = [
    { label: "EMAIL", value: "EMAIL" },
    { label: "LISTA", value: "SELECT" },
    { label: "TEXTO", value: "TEXT" },
    { label: "TEXTO AREA", value: "TEXTAREA" },
  ];

  useEffect(() => {
    Controllers.form
      .get_form()
      .then((res) => {
        setRow({
          p_id_form: res.data.id_form,
          p_form_det: res.data.form_det,
        });
        setReady(true);
      })
      .catch(console.error);
  }, []);

  const handleShowModalDelete = (e) => {
    setRow({
      ...row,
      p_form_det: row.p_form_det.filter(
        (f) =>
          parseInt(f.id_form_det) !==
          parseInt(e.currentTarget.dataset.idFormDet)
      ),
    });
  };
  /* const handleShowModalDelete = e => {
        let { idFormDet } = e.currentTarget.dataset
        setDataDeleted(idFormDet)
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.form.form_det_delete({
            p_id_form_det: dataDeleted
        }).then(res => {
            setRow({
                ...row,
                p_form_det: row.p_form_det.filter(f => parseInt(f.id_form_det) !== parseInt(dataDeleted))
            })

            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted('')
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmitDelete(false)
        })
    } */

  return (
    <>
      <Card>
        <Card.Header className="justify-content-between">
          <span>Formulario Contacto</span>
          <Button
            size="sm"
            onClick={(_) =>
              setRow({
                ...row,
                p_form_det: [
                  ...row.p_form_det,
                  {
                    type: "",
                    max_length: "",
                    field_name: "",
                    key_name: "",
                    required: "",
                    list_values: [],
                    list_item: "",
                  },
                ],
              })
            }
          >
            <i className="fa fa-plus"></i> Agregar Item
          </Button>
        </Card.Header>
        <Card.Body>
          {ready ? (
            <FormCustom
              loadSubmit={loadSubmit}
              onSubmit={(_) => {
                let typeNotCompleted = false;
                let listEmpty = false;

                row.p_form_det.forEach((f) => {
                  if (!f.type) {
                    typeNotCompleted = true;
                  }

                  if (f.type === "SELECT") {
                    if (f.list_values.length === 0) {
                      listEmpty = true;
                    }
                  }
                });

                if (typeNotCompleted) {
                  Helpers.toast.construct({
                    response: "warning",
                    message: "Seleccionar todos los tipos",
                    toast,
                  });
                  return;
                }

                if (listEmpty) {
                  Helpers.toast.construct({
                    response: "warning",
                    message: "Llene items en la lista",
                    toast,
                  });
                  return;
                }

                setLoadSubmit(true);
                Controllers.form
                  .form_insert(row)
                  .then((res) => {
                    Helpers.toast.construct({ ...res, toast });
                  })
                  .finally((_) => setLoadSubmit(false));
              }}
            >
              <div className="col-12">
                <table>
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Campo</th>
                      <th>Clave</th>
                      <th>Lista</th>
                      <th>Long. Caracteres</th>
                      {/* <th>Obligatorio</th> */}
                      <th>
                        <i className="fa fa-cog"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {React.Children.toArray(
                      row.p_form_det.map((f, i) => {
                        let typeSelected = optionsType.find(
                          (o) => o.value === f.type
                        );
                        return (
                          <tr>
                            <td>
                              <Select
                                options={optionsType}
                                value={typeSelected ? typeSelected : ""}
                                onChange={(e) =>
                                  setRow({
                                    ...row,
                                    p_form_det: row.p_form_det.map((ff, ii) => {
                                      if (i === ii) {
                                        ff.type = e.value;
                                      }
                                      return ff;
                                    }),
                                  })
                                }
                              />
                            </td>
                            <td>
                              <Input
                                placeholder="Nombre Campo"
                                value={f.field_name}
                                onChange={(e) =>
                                  setRow({
                                    ...row,
                                    p_form_det: row.p_form_det.map((ff, ii) => {
                                      if (i === ii) {
                                        ff.field_name = e.currentTarget.value;
                                      }
                                      return ff;
                                    }),
                                  })
                                }
                                style={{
                                  minWidth: 60,
                                }}
                                required
                              />
                            </td>
                            <td>
                              <Input
                                placeholder="Clave Campo"
                                value={f.key_name}
                                onChange={(e) =>
                                  setRow({
                                    ...row,
                                    p_form_det: row.p_form_det.map((ff, ii) => {
                                      if (i === ii) {
                                        ff.key_name = e.currentTarget.value;
                                      }
                                      return ff;
                                    }),
                                  })
                                }
                                style={{
                                  minWidth: 60,
                                }}
                                required
                              />
                            </td>
                            <td>
                              {f.type === "SELECT" ? (
                                <>
                                  <Input
                                    placeholder="Item"
                                    value={f.list_item}
                                    required={
                                      f.type === "SELECT" &&
                                      f.list_values.length === 0
                                    }
                                    onChange={(e) =>
                                      setRow({
                                        ...row,
                                        p_form_det: row.p_form_det.map(
                                          (ff, ii) => {
                                            if (i === ii) {
                                              ff.list_item =
                                                e.currentTarget.value;
                                            }
                                            return ff;
                                          }
                                        ),
                                      })
                                    }
                                    style={{
                                      minWidth: 60,
                                    }}
                                    rows="4"
                                    append={[
                                      <Button
                                        onClick={(_) => {
                                          if (!f?.list_item) {
                                            return;
                                          }
                                          setRow({
                                            ...row,
                                            p_form_det: row.p_form_det.map(
                                              (ff, ii) => {
                                                if (i === ii) {
                                                  ff.list_values = [
                                                    ...ff.list_values,
                                                    ff.list_item,
                                                  ];
                                                }
                                                return ff;
                                              }
                                            ),
                                          });
                                        }}
                                      >
                                        <i className="fa fa-plus"></i>
                                      </Button>,
                                    ]}
                                  />
                                  <div className="d-flex flex-column col-12 mt-2">
                                    <small>Lista</small>
                                    {React.Children.toArray(
                                      f.list_values.map((l) => <div>{l}</div>)
                                    )}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              {f.type === "TEXT" ||
                              f.type === "EMAIL" ||
                              f.type === "TEXTAREA" ? (
                                <Input
                                  placeholder="Long. Caracteres"
                                  type="number"
                                  value={f.max_length}
                                  onChange={(e) =>
                                    setRow({
                                      ...row,
                                      p_form_det: row.p_form_det.map(
                                        (ff, ii) => {
                                          if (i === ii) {
                                            ff.max_length =
                                              e.currentTarget.value;
                                          }
                                          return ff;
                                        }
                                      ),
                                    })
                                  }
                                  style={{
                                    minWidth: 60,
                                  }}
                                  required
                                />
                              ) : (
                                ""
                              )}
                            </td>
                            {/* <td align="center">
                                            <SwitchToggle
                                                id={'frm_active_' + i}
                                                textCheck=""
                                                textUnChecked=""
                                                defaultChecked={parseInt(f.required) === 1}
                                                onChange={e => setRow({
                                                    ...row,
                                                    p_form_det: row.p_form_det.map((ff, ii) => {
                                                        if (i === ii) {
                                                            ff.required = e.currentTarget.checked ? 1 : 0
                                                        }
                                                        return ff
                                                    })
                                                })}
                                            />
                                        </td> */}
                            <td align="center">
                              <Button
                                variant="danger"
                                data-id-form-det={f.id_form_det}
                                size="xs"
                                onClick={handleShowModalDelete}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </FormCustom>
          ) : (
            <small className="text-muted">Cargando...</small>
          )}
        </Card.Body>
      </Card>
      {/* <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar Item?</>}
            subtitle={<>#{dataDeleted}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        /> */}
    </>
  );
};

export default FormChat;
