import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import ProjectForm from './ProjectForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { useSelector } from 'react-redux';
import Area from '../Area/Area'
import Event from '../Event/Event'
import Footage from '../Footage/Footage'
import Keyword from '../Keyword/Keyword'
import ProjectRooms from '../ProjectRooms/ProjectRooms';
/* import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup' */

const Project = ({ nameSingle, namePlural, separator }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})

    const masterUser = useSelector(store => store.session.master_user)

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.project.get_project().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.project.project_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
            setReload(true)
        })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idProject) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let { idProject } = $this.dataset

        Controllers.project.get_project_id({ p_id_project: idProject }).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idProject}`)
            setDataUpdated({ data: res.data })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
        .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.project.project_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idProject, nameProject } = e.currentTarget.dataset
        setDataDeleted({
            p_id_project: idProject,
            p_name_project: nameProject
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const [dataArea, setDataArea] = useState({})
    const [modalShowArea, setModalShowArea] = useState(false)
    const [modalTitleArea, setModalTitleArea] = useState('')
    const handleArea = e => {
        let { idProject, idCustomers } = e.currentTarget.dataset
        setDataArea({ 
            idProject, idCustomers
        })
        setModalTitleArea('Información de áreas del Proyecto #' + idProject)
        setModalShowArea(true)
    }

    const [dataEvent, setDataEvent] = useState({})
    const [modalShowEvent, setModalShowEvent] = useState(false)
    const [modalTitleEvent, setModalTitleEvent] = useState('')
    const handleEvent = e => {
        let { idProject, idCustomers } = e.currentTarget.dataset
        setDataEvent({ 
            idProject, idCustomers
        })
        setModalTitleEvent('Información de eventos del Proyecto #' + idProject)
        setModalShowEvent(true)
    }

    const [dataFootage, setDataFootage] = useState({})
    const [modalShowFootage, setModalShowFootage] = useState(false)
    const [modalTitleFootage, setModalTitleFootage] = useState('')
    const handleFootage = e => {
        let { idProject, idCustomers } = e.currentTarget.dataset
        setDataFootage({ 
            idProject, idCustomers
        })
        setModalTitleFootage('Información de metrajes del Proyecto #' + idProject)
        setModalShowFootage(true)
    }

    const [dataKeyword, setDataKeyword] = useState({})
    const [modalShowKeyword, setModalShowKeyword] = useState(false)
    const [modalTitleKeyword, setModalTitleKeyword] = useState('')
    const handleKeyword = e => {
        let { idProject, idCustomers } = e.currentTarget.dataset
        setDataKeyword({ 
            idProject, idCustomers
        })
        setModalTitleKeyword('Información de keywords del Proyecto #' + idProject)
        setModalShowKeyword(true)
    }
    
    const [dataRooms, setDataRooms] = useState({})
    const [modalShowRooms, setModalShowRooms] = useState(false)
    const [modalTitleRooms, setModalTitleRooms] = useState('')
    const handleRooms = e => {
        let { idProject, idCustomers } = e.currentTarget.dataset
        setDataRooms({ 
            idProject, idCustomers
        })
        setModalTitleRooms('Información de Stock del Proyecto #' + idProject)
        setModalShowRooms(true)
    }

    const dtRows = useMemo(() => {
        let headTable = [
            /* { name: 'id_project', text: 'ID', align: 'center' }, */
            { name: 'name_project', text: 'Nombres' },
            { name: 'key_project', text: 'Clave', align: 'center' },
            { name: 'address_project', text: 'Dirección', align: 'center' },
            { name: 'enabled_project', text: 'Estado', align: 'center', render: r => <div className={'badge badge-' + (parseInt(r.enabled_project) === 1 ? 'success' : 'danger')}>
                {parseInt(r.enabled_project) === 1 ? 'Activo' : 'Inactivo'}
            </div> },
            { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                return <div>
                    <Button variant="info" className="mr-1" size="xs" data-id-project={u.id_project} onClick={handleGet}>
                        <i className="fa fa-edit"></i> Editar
                    </Button>
                    <Button variant="danger" className="mr-1" size="xs" data-id-project={u.id_project} data-name-project={u.name_project} onClick={handleShowModalDelete}>
                        <i className="fa fa-trash"></i> Eliminar
                    </Button>
                    <Button variant="secondary" className="mr-1" size="xs" data-id-project={u.id_project} data-id-customers={u.id_customers} onClick={handleArea}>
                        <i className="fa fa-home mr-1"></i>Áreas Comunes
                    </Button>
                    <Button variant="blue" className="mr-1" size="xs" data-id-project={u.id_project} data-id-customers={u.id_customers} onClick={handleEvent}>
                        <i className="far fa-handshake mr-1"></i>Eventos
                    </Button>
                    <Button variant="dark" className="mr-1" size="xs" data-id-project={u.id_project} data-id-customers={u.id_customers} onClick={handleFootage}>
                        <i className="fas fa-vector-square mr-1"></i>Metrajes
                    </Button>
                    {masterUser ? <Button variant="secondary" className="mr-1" size="xs" data-id-project={u.id_project} data-id-customers={u.id_customers} onClick={handleKeyword}>
                        <i className="far fa-keyboard mr-1"></i>Keywords
                    </Button> : ''}
                    {<Button variant="secondary" className="mr-1" size="xs" data-id-project={u.id_project} data-id-customers={u.id_customers} onClick={handleRooms}>
                        <i className="far fa-keyboard mr-1"></i>Stock
                    </Button>}
                    {/* <DropdownButton
                        size="xs"
                        as={ButtonGroup}
                        variant={'secondary'}
                        title={<i className="fa fa-cog"></i>}
                    >
                        <Dropdown.Item eventKey="1" data-id-project={u.id_project} data-id-customers={u.id_customers} onClick={handleKeyword}>
                            <i className="fa fa-check mr-1"></i>Keywords
                        </Dropdown.Item>
                    </DropdownButton> */}
                </div>
            } },
        ]

        if (masterUser) {
            headTable.unshift({ name: 'name_customers', text: 'Cliente', align: 'center' })
        }

        return <ReactDataTableBootstrap
            head={[
                headTable,
            ]}
            rows={rows}
            isProcessing={isProcessing}
        />
    }, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Listado De {namePlural}</span>
                <Button size="sm" onClick={handleGet}>
                    <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                </Button>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProjectForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} />
            </Modal.Body>
        </Modal>
        <Modal show={modalShowArea} onHide={() => setModalShowArea(false)} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitleArea}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Area namePlural="Áreas" nameSingle="Area" separator="la" {...dataArea} />
            </Modal.Body>
        </Modal>
        <Modal show={modalShowEvent} onHide={() => setModalShowEvent(false)} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitleEvent}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Event namePlural="Eventos" nameSingle="Evento" separator="el" {...dataEvent} />
            </Modal.Body>
        </Modal>
        <Modal show={modalShowFootage} onHide={() => setModalShowFootage(false)} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitleFootage}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Footage namePlural="Metrajes" nameSingle="Metraje" separator="el" {...dataFootage} />
            </Modal.Body>
        </Modal>
        <Modal show={modalShowKeyword} onHide={() => setModalShowKeyword(false)} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitleKeyword}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Keyword namePlural="Keywords" nameSingle="Keyword" separator="la" {...dataKeyword} />
            </Modal.Body>
        </Modal>
        <Modal show={modalShowRooms} onHide={() => setModalShowRooms(false)} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitleRooms}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProjectRooms namePlural="Stock" nameSingle="Stock" separator="el" {...dataRooms} />
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_project} - {dataDeleted.p_name_project}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default Project