import React from "react";

function TableServiceItem({ service, deleteService, setShowEdit, setServiceToEdit, setShowAdd }) {
  return (
    <tr key={service.id}>
      <td>{service.service}</td>
      <td>{service.keywords}</td>
      <td>
        <div className="d-flex w-100 justify-content-center align-items-center">
          <button
            className="btn btn-blue btn-sm mr-4 p-2"
            onClick={() => {
              setShowAdd(false);
              setShowEdit(pv => !pv);
              setServiceToEdit(service);
            }}
          >
            Editar
          </button>
          <button
            className="btn btn-danger btn-sm p-2"
            onClick={() => deleteService(service.id)}
          >
            Eliminar
          </button>
        </div>
      </td>
    </tr>
  );
}

export default TableServiceItem;
