import React, { useState, useEffect, useCallback } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ReactDataTableBootstrap from "../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap";
import Helpers from "../../Components/Helpers/Helpers";
import { FormCustom, Input } from "../../Components/Form/Form";
import Controllers from "../../Api/Controllers";

export default function BellReport({ typeBot }) {
  const [isProcessing, setIsProcessing] = useState(true);
  const [rows, setRows] = useState([]);
  const [formFilter, setFormFilter] = useState({
    p_date_start: Helpers.date.get({ format: "%Y-%m-%d" }),
    p_date_end: Helpers.date.get({ format: "%Y-%m-%d" }),
  });

  useEffect(() => {
    setFormFilter({
      p_date_start: Helpers.date.get({ format: "%Y-%m-%d" }),
      p_date_end: Helpers.date.get({ format: "%Y-%m-%d" }),
    });
    getCampaign();
  }, []);

  const getCampaignId = (p_id_campaign) => {
    Controllers.chat.get_report_csv_id({ p_id_campaign }).then(({ data }) => {
      var link = document.createElement("a");
      link.href = data.file;
      link.download = data.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const dtRows = useCallback(() => {
    let headTable = [
      // { name: "", text: "Item" },
      { name: "name", text: "Nombre de envio" },
      { name: "date", text: "Fecha", align: "center" },
      { name: "hour", text: "Hora", align: "center" },
      { name: "username", text: "Usuario", align: "center" },
      {
        name: "",
        text: "Descargar",
        align: "center",
        render: (u) => {
          return (
            <div>
              <Button
                variant="info"
                className="mr-1"
                size="xs"
                onClick={() => getCampaignId(u.id_campaign)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <ReactDataTableBootstrap
        head={[headTable]}
        rows={rows}
        isProcessing={isProcessing}
      />
    );
  }, [rows, isProcessing]);

  const getCampaign = useCallback(() => {
    setIsProcessing(true);
    Controllers.chat
      .get_report_csv(formFilter)
      .then((res) => setRows(res.data))
      .finally(() => setIsProcessing(false));
  }, [formFilter]);

  return (
    <>
      <Card className="mb-4">
        <Card.Header className="justify-content-between">
          <span>Filtrar Por Fechas</span>
        </Card.Header>
        <Card.Body>
          <FormCustom
            onSubmit={(_) => getCampaign()}
            viewSubmit={false}
            className="mb-4"
          >
            <Input
              icon="far fa-calendar-alt"
              text="Fecha Inicio"
              type="date"
              required
              classNameParent="col-12 col-md-6"
              value={formFilter.p_date_start}
              onChange={(e) =>
                setFormFilter({
                  ...formFilter,
                  p_date_start: e.currentTarget.value,
                })
              }
            />
            <Input
              icon="far fa-calendar-alt"
              text="Fecha Fin"
              type="date"
              required
              classNameParent="col-12 col-md-6"
              append={[
                <Button type="submit">
                  <i className="fa fa-search mt-1"></i> Filtrar Información
                </Button>,
              ]}
              value={formFilter.p_date_end}
              onChange={(e) =>
                setFormFilter({
                  ...formFilter,
                  p_date_end: e.currentTarget.value,
                })
              }
            />
          </FormCustom>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header className="justify-content-between">
          <span>Reportes de campaña</span>
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 15 }}
          >
            <span style={{ fontWeight: "700" }}>Status</span>
            <span style={{ fontWeight: "500", margin: "4px 0" }}>
              ✅ Delivered: Whatsapp enviado
            </span>
            <span style={{ fontWeight: "500" }}>
              🚨 Failed: Error en el envio
            </span>
          </div>
          <span></span>
          <span></span>
        </Card.Header>
        <Card.Body>{dtRows()}</Card.Body>
      </Card>
    </>
  );
}
