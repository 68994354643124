/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/esm/Button";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { format } from "date-fns";

import SwitchTemplates from "../../Components/buttons/SwitchTemplates";
import { RenderModalImage } from "../../Components/modals/NewMessage";
import EditableElement from "../../Components/common/EditableElement";
import useTemplateEdit from "../../hooks/useTemplateEdit";
import Helpers from "../../Components/Helpers/Helpers";
import Select from "../../Components/Form/Select";
import Controllers from "../../Api/Controllers";
import { hours, minutes } from "../../db/state";
import Portal from "./Portal";

export default function ProgramButton(props) {
  const [open, setOpen] = useState(false);

  const { onClose, currentChat, templates, messages } = props;

  return (
    <>
      {open && (
        <ModalScheduleTime
          onClose={() => {
            setOpen(false);
            if (onClose) {
              onClose();
            }
          }}
          currentChat={currentChat}
          templates={templates}
          messages={messages}
        />
      )}
      <Button
        style={{
          backgroundColor: "#24A4CC",
          borderColor: "#24A4CC",
          borderRadius: 5,
          marginRight: 5,
          marginLeft: 3,
          height: 35,
          padding: 0,
          width: 35,
          minWidth: 35,
        }}
        onClick={() => setOpen(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-clock"
          viewBox="0 0 16 16"
        >
          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
        </svg>
      </Button>
    </>
  );
}

function ModalScheduleTime(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [switchActive, setSwitchActive] = useState("1");
  const [idTemplate, setIdTemplate] = useState("");
  const [initial, setInitial] = useState(false);
  const [deadline, setDeadline] = useState("");
  const [message, setMessage] = useState("");
  const [time, setTime] = useState({
    hours: "00",
    minutes: "00",
  });
  const [viewImage, setViewImage] = useState({
    enabled: false,
    imageUrl: "",
  });
  const [templateEdit, setTemplateEdit] = useState({
    value: "",
    label: "",
  });

  const { templates, currentChat, onClose } = props;

  const { id_customers, username } = useSelector((store) => store.session);

  const handleChangeTime = (name, value) => setTime({ ...time, [name]: value });

  const isWhatsapp = useCallback(
    () => currentChat.resource === "WHATSAPP",
    [currentChat]
  );

  const isFacebook = useCallback(
    () => currentChat.resource === "FACEBOOK",
    [currentChat]
  );

  useEffect(() => {
    const messagesCurrent = props.messages.filter(
      (item) => item.from_user === 0
    );
    const msgPop = messagesCurrent.pop();
    let dateCreate = new Date(msgPop.date_created);
    dateCreate.setDate(dateCreate.getDate() + 1);
    setDeadline(dateCreate);
  }, [props.messages]);

  const templateActive = useCallback(() => {
    if (isWhatsapp()) {
      if (startDate && time.hours && time.minutes && deadline) {
        const date = format(startDate, "P");
        const dateFormat = `${date} ${time.hours}:${time.minutes}`;
        const dateTime = new Date(dateFormat);
        const dateMsg = new Date(deadline);
        return dateMsg.getTime() < dateTime.getTime();
      }
      return false;
    } else {
      return false;
    }
  }, [deadline, isWhatsapp, startDate, time.hours, time.minutes]);

  useEffect(() => {
    if (!initial) {
      setInitial(true);
      const date = new Date();
      setStartDate(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const template = useCallback(() => {
    return templates.find((item) => item.value === idTemplate);
  }, [idTemplate, templates]);

  const templatesEdit = useTemplateEdit(id_customers, username);

  const handleSubmit = async () => {
    try {
      const date = format(new Date(startDate), "	yyyy-MM-dd");
      const p_date = `${date} ${time.hours}:${time.minutes}:00`;
      let data = {
        p_date,
        p_id_chat: currentChat.id_chat,
        p_message: "",
        p_id_template: "",
        p_template_data: [],
      };
      if (isFacebook()) {
        const response = await Controllers.chat.chat_message_time({
          ...data,
          p_message: message,
        });
        Helpers.toast.construct({ ...response, toast });
        onClose();
      } else if (isWhatsapp()) {
        const params = document.querySelectorAll(".template-div-input-buttons");
        const p_params = Array.from(params)
          .map((item) => ({
            data: item.innerText,
          }))
          .filter((_, i) => templateEdit.label.includes(`{{${i + 1}}}`));

        const response = await Controllers.chat.chat_message_time({
          ...data,
          p_message: switchActive === "3" ? message : "",
          p_id_template:
            switchActive === "1"
              ? idTemplate
              : switchActive === "2"
              ? templateEdit.value
              : "",
          p_template_data: switchActive === "2" ? p_params : [],
        });
        Helpers.toast.construct({ ...response, toast });
        onClose();
      }
    } catch (error) {
      Helpers.toast.construct({
        response: "warning",
        message: "No se ha pddido enviar el mensaje.",
        toast,
      });
    }
  };

  const handleChangeTemplate = (template) => {
    setTemplateEdit(template);
    const element = document.getElementById("template-edit-input-buttons");
    let message = template.label.replaceAll(
      "{{",
      "<div contenteditable class='template-div-input-buttons' >"
    );
    message = message.replaceAll("}}", "</div>");
    element.innerHTML = message;
  };

  return (
    <Portal>
      <RenderModalImage {...viewImage} onClose={onClose} />
      <div
        style={{
          background: "#ffffff",
          borderRadius: 10,
          padding: 20,
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            fontSize: "1.2em",
            fontWeight: "600",
            color: "#1b1b1b",
            marginBottom: 10,
          }}
        >
          Programar envío de mensaje
        </span>
        <div className="contain_date">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "1em",
                fontWeight: "500",
                color: "#b6b6b6",
                marginBottom: 10,
              }}
            >
              Fecha
            </span>
            <DatePicker
              showIcon
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: 5,
            }}
          >
            <span
              style={{
                fontSize: "1em",
                fontWeight: "500",
                color: "#b6b6b6",
                marginBottom: 10,
              }}
            >
              Hora
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                zIndex: 1000,
              }}
            >
              <Select
                options={hours}
                placeholder="00"
                width={80}
                onChange={({ value }) => handleChangeTime("hours", value)}
                stylesProps={{
                  width: "80px",
                  margin: "0 5px",
                }}
                value={hours.find((item) => item.value === time.hours)}
              />
              :
              <Select
                width={80}
                stylesProps={{
                  width: "80px",
                  margin: "0 5px",
                }}
                options={minutes}
                placeholder="00"
                onChange={({ value }) => handleChangeTime("minutes", value)}
                value={hours.find((item) => item.value === time.minutes)}
              />
            </div>
          </div>
        </div>
        <div style={{ padding: "10px 0", width: "100%" }}>
          <div
            style={{
              margin: "10px 0 10px auto",
              maxWidth: templateActive() ? 80 : 110,
            }}
          >
            {!isFacebook() && (
              <SwitchTemplates
                onChange={setSwitchActive}
                value={switchActive}
                enabledMsg={!templateActive()}
              />
            )}
          </div>
          {switchActive === "1" && !isFacebook() ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginRight: "auto",
              }}
            >
              {template()?.imagen && (
                <img
                  onClick={() => {
                    setViewImage({
                      enabled: true,
                      imageUrl: template().imagen,
                    });
                  }}
                  style={{
                    width: 45,
                    height: 45,
                    cursor: "pointer",
                    objectFit: "contain",
                  }}
                  src={template().imagen}
                  alt="image preview"
                />
              )}
              {template()?.files && (
                <strong>
                  <a
                    href={template().files}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      cursor: "pointer",
                      padding: 5,
                      margin: 5,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-filetype-pdf"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                      />
                    </svg>
                  </a>
                </strong>
              )}
              <Select
                options={templates}
                stylesProps={{
                  width: "100%",
                  margin: "0 10px",
                }}
                placeholder="Seleccionar plantilla"
                onChange={(e) => setIdTemplate(e.value)}
                value={templates.find((item) => item.value === idTemplate)}
              />
            </div>
          ) : switchActive === "2" && !isFacebook() ? (
            <div style={{ display: "grid", position: "relative" }}>
              <Select
                options={templatesEdit}
                classNameParent="col-12 mb-2 px-0"
                placeholder="Seleccionar Plantilla para editar"
                onChange={(value) => {
                  handleChangeTemplate(value);
                }}
                value={templateEdit}
              />
              <div
                id="template-edit-input-buttons"
                style={{ maxWidth: 350, width: "90%", margin: "0 auto" }}
                className={
                  templateEdit.label ? "contain-template-absolute" : ""
                }
              ></div>
            </div>
          ) : switchActive === "3" && !isFacebook() ? (
            <div
              style={{
                width: "100%",
                margin: 0,
                marginTop: 20,
                border: "1px solid #d4d4d4",
              }}
              className="contain-input"
            >
              <EditableElement
                onChange={(text) => setMessage(text)}
                onChangeImage={(image) => console.log(image)}
                onChangeImageSend={(image) => console.log(image)}
              >
                <div className="input-div" id="imageUpload"></div>
              </EditableElement>
              {message ? null : (
                <div className="input-message">Escribe un mensaje aquí</div>
              )}
            </div>
          ) : null}
          {isFacebook() && (
            <div
              style={{
                width: "100%",
                margin: 0,
                marginTop: 20,
                border: "1px solid #d4d4d4",
              }}
              className="contain-input"
            >
              <EditableElement
                onChange={(text) => setMessage(text)}
                onChangeImage={(image) => console.log(image)}
                onChangeImageSend={(image) => console.log(image)}
              >
                <div className="input-div" id="imageUpload"></div>
              </EditableElement>
              {message ? null : (
                <div className="input-message">Escribe un mensaje aquí</div>
              )}
            </div>
          )}
        </div>
        <div>
          <Button
            style={{ margin: "10px 5px", borderRadius: 5 }}
            variant="secondary"
            onClick={onClose}
          >
            <span style={{ margin: "0 10px", fontWeight: "500" }}>
              Cancelar
            </span>
          </Button>
          <Button
            style={{ margin: "10px 5px", borderRadius: 5 }}
            onClick={handleSubmit}
          >
            <span style={{ margin: "0 10px", fontWeight: "500" }}>
              Programar envío
            </span>
          </Button>
        </div>
      </div>
    </Portal>
  );
}
