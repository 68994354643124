import styled from 'styled-components'
import { widthResize, widthFull, heightFooter } from '../Resources/Theme'

export default styled.footer`
  width: calc(100% - ${props => props.resize ? widthResize : widthFull});
  margin-left: ${props => props.resize ? widthResize : widthFull};
  transition: .5s;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${heightFooter};

  @media (max-width: 992px) {
    margin-left: 0;
    width: 100%;
  }
`