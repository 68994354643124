import React, { useState } from 'react'
import { useEffect } from 'react'
import Controllers from '../../Api/Controllers'
import { Input, SwitchToggle, FormCustom, Select } from '../../Components/Form/Form'
import ReactPlaceholder from 'react-placeholder'
import { useSelector } from 'react-redux'

const ProjectForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_project: data.id_project,
        p_id_customers: data.id_customers,
        p_name_project: data.name_project,
        p_key_project: data.key_project,
        p_address_project: data.address_project,
        p_rooms_project: data.rooms_project,
        p_price_project: data.price_project,
        p_brochure_project: data.brochure_project,
        p_enabled_project: data.enabled_project,
    })

    const masterUser = useSelector(store => store.session.master_user)
    const [ready, setReady] = useState(false)
    const [customers, setCustomers] = useState([])
    useEffect(() => {
        if (masterUser && isNaN(parseInt(data.id_project))) {
            Controllers.customers.get_customers_public()
            .then(res => {
                setReady(true)
                setCustomers(res.data)
            })
        } else {
            setReady(true)
        }
    }, [])

    return <ReactPlaceholder rows={15} showLoadingAnimation ready={ready}>
        <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            {masterUser ? (isNaN(parseInt(data.id_project))? <Select
                options={customers.map(c => {
                    return {
                        label: c.name_customers,
                        value: c.id_customers
                    }
                })}
                text="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                onChange={e => setRow({ ...row, p_id_customers: e.value })}
            /> : <Input
                text="Cliente"
                placeholder="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                readOnly
                defaultValue={data.name_customers}
            />) : ''}
            <Input
                text="Nombres"
                placeholder="Nombres"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.name_project}
                onChange={e => setRow({ ...row, p_name_project: e.currentTarget.value })}
                maxLength={255}
            />
            {/* <Input
                text="Clave"
                placeholder="Clave"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.key_project}
                onChange={e => setRow({ ...row, p_key_project: e.currentTarget.value })}
                maxLength={100}
            /> */}
            <Input
                text="Dirección"
                placeholder="Dirección"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.address_project}
                onChange={e => setRow({ ...row, p_address_project: e.currentTarget.value })}
                maxLength={100}
            />
            <Input
                text="Número Habitaciones"
                placeholder="Número Habitaciones"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.rooms_project}
                onChange={e => setRow({ ...row, p_rooms_project: e.currentTarget.value })}
                maxLength={255}
            />
            <Input
                text="Precio"
                placeholder="Precio"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.price_project}
                onChange={e => setRow({ ...row, p_price_project: e.currentTarget.value })}
                maxLength={255}
            />
            <Input
                text="Brochure"
                placeholder="Brochure"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.brochure_project}
                onChange={e => setRow({ ...row, p_brochure_project: e.currentTarget.value })}
                maxLength={255}
            />
            <SwitchToggle
                id="enabled-project"
                defaultChecked={parseInt(data.enabled_project) === 1}
                onChange={e => setRow({ ...row, p_enabled_project: e.currentTarget.checked ? 1 : 0 })}
            />
        </FormCustom>
    </ReactPlaceholder>
}

ProjectForm.defaultProps = {
    data: {
        id_project: '',
        id_customers: '',
        name_project: '',
        key_project: '',
        address_project: '',
        rooms_project: '',
        price_project: '',
        brochure_project: '',
        enabled_project: '0',
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default ProjectForm;