import React, { useState, useCallback } from 'react'
import { useEffect } from 'react'
import Controllers from '../../Api/Controllers'
import { Input, SwitchToggle, FormCustom, Select, CheckBox } from '../../Components/Form/Form'
import ReactPlaceholder from 'react-placeholder'
import { useSelector } from 'react-redux'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify'

const SellerForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_seller: data.id_seller,
        p_name_seller: data.name_seller,
        p_email_seller: data.email_seller,
        p_phone_seller: data.phone_seller,
        p_enabled_seller: data.enabled_seller,
        p_id_customers: data.id_customers,
        p_has_user: data.has_user,
        p_id_customers_contact: data.id_customers_contact,
        p_username: data.username,
        p_password: data.password,
        p_id_profile: data.id_profile
    })

    const masterUser = useSelector(store => store.session.master_user)
    const [ready, setReady] = useState(false)
    const [customers, setCustomers] = useState([])
    const [profiles, setProfiles] = useState([])

    useEffect(() => {
        if (masterUser && isNaN(parseInt(data.id_seller))) {
            Promise.all([
                Controllers.customers.get_customers_public(),
                Controllers.profile.get_profile()
            ])
            .then(res => {
                setCustomers(res[0].data)
                setProfiles(res[1].data)

                setReady(true)
            })
        } else {
            Promise.all([
                Controllers.profile.get_profile()
            ])
            .then(res => {
                setProfiles(res[0].data)
                setReady(true)
            })
        }
    }, [])

    const profileSelected = useCallback(() => {
        if (!row.p_id_profile) {
            return ''
        }

        let finded = profiles.find(j => parseInt(j.id_profile) === parseInt(row.p_id_profile))

        if (!finded) {
            return ''
        }

        return {
            label: (masterUser ? finded.name_customers + ' - ' : '') + finded.description_profile,
            value: finded.id_profile
        }
    }, [row.p_id_profile, profiles])

    return <ReactPlaceholder rows={15} showLoadingAnimation ready={ready}>
        <FormCustom dataSubmit={row} onSubmit={({ data }) => {
            if (parseInt(row.p_has_user) === 1 && !row.p_id_profile) {
                Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Perfil', toast })
            }

            onSubmit({ data })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            {masterUser ? (isNaN(parseInt(data.id_seller))? <Select
                options={customers.map(c => {
                    return {
                        label: c.name_customers,
                        value: c.id_customers
                    }
                })}
                text="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                onChange={e => setRow({ ...row, p_id_customers: e.value })}
            /> : <Input
                text="Cliente"
                placeholder="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                readOnly
                defaultValue={data.name_customers}
            />) : ''}
            <Input
                text="Nombres"
                placeholder="Nombres"
                classNameParent="col-12 mb-2"
                icon="far fa-user"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.name_seller}
                onChange={e => setRow({ ...row, p_name_seller: e.currentTarget.value })}
                maxLength={255}
            />
            <Input
                type="email"
                text="Email"
                placeholder="Email"
                classNameParent="col-12 mb-2"
                icon="far fa-envelope"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.email_seller}
                onChange={e => setRow({ ...row, p_email_seller: e.currentTarget.value })}
                maxLength={255}
            />
            <Input
                text="Telefono"
                placeholder="Telefono"
                classNameParent="col-12 mb-2"
                icon="fa fa-phone"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.phone_seller}
                onChange={e => setRow({ ...row, p_phone_seller: e.currentTarget.value })}
                maxLength={255}
            />
            <SwitchToggle
                id="enabled-seller"
                defaultChecked={parseInt(data.enabled_seller) === 1}
                onChange={e => setRow({ ...row, p_enabled_seller: e.currentTarget.checked ? 1 : 0 })}
            />
            <div className="col-12">
                <hr />
            </div>
            <CheckBox
                id="has-user"
                text="¿Tiene Usuario?"
                defaultChecked={parseInt(data.has_user) === 1}
                onChange={e => setRow({ ...row, p_has_user: e.currentTarget.checked ? 1 : 0 })}
                disabled={parseInt(data.has_user) === 1}
            />
            <Input
                text="Username"
                placeholder="Username"
                classNameParent="col-12 mb-2"
                icon="fa fa-user"
                required
                disabled
                invalid="El campo es obligatorio."
                value={row.p_email_seller}
                onChange={e => setRow({ ...row, p_email_seller: e.currentTarget.value })}
                maxLength={255}
            />
            <Input
                text="Password"
                type="password"
                placeholder="Password"
                classNameParent="col-12 mb-2"
                icon="fa fa-lock"
                required
                disabled={parseInt(row.p_has_user) !== 1}
                invalid="El campo es obligatorio."
                defaultValue={data.password}
                onChange={e => setRow({ ...row, p_password: e.currentTarget.value })}
                maxLength={30}
            />
            <Select
                text="Perfil"
                disabled={parseInt(row.p_has_user) !== 1}
                classNameParent="col-12 mb-2"
                value={profileSelected()}
                onChange={e => setRow({ ...row, p_id_profile: e.value })}
                options={profiles.map(j => {
                    return {
                        label: (masterUser ? j.name_customers + ' - ' : '') + j.description_profile,
                        value: j.id_profile
                    }
                })}
            />
        </FormCustom>
    </ReactPlaceholder>
}

SellerForm.defaultProps = {
    data: {
        id_seller: '',
        name_seller: '',
        email_seller: '',
        phone_seller: '',
        enabled_seller: '0',
        id_customers: '',
        name_customers: '',
        has_user: 0,
        id_customers_contact: '',
        password: '',
        id_profile: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default SellerForm;