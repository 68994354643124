/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
//
//
import FormCustom from "../../../Components/Form/FormCustom";
import Helpers from "../../../Components/Helpers/Helpers";
import Select from "../../../Components/Form/Select";
import Input from "../../../Components/Form/Input";
import { format } from "date-fns";

const URL = process?.env?.REACT_APP_API_URL || "";

const stateDefault = {
  typeEvent: "",
  chatId: "",
  name: "",
  date: "",
};

export default function EventEditAddForm({ chatId, onClose, open }) {
  const [messageWarning, setMessageWarning] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadInit, setIsLoadInit] = useState(false);
  const [form, setForm] = useState(stateDefault);
  const [types, setTypes] = useState([]);

  const handleOnChange = useCallback(
    (name, value) => setForm({ ...form, [name]: value }),
    [form]
  );

  const getTypesEvents = useCallback(async () => {
    const route = `/sperant/get/event-types?jwt=${Helpers.localStorage.getJWT()}`;
    setIsLoadInit(true);
    fetch(`${URL}${route}`)
      .then((response) => response.json())
      .then(({ data }) => {
        setTypes(
          data.map((item) => ({
            label: item.attributes.name,
            value: item.attributes.id,
          }))
        );
      })
      .catch(() => {
        Helpers.toast.construct({
          response: "warning",
          message: "No se ha podido cargar los tipo de evento",
          toast,
        });
      })
      .finally(() => setIsLoadInit(false));
  }, []);

  useEffect(() => {
    if (open && chatId) {
      getTypesEvents();
      handleOnChange("chatId", chatId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, getTypesEvents, open]);

  const handleClose = useCallback(() => {
    setForm(stateDefault);
    onClose();
  }, [onClose]);

  const validFields = useCallback(() => {
    if (!form.chatId || !form.name || !form.date || !form.typeEvent) {
      Helpers.toast.construct({
        response: "warning",
        message: "Campos requerido",
        toast,
      });
      setMessageWarning("Todos lo campos son obligatorio para crear evento");
      return;
    }
  }, [form.chatId, form.date, form.name, form.typeEvent]);

  const handleSubmit = useCallback(async () => {
    validFields();

    const formData = new FormData();

    formData.append("p_id_chat", form.chatId);
    formData.append("p_name", form.name);
    formData.append(
      "p_datetime_start",
      format(new Date(form.date), "yyyy-MM-dd hh:mm:ss")
    );
    formData.append("p_event_type_id", form.typeEvent);

    const route = `/sperant/create-event?jwt=${Helpers.localStorage.getJWT()}`;
    setIsLoading(true);

    fetch(`${URL}${route}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        Helpers.toast.construct({
          response: "success",
          message: "Evento creado",
          toast,
        });
        handleClose();
      })
      .catch((error) => {
        console.log(error);
        Helpers.toast.construct({
          response: "warning",
          message: "No se ha podido crear el evento",
          toast,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [form, handleClose, validFields]);

  return (
    <Modal
      size=""
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={open}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Crear evento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!messageWarning && (
          <div
            class="alert"
            style={{
              color: "#856404",
              backgroundColor: "#fff3cd",
              borderColor: "#ffeeba",
            }}
            role="alert"
          >
            {messageWarning}
          </div>
        )}
        <FormCustom
          loadSubmit={isLoading}
          onSubmit={handleSubmit}
          textButton="Crear evento"
        >
          <Select
            options={types}
            disabled={isLoading || isLoadInit}
            onChange={({ value }) => handleOnChange("typeEvent", value)}
            value={types.find((item) => item.value === form.typeEvent)}
            classNameParent="col-12 mb-2"
            invalid="El campo es obligatorio."
            text={isLoadInit ? "Cargando..." : "Tipo de evento"}
          />
          <Input
            classNameParent="col-12 mb-2"
            type="text"
            disabled={isLoading || isLoadInit}
            placeholder="Nombre del evento"
            text="Nombre del evento"
            value={form.name}
            invalid="El campo es obligatorio."
            onChange={(e) => handleOnChange("name", e.currentTarget.value)}
          />
          <Input
            type="datetime-local"
            icon="far fa-calendar"
            disabled={isLoading || isLoadInit}
            classNameParent="col-12 mb-2"
            text="Fecha"
            onChange={(e) => handleOnChange("date", e.currentTarget.value)}
            defaultValue={form.date}
          />
        </FormCustom>
      </Modal.Body>
    </Modal>
  );
}
