import React, {useState, useEffect} from 'react'

export default function SelectTime(props) {
  const [active, setActive] = useState(false)
  const [minutes, setMinutes] = useState('00');
  const [hours, setHours] = useState('00');
  const [selectHours, setSelectHours] = useState([])
  const [selectMinutes, setSelectMinutes] = useState([])
  
  const {onClickMinutes, onClickHours, value} = props
  
  useEffect(() => {
    let hours = [];
    for (let i = 0; i < 25; i++) {
      hours.push(i)
    }
    setSelectHours(hours)

    let minutes = [];
    for (let i = 0; i < 61; i++) {
      minutes.push(i)
    }
    setSelectMinutes(minutes)
  }, [])

  useEffect(() => {
    setMinutes(value?.minutes || '00')
    setHours(value?.hours || '00')
  }, [value])

  return (
    <div className="container-time col-12">
      <div className="time-text" onClick={() => setActive(true)}>
        <span>{`${hours}:${minutes}`}</span>
        <div className="d-flex">
          <span>|</span>
          <div className="time-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
          </div>
        </div>
      </div>
      {active && <div className="background-fixed" onClick={() => setActive(false)} />}
      {active && (
        <div className="modal-time">
          <div className="time-input scroll-effect">
            {selectHours.map(h => (
              <span
                key={h}
                onClick={() => {
                  onClickHours(`${h < 10 ? '0' : ''}${h}`)
                  setHours(`${h < 10 ? '0' : ''}${h}`)
                }}
                className='item-time'
              >
                {`${h < 10 ? '0' : ''}${h}`}
              </span>
            ))}
          </div>
          <div className="time-input scroll-effect">
            {selectMinutes.map(m =>
              <span
                key={m}
                onClick={() => {
                  onClickMinutes(`${m < 10 ? '0' : ''}${m}`)
                  setMinutes(`${m < 10 ? '0' : ''}${m}`)
                }}
                className='item-time'
              >
                {`${m < 10 ? '0' : ''}${m}`}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
