import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import { Input, Select } from '../../Components/Form/Form';
import { useSelector } from 'react-redux';
import CommentHistory from '../FeedHistory/CommentHistory';

const Feed = ({ feed, changeResponse, projects, groups, changeProject, changeFlow, isHistory, deleteGroup, changeDate, contacts, changeContact, deleteContact })  => {
    const isMasterUser = useSelector(store => parseInt(store.session.master_user))

    const projectSelected = useMemo(() => {
        if (!feed.id_project) {
            return ''
        }

        let finded = projects.find(p => parseInt(p.id_project) ===  parseInt(feed.id_project))

        if (!finded) {
            return ''
        }

        return {
            label: (isMasterUser ? finded.name_customers + ' - ' : '') + finded.name_project,
            value: finded.id_project
        }
    }, [feed.id_project])

    const [groupSelected, setGroupSelected] = useState('')
    const [contactSelected, setContactSelected] = useState('')

    const [commentsHistory, setCommentsHistory] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [modalTitle, setModalTitle] = useState('')

    const handleHideModalForm = useCallback(() => {
        setModalShow(false)
    })

    const isCorrectlyActived = useCallback(() => {
        if (parseInt(feed.response) === 1) {
            let message = ''
            let hasError = false

            if (!feed.date_start) {
                message = <>No se ha asignado la <strong>Fecha de Inicio</strong></>
                hasError = true
            } else if (!feed.time_start) {
                message = <>No se ha asignado la <strong>hora de Inicio</strong></>
                hasError = true
            } else if (!feed.date_end) {
                message = <>No se ha asignado la <strong>Fecha de Fin</strong></>
                hasError = true
            } else if (!feed.time_end) {
                message = <>No se ha asignado la <strong>Hora de Fin</strong></>
                hasError = true
            } else if (feed.groups.length === 0) {
                message = <>No se ha asignado el <strong>Grupo de Keyword</strong></>
                hasError = true
            } else if (feed.contacts.length === 0) {
                message = <>No se ha asignado <strong>CM</strong></>
                hasError = true
            }

            let date1 = new Date(feed.date_start);
            let date2 = new Date(feed.date_end);
            let diffTime = Math.abs(date2 - date1);
            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

            if (diffDays > 30) {
                message = <>El intervalo de tiempo máximo es de <strong>30 días</strong></>
                hasError = true
            }
            
            
            if (hasError) {
                return <div className="alert alert-warning">
                    {message}
                </div>
            }
        }
        
        return ''
    }, [feed])

    return <>
    <Card className="mb-4">
        <Card.Body className="d-flex" style={{
            height: 330
        }}>
            <div className="d-flex flex-column scroll-effect pr-3" style={{
                width: 250,
                height: 300,
                overflowY: 'auto'
            }}>
                <div className="d-flex justify-content-between mb-4">
                    <div>
                        <strong>POST ID: </strong>
                        <strong className="mr-2">{feed.id_feed}</strong>
                    </div>
                    <div>
                        <small className="text-muted mr-2">{feed.date_created} </small>
                        <a href={'https://www.facebook.com/' + feed.id} target="_blank">
                            <i className="fa fa-link"></i>
                        </a>
                    </div>
                </div>

                <span>{feed.message}</span>
                {feed.url_video ? <>
                    <iframe muted style={{
                        width: '100%'
                    }}
                    src={feed.url_video}>
                    </iframe>
                </> : (feed.full_picture ? <>
                    <img src={feed.full_picture} className="img-thumbnail mt-2" width="250" />
                </> : '')}
            </div>
            <div className="d-flex flex-column align-items-start pl-4 pr-4 scroll-effect" style={{
                width: 250,
                height: 300,
                overflowY: 'auto'
            }}>
                {isHistory ? <>
                    <div className="list-group-item p-2 rounded w-100">
                        Respuesta del ChatBot: <strong>{feed.qty_comments_bot}</strong>
                    </div>
                    <div className="mb-2"></div>
                    <div className="list-group-item p-2 rounded w-100">Derivados a CM: <strong>{feed.qty_comments_cm}</strong></div>

                    <Button className="d-flex justify-content-between mt-2" block size="sm" onClick={_ => {
                        setCommentsHistory(feed.comments)
                        setModalTitle('Comentarios del post: ' + feed.id_feed)
                        setModalShow(true)
                    }}>
                        <span>Ver Comentarios</span>
                        <i className="fa fa-eye mr-2 align-self-center"></i>
                    </Button>
                </> : <>
                    <div className="mb-2 d-flex flex-column w-100">
                        <Button block size="sm" className={'btn cur-pointer btn-' + (parseInt(feed.response) === 1 ? 'success' : 'secondary')} data-response={1} data-id={feed.id_feed} onClick={changeResponse}>
                            Chatbot Responde
                        </Button>
                        <Button block size="sm" className={'btn cur-pointer btn-' + (parseInt(feed.response) === 0 ? 'danger' : 'secondary')} data-response={0} data-id={feed.id_feed} onClick={changeResponse}>
                            Chatbot No Responde
                        </Button>
                    </div>
                    {isCorrectlyActived()}
                    <Input
                        type="date"
                        icon="far fa-calendar"
                        classNameParent="w-100 mb-2"
                        text="Fecha Inicio"
                        onChange={e => changeDate({ p_date_start: e.currentTarget.value, id: feed.id_feed, type: 'p_date_start' })}
                        defaultValue={feed.date_start}
                    />
                    <Input
                        type="time"
                        icon="far fa-clock"
                        classNameParent="w-100 mb-2"
                        text="Hora Inicio"
                        onChange={e => changeDate({ p_time_start: e.currentTarget.value, id: feed.id_feed, type: 'p_time_start' })}
                        defaultValue={feed.time_start}
                    />
                    <Input
                        type="date"
                        icon="far fa-calendar"
                        classNameParent="w-100 mb-2"
                        text="Fecha Fin"
                        onChange={e => changeDate({ p_date_end: e.currentTarget.value, id: feed.id_feed, type: 'p_date_end' })}
                        defaultValue={feed.date_end}
                    />
                    <Input
                        type="time"
                        icon="far fa-clock"
                        classNameParent="w-100 mb-3"
                        text="Hora Fin"
                        onChange={e => changeDate({ p_time_end: e.currentTarget.value, id: feed.id_feed, type: 'p_time_end' })}
                        defaultValue={feed.time_end}
                    />
                  
                    {/* <Select
                        classNameParent="w-100"
                        text="Proyecto"
                        options={projects.map(p => {
                            return {
                                label: (isMasterUser ? p.name_customers + ' - ' : '') + p.name_project,
                                value: p.id_project
                            }
                        })}
                        value={projectSelected}
                        onChange={e => changeProject({ e, id: feed.id_feed })}
                    />
                    <div className="w-100">
                        <hr />
                    </div> */}
                    <Select
                        classNameParent="w-100"
                        text="Grupo de Keywords"
                        options={groups.map(p => {
                            return {
                                label: (isMasterUser ? p.name_customers + ' - ' : '') + p.description_group,
                                value: p.id_group,
                                isDisabled: feed.groups.some(g => parseInt(g.id_group) === parseInt(p.id_group))
                            }
                        })}
                        value={groupSelected}
                        onChange={e => setGroupSelected(e)}
                        append={[
                            <Button onClick={e => {
                                if (!groupSelected) {
                                    Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Grupo', toast })
                                    return
                                }
                                if (feed.groups.some(g => parseInt(g.id_group) === parseInt(groupSelected?.value))) {
                                    Helpers.toast.construct({ response: 'warning', message: 'El grupo ya fue agregado', toast })
                                    return
                                }
                                changeFlow({ e, idGroup: groupSelected.value, id: feed.id_feed })
                                setGroupSelected('')
                            }}>
                                <i className="fa fa-plus"></i>
                            </Button>
                        ]}
                    />
                    <small className="text-muted mt-2 mb-1">Grupos Agregados</small>
                    {React.Children.toArray(feed.groups.map(g => <Input 
                        classNameParent="w-100 mb-1"
                        value={g.description_group}
                        disabled
                        append={[
                            <Button variant="secondary" size="xs" onClick={_ => {
                                deleteGroup({ idFeedGroup: g.id_feed_group, id: g.id_feed })
                            }}>
                                <i className="fa fa-times"></i>
                            </Button>
                        ]}
                    />))}
                    <Select
                        classNameParent="w-100 mt-3"
                        text="Communities"
                        options={contacts.map(p => {
                            return {
                                label: (isMasterUser ? p.name_customers + ' - ' : '') + p.name_contact,
                                value: p.id_customers_contact,
                                isDisabled: feed.contacts.some(g => parseInt(g.id_contact) === parseInt(p.id_customers_contact))
                            }
                        })}
                        value={contactSelected}
                        onChange={e => setContactSelected(e)}
                        append={[
                            <Button onClick={e => {
                                if (!contactSelected) {
                                    Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Contacto', toast })
                                    return
                                }
                                if (feed.contacts.some(g => parseInt(g.id_contact) === parseInt(contactSelected?.value))) {
                                    Helpers.toast.construct({ response: 'warning', message: 'El contacto ya fue agregado', toast })
                                    return
                                }
                                changeContact({ e, idContact: contactSelected.value, id: feed.id_feed })
                                setContactSelected('')
                            }}>
                                <i className="fa fa-plus"></i>
                            </Button>
                        ]}
                    />
                    <small className="text-muted mt-2 mb-1">Communities Agregados</small>
                    {React.Children.toArray(feed.contacts.map(g => <Input
                        classNameParent="w-100 mb-1"
                        value={g.name_contact}
                        append={[
                            <Button variant="secondary" size="xs" onClick={_ => {
                                deleteContact({ idFeedContact: g.id_feed_contact, id: g.id_feed })
                            }}>
                                <i className="fa fa-times"></i>
                            </Button>
                        ]}
                        disabled
                    />))}
                </>}
            </div>
        </Card.Body>
    </Card>

    <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title as="div">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CommentHistory comments={commentsHistory} />
        </Modal.Body>
    </Modal>
    </>
}

Feed.defaultProps = {
    isHistory: false
}

export default Feed