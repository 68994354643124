import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import {
  RectShape,
  TextBlock,
  TextRow,
} from "react-placeholder/lib/placeholders";
import Controllers from "../../Api/Controllers";
import ChatBotFlow from "../../Components/common/ChatBotFlow";

const FlowChat = (_) => {
  const [rows, setRows] = useState([]);
  const [rowsWelcome, setRowsWelcome] = useState([]);
  const [load, setLoad] = useState(true);
  const [listAgentes, setListAgentes] = useState([]);

  useEffect((_) => {
    setLoad(true);
    Promise.all([
      // Controllers.flow_chat.get_flow_chat(),
      Controllers.flow_chat.get_flow_chat2(),
      Controllers.customers_contact.get_customers_contact(),
      Controllers.answer.get_answer(),
    ])
      .then((res) => {
        const rowsNew = [];
        const data = res[0].data;
        data.forEach((item) => {
          if (item?.WEB) {
            rowsNew.push({ ...item.WEB, type: "Web" });
          } else if (item?.WHATSAPP) {
            rowsNew.push({ ...item.WHATSAPP, type: "Whatsapp" });
          } else if (item?.FACEBOOK) {
            rowsNew.push({ ...item.FACEBOOK, type: "Facebook" });
          } else if (item?.INSTAGRAM) {
            rowsNew.push({ ...item.INSTAGRAM, type: "Instagram" });
          }
        });
        setRows(rowsNew);
        setListAgentes(res[1].data);
        setRowsWelcome(res[2].data);
      })
      .finally(() => setLoad(false));
  }, []);

  return (
    <div className="form-row">
      {load ? (
        <>
          <div className="col-md-6 mb-2">
            <Card style={{ padding: "10px 20px" }}>
              <TextRow
                color="#E0E0E0"
                style={{ width: 150, height: 15, borderRadius: 10 }}
              />
              <RectShape
                color="#E0E0E0"
                style={{
                  width: "100%",
                  margin: "40px 0 20px",
                  height: 200,
                  borderRadius: 10,
                }}
              />
              <TextBlock color="#E0E0E0" rows={4} />
            </Card>
          </div>
          <div className="col-md-6 mb-2">
            <Card style={{ padding: "10px 20px" }}>
              <TextRow
                color="#E0E0E0"
                style={{ width: 150, height: 15, borderRadius: 10 }}
              />
              <RectShape
                color="#E0E0E0"
                style={{
                  width: "100%",
                  margin: "40px 0 20px",
                  height: 200,
                  borderRadius: 10,
                }}
              />
              <TextBlock color="#E0E0E0" rows={4} />
            </Card>
          </div>
        </>
      ) : (
        rows.map((item) => (
          <div className="col-md-6 mb-2">
            <ChatBotFlow
              item={item}
              key={item.type}
              rowWelcome={rowsWelcome.find(
                (it) => it.type.toLowerCase() === item.type.toLowerCase()
              )}
              listAgentes={listAgentes}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default FlowChat;
