import React from "react";

import Preloading from "../../Resources/preloading.svg";
import PreloadingTelcotec from "../../Resources/preloading-telcotec.svg";

export default function Loading() {
  const source =
    window?.location.pathname.includes("login2") ||
    window?.location.hostname.includes("telcotech")
      ? PreloadingTelcotec
      : Preloading;

  return (
    <div style={styleDiv}>
      <img src={source} alt="Preloading" />
      <span style={styleSpan}>Por favor espere...</span>
    </div>
  );
}

const styleDiv = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "fixed",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10000,
};

const styleSpan = {
  fontSize: 15,
  paddingTop: 10,
  fontWeight: "500",
};
