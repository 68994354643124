import React, { forwardRef } from "react";
import { Layout, Header } from "../../Components/Layout/Layout";
/* import permissions from '../../Components/Layout/Resources/Example.json'
import permissionsCustomer from '../../Components/Layout/Resources/ExampleCustomer.json' */
import Helpers from "../../Components/Helpers/Helpers";
import Dropdown from "react-bootstrap/Dropdown";
import UserPhoto from "../../Resources/user.png";
import Logo from "../../Resources/logo.png";
import { useSelector } from "react-redux";

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <Header.Right.Icon
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <Header.Right.Image src={UserPhoto} />
    {children}
  </Header.Right.Icon>
));

const AppLayout = ({ title, children, page, showBread, stylesProps }) => {
  const permissions = useSelector((store) => store.session.permissions);
  // const masterUser = useSelector((store) => store.session.master_user);

  const handleLogout = () => {
    // let theme = parseInt(localStorage?.theme);
    localStorage.clear();
    window.location.reload();
    // window.location.href =
    //   theme === 2 && !window.location.hostname.includes("telcotech")
    //     ? Helpers.config.convertUrl({ url: "/login2" })
    //     : "/login";
  };

  return (
    <Layout
      stylesProps={stylesProps}
      resizeNav
      imageLogo={
        parseInt(localStorage?.theme) === 2
          ? "/assets/customers/telcotec/logo.png"
          : Logo
      }
      permissions={permissions}
      title={title}
      page={page}
      companyName={Helpers.config.company.name}
      folderPath={Helpers.config.folder_path}
      iconsHeader={[
        <Dropdown>
          <Dropdown.Toggle variant="success" as={CustomToggle} />
          <Dropdown.Menu>
            <Dropdown.Item className="d-flex" onClick={handleLogout}>
              <i className="fa fa-arrow-left mr-2 align-self-center"></i>
              <span>Salir</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>,
      ]}
      showBread={showBread}
    >
      {children}
    </Layout>
  );
};

AppLayout.defaultProps = {
  title: "",
  page: "",
};

export default AppLayout;
