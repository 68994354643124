import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { TextRow } from 'react-placeholder/lib/placeholders'
import Controllers from '../../Api/Controllers';
//import Controllers from '../../Api/Controllers';
import {Select} from '../Form/Form'

export default function EmailSelection(props) {
  const [load, setLoad] = useState(false);
  const [value, setValues] = useState('')
  
  const [rows, setRows] = useState([]);
  
  const {
    emails,
    onClickAdd,
    deleteEmail,
  } = props;
  
  
  useEffect(() => {
    setLoad(true)
    Controllers.emails.get_emails()
      .then(res => setRows(res.data))
      .finally(() => setLoad(false))
  }, [])


  return (
    <div className="w-100">
      { load ? (
        <TextRow style={ { height: 30, borderRadius: 5 } } color="#E0E0E0" />
      ) : rows.length > 0 && <Select
        options={rows.map((agt) => {
          return {
            label: agt.email,
            value: agt.id,
            isDisabled: emails.some((a) => {
              return (
                parseInt(a.id) ===
                parseInt(agt.id)
              );
            }),
          };
        })}
        onChange={(e) => setValues({
          email: e.label,
          id: e.value,
        })}
        defaultValue={value}
        classNameParent="w-100 mb-2"
        text="Seleccionar correos"
        append={[
          <Button
            variant="primary"
            onClick={ () => {
              if (value) {
                onClickAdd(value)
                setValues('')
              }
            } }
          >
            <i className="fa fa-plus"></i>
          </Button>,
        ] }
      /> }
      
      <strong className="text-primary">
        Correos seleccionados
      </strong>
      {React.Children.toArray(
        emails.map((it, j) => {
          return (
            <div className="flex-row d-flex justify-content-between p-2 align-items-center">
              <strong>
                #{j + 1}. {it.email}
              </strong>
              <Button
                size="sm"
                variant="danger"
                onClick={(_) => deleteEmail(it.id)}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </div>
          );
        })
      )}
    </div>
  )
}
