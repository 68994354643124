import React from "react";

function TableServices({children}) {
  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead className="thead-dark">
            <tr>
              <th>Servicio</th>
              <th>Keywords</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {children}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TableServices;
