import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import FileForm from './FileForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { useSelector } from 'react-redux';

const File = ({ nameSingle, namePlural, separator }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})

    const masterUser = useSelector(store => store.session.master_user)

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.file.get_file().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.file.file_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
            setReload(true)
        })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idFile) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let { idFile } = $this.dataset

        Controllers.file.get_file_id({ p_id_file: idFile }).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idFile}`)
            setDataUpdated({ data: res.data })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
        .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.file.file_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idFile, descriptionFile } = e.currentTarget.dataset
        setDataDeleted({
            p_id_file: idFile,
            p_name_file: descriptionFile
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const dtRows = useMemo(() => {
        let headTable = [
            /* { name: 'id_file', text: 'ID', align: 'center' }, */
            { name: 'description_file', text: 'Descripción', align: 'center' },
            { name: 'path_file', text: 'Archivo', align: 'center', render: r => r.path_file ? <a target="_blank" href={Helpers.config.resourcesUrl({ url: r.path_file })}><Button size="sm">Ver Archivo</Button></a> : '' },
            { name: 'text_file', text: 'Mensaje', align: 'center' },
            { name: 'enabled_file', text: 'Estado', align: 'center', render: r => <div className={'badge badge-' + (parseInt(r.enabled_file) === 1 ? 'success' : 'danger')}>
                    {parseInt(r.enabled_file) === 1 ? 'Activo' : 'Inactivo'}
                </div> },
            { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                return <div>
                    <Button variant="info" className="mr-1" size="xs" data-id-file={u.id_file} onClick={handleGet}>
                        <i className="fa fa-edit"></i> Editar
                    </Button>
                    <Button variant="danger" size="xs" data-id-file={u.id_file} data-description-file={u.description_file} onClick={handleShowModalDelete}>
                        <i className="fa fa-trash"></i> Eliminar
                    </Button>
                </div>
            } },
        ]

        if (masterUser) {
            headTable.unshift({ name: 'name_customers', text: 'Cliente', align: 'center' })
        }

        return <ReactDataTableBootstrap
            head={[
                headTable,
            ]}
            rows={rows}
            isProcessing={isProcessing}
        />
    }, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Listado De {namePlural}</span>
                <Button size="sm" onClick={handleGet}>
                    <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                </Button>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <FileForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} />
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_file} - {dataDeleted.p_name_file}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default File