import React from 'react'
//
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const SweetAlert = ({ show, onHide, title, subtitle, icon, confirmButtonText, cancelButtonText, showCancelButton, onConfirm, onCancel, loadSubmit }) => {
    return <Modal show={show} onHide={onHide} backdrop="static" centered>
        <Modal.Body className="text-center">
            <h2><strong>{title}</strong></h2>
            <p>{subtitle}</p>
            <div>
                <Button variant={`danger ${showCancelButton ? ' mr-2' : ''}`} onClick={onConfirm}>
                    {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : confirmButtonText}
                </Button>
                {showCancelButton ? <Button variant="dark" onClick={onCancel}>{cancelButtonText}</Button> : ''}
            </div>
        </Modal.Body>
    </Modal>
}

SweetAlert.defaultProps = {
    title: '',
    subtitle: '',
    icon: '',
    show: false,
    onHide: () => {},
    onConfirm: () => {},
    confirmButtonText: '',
    onCancel: () => {},
    cancelButtonText: '',
    showCancelButton: true,
    loadSubmit: false
}

export default SweetAlert;