import React from 'react'
import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders';

export default function PContacts() {
  return (
     <>
      <div style={{display: 'flex', padding: '15px', alignItems: 'center'}}>
        <RectShape color="#CDCDCD"  style={{width: 50, maxWidth: 50, minWidth: 50, borderRadius: '100%', height: 50, maxHeight: 50, minHeight: 50}}/>
        <TextBlock rows={3} color="#CDCDCD" />
      </div>
      <hr className="p-0 m-0" />
      <div style={{display: 'flex', padding: '15px', alignItems: 'center'}}>
        <RectShape color="#CDCDCD"  style={{width: 50, maxWidth: 50, minWidth: 50, borderRadius: '100%', height: 50, maxHeight: 50, minHeight: 50}}/>
        <TextBlock rows={3} color="#CDCDCD" />
      </div>
      <hr className="p-0 m-0" />
      <div style={{display: 'flex', padding: '15px', alignItems: 'center'}}>
        <RectShape color="#CDCDCD"  style={{width: 50, maxWidth: 50, minWidth: 50, borderRadius: '100%', height: 50, maxHeight: 50, minHeight: 50}}/>
        <TextBlock rows={3} color="#CDCDCD" />
      </div>
      <hr className="p-0 m-0" />
      <div style={{display: 'flex', padding: '15px', alignItems: 'center'}}>
        <RectShape color="#CDCDCD"  style={{width: 50, maxWidth: 50, minWidth: 50, borderRadius: '100%', height: 50, maxHeight: 50, minHeight: 50}}/>
        <TextBlock rows={3} color="#CDCDCD" />
      </div>
    </>
  )
}
