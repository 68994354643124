import React, { useEffect, useRef } from "react";

function generateProductKey() {
  const tokens = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const chars = 5;
  const segments = 4;
  let keyString = "";

  for (let i = 0; i < segments; i++) {
    var segment = "";
    for (let j = 0; j < chars; j++) {
      const k = Math.floor(Math.random() * (0 - 35 + 1)) + 35;
      segment += tokens[k];
    }
    keyString += segment;
  }

  return keyString;
}

const EditableElement = (props) => {
  const { onChange, onChangeImage, onChangeImageSend } = props;
  const element = useRef();

  let elements = React.Children.toArray(props.children);
  if (elements.length > 1) {
    throw Error("Can't have more than one child");
  }

  let contentTarget = document.getElementById("imageUpload");
  if (contentTarget) {
    contentTarget.onpaste = (e) => {
      var pasteText = e.clipboardData.getData("text/plain");
      if (pasteText) {
        onChange(pasteText);
      } else {
        let cbPayload = [
          ...(e.clipboardData || e.originalEvent.clipboardData).items,
        ];
        cbPayload = cbPayload.filter((i) => /image/.test(i.type));
        if (!cbPayload.length || cbPayload.length === 0) return false;
        const file = cbPayload[0].getAsFile();
        if (file.type.includes("png")) {
          const hash = generateProductKey();
          const blob = file.slice(0, file.size, "image/png");
          const newFile = new File([blob], `${hash}.png`, { type: file.type });
          onChangeImageSend({ p_image: newFile });
        } else {
          onChangeImageSend({ p_image: file });
        }
        let reader = new FileReader();
        reader.onload = (e) => {
          onChangeImage({
            uri: e.target.result,
          });
        };
        reader.readAsDataURL(cbPayload[0].getAsFile());
      }
    };
  }
  const onMouseUp = () => {
    const value = element.current?.value || element.current?.innerText;
    onChange(value);
  };

  useEffect(() => {
    const value = element.current?.value || element.current?.innerText;
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  elements = React.cloneElement(elements[0], {
    contentEditable: true,
    suppressContentEditableWarning: true,
    ref: element,
    onKeyUp: onMouseUp,
  });

  return elements;
};

export default EditableElement;
