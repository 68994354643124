import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
//
import Helpers from "../../../Components/Helpers/Helpers";

const URL = process?.env?.REACT_APP_API_URL || "";

export default function useGetTemplates(filters) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getTemplates = useCallback(async () => {
    const route = `/newtemplates/get?jwt=${Helpers.localStorage.getJWT()}`;
    setIsLoading(true);
    fetch(`${URL}${route}`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((response) => setData(response || []))
      .catch(() => {
        Helpers.toast.construct({
          response: "warning",
          message: "No se ha podido cargar las plantillas",
          toast,
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  const postTemplate = useCallback(
    (formData, cb, edit) => {
      const route = `/newtemplates/insert/update?jwt=${Helpers.localStorage.getJWT()}`;
      setIsLoading(true);
      fetch(`${URL}${route}`, {
        method: "POST",
        body: formData,
        header: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => response.json())
        .then(() => {
          Helpers.toast.construct({
            response: "success",
            message: edit ? "Plantilla actualizada" : "Plantilla registrada",
            toast,
          });
          getTemplates();
        })
        .catch(() => {
          Helpers.toast.construct({
            response: "warning",
            message: "No se ha podido crear la plantillas",
            toast,
          });
        })
        .finally(() => {
          cb();
          setIsLoading(false);
        });
    },
    [getTemplates]
  );

  const deleteTemplate = useCallback(
    (templateId) => {
      const route = `/newtemplates/delete/${templateId}jwt=${Helpers.localStorage.getJWT()}`;
      setIsLoading(true);
      fetch(`${URL}${route}`, {
        method: "POST",
      })
        .then((response) => response.json())
        .then(() => {
          Helpers.toast.construct({
            response: "success",
            message: "Plantilla eliminada",
            toast,
          });
          getTemplates();
        })
        .catch(() => {
          Helpers.toast.construct({
            response: "warning",
            message: "No se ha podido eliminar las plantillas",
            toast,
          });
        })
        .finally(() => setIsLoading(false));
    },
    [getTemplates]
  );

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  const templates = useCallback(() => {
    let currentData = data;
    if (filters?.templateType) {
      switch (filters?.templateType) {
        case "rich_text_params":
          currentData = data.filter((item) => item.type === "rich_text_params");
          break;

        case "rich_text_image":
          currentData = data.filter((item) => item.type === "rich_text_params");
          break;

        case "rich_text_pdf":
          currentData = data.filter((item) => item.type === "rich_text_params");
          break;

        default:
          currentData = data.filter((item) => item.type !== "rich_text_params");
          break;
      }
    }
    if (filters?.arrayMap) {
      currentData = currentData.map((item) => ({
        label: item?.name || "",
        imagen: item?.image || false,
        files: item?.pdf || false,
        value: item?.id_template || "",
      }));
    }
    return currentData;
  }, [data, filters]);

  const updateTemplates = useCallback(() => {
    getTemplates();
  }, [getTemplates]);

  return {
    data: templates(),
    isLoading,
    postTemplate,
    deleteTemplate,
    updateTemplates,
  };
}
