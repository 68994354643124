import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import Controllers from '../../Api/Controllers'
import { Input, SwitchToggle, FormCustom, Select, TextArea } from '../../Components/Form/Form'
import ReactPlaceholder from 'react-placeholder'
import { useSelector } from 'react-redux'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify'

const GroupForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_group: data.id_group,
        p_description_group: data.description_group,
        p_keywords_group: data.keywords_group,
        p_enabled_group: data.enabled_group,
        p_id_customers: data.id_customers,
        p_responses_group: data.responses_group,
        p_name_customers: data.name_customers
    })

    useEffect(() => {
        setRow({
            p_id_group: data.id_group,
            p_description_group: data.description_group,
            p_keywords_group: data.keywords_group,
            p_enabled_group: data.enabled_group,
            p_id_customers: data.id_customers,
            p_responses_group: data.responses_group,
            p_name_customers: data.name_customers
        })
    }, [data])

    const masterUser = useSelector(store => store.session.master_user)
    const [ready, setReady] = useState(false)
    const [customers, setCustomers] = useState([])

    useEffect(() => {
        if (masterUser && isNaN(parseInt(data.id_group))) {
            Controllers.customers.get_customers_public()
            .then(res => {
                setReady(true)
                setCustomers(res.data)
            })
        } else {
            setReady(true)
        }
    }, [data])

    return <ReactPlaceholder rows={15} showLoadingAnimation ready={ready}>
        <FormCustom dataSubmit={row} beforeSubmit={_ => {
            let idxResponse = -1
            row.p_responses_group.forEach((r, i) => {
                if (idxResponse === -1 && !r.description) {
                    idxResponse = i
                }
            })
            if (idxResponse >= 0) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, completa la Respuesta #' + (idxResponse + 1), toast })
            }
        }} onSubmit={({ data }) => {
            /* if (!row.p_id_customers) {
                Helpers.toast.construct({ response: 'warning', message: 'Seleccionar cliente', toast })
                return
            } */
            onSubmit({ data })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            {masterUser ? (isNaN(parseInt(data.id_group))? <Select
                options={customers.map(c => {
                    return {
                        label: c.name_customers,
                        value: c.id_customers
                    }
                })}
                text="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                onChange={e => setRow({ ...row, p_id_customers: e.value })}
            /> : <Input
                text="Cliente"
                placeholder="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                readOnly
                value={row.p_name_customers}
            />) : ''}
            <Input
                text="Descripción"
                placeholder="Descripción"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                value={row.p_description_group}
                onChange={e => setRow({ ...row, p_description_group: e.currentTarget.value })}
                maxLength={255}
            />
            {/* <SwitchToggle
                id="enabled-group"
                defaultChecked={parseInt(data.enabled_group) === 1}
                onChange={e => setRow({ ...row, p_enabled_group: e.currentTarget.checked ? 1 : 0 })}
            /> */}
            <Input
                text="Keywords"
                placeholder="Keywords"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                value={row.p_keywords_group}
                onChange={e => setRow({ ...row, p_keywords_group: e.currentTarget.value })}
                maxLength={255}
            />
            <div className="col-12 mt-0 mb-0">
                <small className="text-muted">Keywords: Son las palabras claves que el BOT buscará en los comentarios de los clientes. Separar por comas: Ej: info, información </small>
            </div>
            
            <div className="col-12 mb-2">
                <hr/>
                <div className="d-flex justify-content-between">
                    <small className="text-primary mr-3 align-self-center">Respuesta Aleatorias</small>
                    <Button size="sm" onClick={_ => {
                        let lastId = row.p_responses_group[row.p_responses_group.length - 1].id + 1

                        setRow({ ...row, p_responses_group: [
                            ...row.p_responses_group,
                            { id: lastId, description: '', photo: '' }
                        ] })
                    }}>
                        <i className="fa fa-plus"></i>
                    </Button>
                </div>
            </div>
            <div className="col-12 mb-2">
                <Accordion>
                    {React.Children.toArray(row.p_responses_group.map((r, i) => {
                        return <Card style={{
                            border: '0 !important',
                            borderRadius: '0.25rem'
                        }}>
                            <Card.Header className="d-flex justify-content-between p-0">
                                <Accordion.Toggle as={Button} style={{
                                    padding: 0
                                }} variant="link" eventKey={r.id}>
                                    Respuesta #{i + 1}
                                </Accordion.Toggle>
                                {i > 3 ? <Button variant="danger" size="xs" onClick={_ => {
                                    setRow({ ...row, p_responses_group: row.p_responses_group.filter(rr => parseInt(rr.id) != parseInt(r.id)) })
                                }}>
                                    <i className="fa fa-trash"></i>
                                </Button> : ''}
                            </Card.Header>
                            <Accordion.Collapse eventKey={r.id}>
                                <Card.Body className="p-1">
                                    <div className="form-row">
                                        <TextArea
                                            text="Respuesta"
                                            placeholder="Respuesta"
                                            classNameParent="col-12 mb-2"
                                            icon="far fa-comment-alt"
                                            required
                                            invalid="El campo es obligatorio."
                                            value={r.description}
                                            onChange={e => setRow({ ...row, p_responses_group: row.p_responses_group.map(rr => {
                                                if (parseInt(rr.id) === parseInt(r.id)) {
                                                    rr.description = e.currentTarget.value
                                                }

                                                return rr
                                            })})}
                                            rows={5}
                                        />
                                        <Input
                                            text="Imagen"
                                            placeholder="Imagen"
                                            classNameParent="col-12 mb-2"
                                            icon="far fa-images"
                                            invalid="El campo es obligatorio."
                                            value={r.photo ? r.photo : ''}
                                            onChange={e => setRow({ ...row, p_responses_group: row.p_responses_group.map(rr => {
                                                if (parseInt(rr.id) === parseInt(r.id)) {
                                                    rr.photo = e.currentTarget.value
                                                }

                                                return rr
                                            })})}
                                            maxLength={255}
                                        />
                                        {r.photo ? <div className="col-12">
                                            <img src={r.photo} className="img-thumbnail" style={{
                                                maxWidth: 200
                                            }} />
                                        </div> : ''}
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    }))}
                </Accordion>
            </div>
            
            
        </FormCustom>
    </ReactPlaceholder>
}

GroupForm.defaultProps = {
    data: {
        id_group: '',
        description_group: '',
        keywords_group: '',
        enabled_group: '0',
        id_customers: '',
        name_customers: '',
        responses_group: [
            { id: 1, description: '', photo: '' },
            { id: 2, description: '', photo: '' },
            { id: 3, description: '', photo: '' },
            { id: 4, description: '', photo: '' }
        ]
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default GroupForm;