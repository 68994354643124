import React, { useState } from 'react'
import { Input, FormCustom, SwitchToggle } from '../../Components/Form/Form'

const KeywordForm = ({ data, onSubmit, disablebSubmit, loadSubmit, idCustomers, idProject }) => {
    const [row, setRow] = useState({
        p_id_keyword: data.id_keyword,
        p_description_keyword: data.description_keyword,
        p_enabled_keyword: data.enabled_keyword,
        p_id_project: idProject,
        p_id_customers: idCustomers
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.description_keyword}
            onChange={e => setRow({ ...row, p_description_keyword: e.currentTarget.value })}
            maxLength={255}
        />
        <SwitchToggle
            id="enabled-keyword"
            defaultChecked={parseInt(data.enabled_keyword) === 1}
            onChange={e => setRow({ ...row, p_enabled_keyword: e.currentTarget.checked ? 1 : 0 })}
        />
    </FormCustom>
}

KeywordForm.defaultProps = {
    data: {
        id_keyword: '',
        description_keyword: '',
        enabled_keyword: '0',
        id_project: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default KeywordForm;