import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
// import "react-tooltip/dist/react-tooltip.css";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import jwtDecode from "jwt-decode";
import axios from "axios";

// import AppState from "./AppState";
import Routes from "./Routes";
import Store from "./Redux/Store";
import { setSession } from "./Redux/Actions";
import Loading from "./Views/Loading/Loading";
import reportWebVitals from "./reportWebVitals";
import Helpers from "./Components/Helpers/Helpers";
import { StateProvider } from "./context/StateContext";

const App = () => {
  const [content, setContent] = useState(<Loading />);

  useEffect(() => {
    if (
      window?.location.pathname.includes("login2") ||
      localStorage.getItem("theme") === "2" ||
      window.location.hostname.includes("telcotech")
    ) {
      import("./Styles/Telcotech.scss");
    } else {
      if (parseInt(localStorage?.theme) === 2) {
        import("./Styles/Telcotech.scss");
      } else {
        import("./Styles/Styles.scss");
      }
    }
  }, []);

  useEffect(() => {
    const url = new URL(window?.location.href);
    const jwt = url.searchParams.get("jwt");
    if (jwt) {
      Helpers.localStorage.setSession({ jwt });
      window.location.href = `"http://${window.location.host}`;
    } else {
      axios({
        url: Helpers.config.api.url + "/users/session",
        method: "POST",
        data: {
          jwt: Helpers.localStorage.getJWT(),
        },
      })
        .then((res) => res.data)
        .then((res) => {
          const token = Helpers?.localStorage?.getJWT();
          const user = jwtDecode(token);
          localStorage.setItem("id_customer", user.id_user);
          if (res.message === "error_session_api") {
            localStorage.clear();
            setContent(
              <Provider store={Store}>
                <Routes type="auth" />
                <ToastContainer />
              </Provider>
            );
          } else {
            Store.dispatch(setSession(res.data));
            Helpers.localStorage.setSession({
              jwt: res.data.jwt,
            });
            setContent(
              <Provider store={Store}>
                <StateProvider>
                  <Routes idUser={user.id_user} />
                  <ToastContainer />
                </StateProvider>
              </Provider>
            );
          }
        })
        .catch((req) => {
          localStorage.clear();
          setContent(
            <Provider store={Store}>
              <Routes type="auth" />
              <ToastContainer />
            </Provider>
          );
        });
    }
  }, []);

  return content;
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
