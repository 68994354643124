import React, { createContext, useState } from "react";

const STATE_INITIAL = {
  update: false,
  data: {},
  type: "",
};

const StateContext = createContext(STATE_INITIAL);

const StateProvider = (props) => {
  const [state, setState] = useState(STATE_INITIAL);
  const [soundActive, setSoundActive] = useState(true);

  const { children } = props;

  const update = (update) => setState((state) => ({ ...state, update }));
  const dataChat = (data, type) =>
    setState((state) => ({ ...state, data, type }));

  return (
    <StateContext.Provider
      value={{
        state,
        soundActive,
        func: {
          update,
          dataChat,
          setSoundActive,
        },
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export { StateProvider, StateContext };
