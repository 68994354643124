import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import GroupForm from './GroupForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { useSelector } from 'react-redux';

const Group = ({ nameSingle, namePlural, separator }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})

    const masterUser = useSelector(store => store.session.master_user)

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.group.get_group().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.group.group_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
            setReload(true)
        })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idGroup) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let { idGroup } = $this.dataset

        Controllers.group.get_group_id({ p_id_group: idGroup }).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idGroup}`)
            setDataUpdated({ data: res.data })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
        .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.group.group_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idGroup, nameGroup } = e.currentTarget.dataset
        setDataDeleted({
            p_id_group: idGroup,
            p_description_group: nameGroup
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const dtRows = useMemo(() => {
        let headTable = [
            /* { name: 'id_group', text: 'ID', align: 'center' }, */
            { name: 'description_group', text: 'Descripción', align: 'center' },
            /* { name: 'enabled_group', text: 'Estado', align: 'center', render: r => <div className={'badge badge-' + (parseInt(r.enabled_group) === 1 ? 'success' : 'danger')}>
                {parseInt(r.enabled_group) === 1 ? 'Activo' : 'Inactivo'}
            </div> }, */
            { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                return <div>
                    <Button variant="info" className="mr-1" size="xs" data-id-group={u.id_group} onClick={handleGet}>
                        <i className="fa fa-edit"></i> Editar
                    </Button>
                    <Button variant="danger" size="xs" data-id-group={u.id_group} data-name-group={u.description_group} onClick={handleShowModalDelete}>
                        <i className="fa fa-trash"></i> Eliminar
                    </Button>
                </div>
            } },
        ]

        if (masterUser) {
            headTable.unshift({ name: 'name_customers', text: 'Cliente', align: 'center' })
        }

        return <table>
            <tbody>
                {React.Children.toArray(rows.map(r => {
                    return <tr>
                        <td className="p-1">{r.description_group}</td>
                        <td align="right">
                            <i className="fa fa-edit mr-2 cur-pointer" data-id-group={r.id_group} onClick={handleGet}></i>
                            <i className="fa fa-trash mr-2 cur-pointer" data-id-group={r.id_group} data-name-group={r.description_group} onClick={handleShowModalDelete}></i>
                        </td>
                    </tr>
                }))}
            </tbody>
        </table>

        return <ReactDataTableBootstrap
            head={[
                headTable,
            ]}
            rows={rows}
            isProcessing={isProcessing}
        />
    }, [rows, isProcessing])

    return <>
        <div className="form-row">
            <div className="col-12 col-md-6">
                <Card>
                    <Card.Header className="justify-content-between">
                        <span className="text-primary">Listado De {namePlural}</span>
                        <Button size="sm" variant="outline-primary" onClick={handleGet}>
                            <i className="fa fa-plus mr-1"></i> Registrar
                        </Button>
                    </Card.Header>
                    <Card.Body style={{
                        height: 400,
                        overflowY: 'auto'
                    }}>
                        { dtRows }
                    </Card.Body>
                </Card>
            </div>
            {modalShow ? <div className="col-12 col-md-6">
                <Card>
                    <Card.Header className="text-primary">
                        {dataUpdated?.data ? 'Modificando Grupo de kewyords' : 'Registrar Grupo de kewyords'}
                    </Card.Header>
                    <Card.Body  style={{
                        height: 400,
                        overflowY: 'auto'
                    }} className="scroll-effect">
                        <GroupForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} />
                    </Card.Body>
                </Card>
            </div> : ''}
        </div>
        {/* <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
            </Modal.Body>
        </Modal> */}
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_group} - {dataDeleted.p_description_group}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default Group