import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import { FormCustom, TextArea, CheckBox, Input } from '../../Components/Form/Form'
import Accordion from 'react-bootstrap/esm/Accordion';
import ReactPlaceholder from 'react-placeholder'

const BotHour = _ => {
    const [dataHourWsp, setDataHourWsp] = useState([])
    const [dataHourFb, setDataHourFb] = useState([])
    const [dataHourWeb, setDataHourWeb] = useState([])
    const [loadSubmitWsp, setLoadSubmitWsp] = useState(false)
    const [loadSubmitFb, setLoadSubmitFb] = useState(false)
    const [loadSubmitWeb, setLoadSubmitWeb] = useState(false)
    const [ready, setReady] = useState(false)
    useEffect(_ => {
        Controllers.bot_date.get_bot_date().then(res => {
            setDataHourWsp(res.data.wsp)
            setDataHourFb(res.data.fb)
            setDataHourWeb(res.data.web)
            setReady(true)
        })
    }, [])

    
    const onSubmitWsp = ({ data }) => {
        let existsError = false

        data.forEach(h => {
            h.details.forEach(d => {
                if (d.hour_start === '' || d.hour_end === '') {
                    existsError = true;
                }
            })
        })

        if (existsError) {
            Helpers.toast.construct({ response: 'warning', message: 'Completar los campos', toast })
            return;
        }

        setLoadSubmitWsp(true)
        Controllers.bot_date.bot_date_insert_update({
            p_days: data
        }).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setLoadSubmitWsp(false)
            })
    }

    const onSubmitFb = ({ data }) => {
        let existsError = false

        data.forEach(h => {
            h.details.forEach(d => {
                if (d.hour_start === '' || d.hour_end === '') {
                    existsError = true;
                }
            })
        })

        if (existsError) {
            Helpers.toast.construct({ response: 'warning', message: 'Completar los campos', toast })
            return;
        }

        setLoadSubmitFb(true)
        Controllers.bot_date.bot_date_insert_update({
            p_days: data
        }).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setLoadSubmitFb(false)
            })
    }

    const onSubmitWeb = ({ data }) => {
        let existsError = false

        data.forEach(h => {
            h.details.forEach(d => {
                if (d.hour_start === '' || d.hour_end === '') {
                    existsError = true;
                }
            })
        })

        if (existsError) {
            Helpers.toast.construct({ response: 'warning', message: 'Completar los campos', toast })
            return;
        }

        setLoadSubmitWeb(true)
        Controllers.bot_date.bot_date_insert_update({
            p_days: data
        }).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setLoadSubmitWeb(false)
            })
    }

    return <div className="form-row">
        <div className="col-md-6 mb-2">
            <Card>
                <Card.Header className="justify-content-between">
                    <span>Horario de ChatBot Whatsapp</span>
                </Card.Header>
                <Card.Body>
                    <ReactPlaceholder ready={ready} rows={10} showLoadingAnimation>
                        <FormCustom dataSubmit={dataHourWsp} onSubmit={onSubmitWsp} loadSubmit={loadSubmitWsp}>
                            <div className="col-12">
                                <Accordion>
                                    {React.Children.toArray(dataHourWsp.map((h, i) => {
                                        return <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey={i + 1}>
                                                    {h.name_day}
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={i + 1}>
                                                <Card.Body>
                                                    {h.details.length > 0 ? React.Children.toArray(h.details.map((d, j) => {
                                                        return <div className="form-row">
                                                            <Input
                                                                text="Hora Inicio"
                                                                type="time"
                                                                size=""
                                                                classNameParent="col-6"
                                                                value={d.hour_start}
                                                                onChange={e => {
                                                                    setDataHourWsp(dataHourWsp.map((h2, i2) => {
                                                                        if (i === i2) {
                                                                            h2.details = h2.details.map((d2, j2) => {
                                                                                if (j === j2) {
                                                                                    d2.hour_start = e.currentTarget.value
                                                                                }

                                                                                return d2
                                                                            })
                                                                        }

                                                                        return h2
                                                                    }))
                                                                }}
                                                            />
                                                            <Input
                                                                text="Hora Fin"
                                                                type="time"
                                                                size=""
                                                                classNameParent="col-6"
                                                                value={d.hour_end}
                                                                onChange={e => {
                                                                    setDataHourWsp(dataHourWsp.map((h2, i2) => {
                                                                        if (i === i2) {
                                                                            h2.details = h2.details.map((d2, j2) => {
                                                                                if (j === j2) {
                                                                                    d2.hour_end = e.currentTarget.value
                                                                                }

                                                                                return d2
                                                                            })
                                                                        }

                                                                        return h2
                                                                    }))
                                                                }}
                                                                append={h.details.length === 1 || j + 1 === h.details.length ? [
                                                                    <Button variant="primary" onClick={_ => {
                                                                        setDataHourWsp(dataHourWsp.map((h2, i2) => {
                                                                            if (i === i2) {
                                                                                h2.details = [
                                                                                    ...h2.details,
                                                                                    {
                                                                                        id_bot_date: '',
                                                                                        hour_start: '',
                                                                                        hour_end: ''
                                                                                    }
                                                                                ]
                                                                            }

                                                                            return h2
                                                                        }))
                                                                    }}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </Button>
                                                                ] : ''}
                                                            />
                                                        </div>
                                                    })) : <div>
                                                        <Button variant="primary" size="sm" onClick={_ => {
                                                            setDataHourWsp(dataHourWsp.map((h2, i2) => {
                                                                if (i === i2) {
                                                                    h2.details = [
                                                                        ...h2.details,
                                                                        {
                                                                            id_bot_date: '',
                                                                            hour_start: '',
                                                                            hour_end: ''
                                                                        }
                                                                    ]
                                                                }

                                                                return h2
                                                            }))
                                                        }}>
                                                            <i className="fa fa-plus"></i> Agregar horario
                                                        </Button>
                                                    </div>}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    }))}
                                </Accordion>
                            </div>
                        </FormCustom>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>

        <div className="col-md-6 mb-2">
            <Card>
                <Card.Header className="justify-content-between">
                    <span>Horario de ChatBot Facebook</span>
                </Card.Header>
                <Card.Body>
                    <ReactPlaceholder ready={ready} rows={10} showLoadingAnimation>
                        <FormCustom dataSubmit={dataHourFb} onSubmit={onSubmitFb} loadSubmit={loadSubmitFb}>
                            <div className="col-12">
                                <Accordion>
                                    {React.Children.toArray(dataHourFb.map((h, i) => {
                                        return <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey={i + 1}>
                                                    {h.name_day}
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={i + 1}>
                                                <Card.Body>
                                                    {h.details.length > 0 ? React.Children.toArray(h.details.map((d, j) => {
                                                        return <div className="form-row">
                                                            <Input
                                                                text="Hora Inicio"
                                                                type="time"
                                                                size=""
                                                                classNameParent="col-6"
                                                                value={d.hour_start}
                                                                onChange={e => {
                                                                    setDataHourFb(dataHourFb.map((h2, i2) => {
                                                                        if (i === i2) {
                                                                            h2.details = h2.details.map((d2, j2) => {
                                                                                if (j === j2) {
                                                                                    d2.hour_start = e.currentTarget.value
                                                                                }

                                                                                return d2
                                                                            })
                                                                        }

                                                                        return h2
                                                                    }))
                                                                }}
                                                            />
                                                            <Input
                                                                text="Hora Fin"
                                                                type="time"
                                                                size=""
                                                                classNameParent="col-6"
                                                                value={d.hour_end}
                                                                onChange={e => {
                                                                    setDataHourFb(dataHourFb.map((h2, i2) => {
                                                                        if (i === i2) {
                                                                            h2.details = h2.details.map((d2, j2) => {
                                                                                if (j === j2) {
                                                                                    d2.hour_end = e.currentTarget.value
                                                                                }

                                                                                return d2
                                                                            })
                                                                        }

                                                                        return h2
                                                                    }))
                                                                }}
                                                                append={h.details.length === 1 || j + 1 === h.details.length ? [
                                                                    <Button variant="primary" onClick={_ => {
                                                                        setDataHourFb(dataHourFb.map((h2, i2) => {
                                                                            if (i === i2) {
                                                                                h2.details = [
                                                                                    ...h2.details,
                                                                                    {
                                                                                        id_bot_date: '',
                                                                                        hour_start: '',
                                                                                        hour_end: ''
                                                                                    }
                                                                                ]
                                                                            }

                                                                            return h2
                                                                        }))
                                                                    }}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </Button>
                                                                ] : ''}
                                                            />
                                                        </div>
                                                    })) : <div>
                                                        <Button variant="primary" size="sm" onClick={_ => {
                                                            setDataHourFb(dataHourFb.map((h2, i2) => {
                                                                if (i === i2) {
                                                                    h2.details = [
                                                                        ...h2.details,
                                                                        {
                                                                            id_bot_date: '',
                                                                            hour_start: '',
                                                                            hour_end: ''
                                                                        }
                                                                    ]
                                                                }

                                                                return h2
                                                            }))
                                                        }}>
                                                            <i className="fa fa-plus"></i> Agregar horario
                                                        </Button>
                                                    </div>}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    }))}
                                </Accordion>
                            </div>
                        </FormCustom>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>

        <div className="col-md-6 mb-2">
            <Card>
                <Card.Header className="justify-content-between">
                    <span>Horario de ChatBot Web</span>
                </Card.Header>
                <Card.Body>
                    <ReactPlaceholder ready={ready} rows={10} showLoadingAnimation>
                        <FormCustom dataSubmit={dataHourWeb} onSubmit={onSubmitWeb} loadSubmit={loadSubmitWeb}>
                            <div className="col-12">
                                <Accordion>
                                    {React.Children.toArray(dataHourWeb.map((h, i) => {
                                        return <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey={i + 1}>
                                                    {h.name_day}
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={i + 1}>
                                                <Card.Body>
                                                    {h.details.length > 0 ? React.Children.toArray(h.details.map((d, j) => {
                                                        return <div className="form-row">
                                                            <Input
                                                                text="Hora Inicio"
                                                                type="time"
                                                                size=""
                                                                classNameParent="col-6"
                                                                value={d.hour_start}
                                                                onChange={e => {
                                                                    setDataHourWeb(dataHourWeb.map((h2, i2) => {
                                                                        if (i === i2) {
                                                                            h2.details = h2.details.map((d2, j2) => {
                                                                                if (j === j2) {
                                                                                    d2.hour_start = e.currentTarget.value
                                                                                }

                                                                                return d2
                                                                            })
                                                                        }

                                                                        return h2
                                                                    }))
                                                                }}
                                                            />
                                                            <Input
                                                                text="Hora Fin"
                                                                type="time"
                                                                size=""
                                                                classNameParent="col-6"
                                                                value={d.hour_end}
                                                                onChange={e => {
                                                                    setDataHourWeb(dataHourWeb.map((h2, i2) => {
                                                                        if (i === i2) {
                                                                            h2.details = h2.details.map((d2, j2) => {
                                                                                if (j === j2) {
                                                                                    d2.hour_end = e.currentTarget.value
                                                                                }

                                                                                return d2
                                                                            })
                                                                        }

                                                                        return h2
                                                                    }))
                                                                }}
                                                                append={h.details.length === 1 || j + 1 === h.details.length ? [
                                                                    <Button variant="primary" onClick={_ => {
                                                                        setDataHourWeb(dataHourWeb.map((h2, i2) => {
                                                                            if (i === i2) {
                                                                                h2.details = [
                                                                                    ...h2.details,
                                                                                    {
                                                                                        id_bot_date: '',
                                                                                        hour_start: '',
                                                                                        hour_end: ''
                                                                                    }
                                                                                ]
                                                                            }

                                                                            return h2
                                                                        }))
                                                                    }}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </Button>
                                                                ] : ''}
                                                            />
                                                        </div>
                                                    })) : <div>
                                                        <Button variant="primary" size="sm" onClick={_ => {
                                                            setDataHourWeb(dataHourWeb.map((h2, i2) => {
                                                                if (i === i2) {
                                                                    h2.details = [
                                                                        ...h2.details,
                                                                        {
                                                                            id_bot_date: '',
                                                                            hour_start: '',
                                                                            hour_end: ''
                                                                        }
                                                                    ]
                                                                }

                                                                return h2
                                                            }))
                                                        }}>
                                                            <i className="fa fa-plus"></i> Agregar horario
                                                        </Button>
                                                    </div>}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    }))}
                                </Accordion>
                            </div>
                        </FormCustom>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
    </div>
}

export default BotHour