import React, { useState, useEffect, /* useMemo */ } from 'react'
import Controllers from '../../Api/Controllers';
import FieldWelcome from '../../Components/common/FieldWelcome';

export default function Answer() {
    const [ listFields, setListFields ] = useState([]);
    const [rowsFlowChat, setRowsFlowChat] = useState([]);

    const getAnswer = () => Controllers.answer.get_answer()
        .then(({ data }) => setListFields(data))
    
    const getFlowChat = () => Controllers.flow_chat.get_flow_chat2()
        .then(({ data }) => {
            const rowsNew = [];
            data.forEach(item => {
                if (item?.WEB) {
                rowsNew.push({...item.WEB, type: 'Web'})
                } else if (item?.WHATSAPP) {
                rowsNew.push({...item.WHATSAPP, type: 'Whatsapp'})
                } else if (item?.FACEBOOK) {
                rowsNew.push({...item.FACEBOOK, type: 'Facebook'})
                } else if (item?.INSTAGRAM) {
                rowsNew.push({...item.INSTAGRAM, type: 'Instagram'})
                }
            });
            setRowsFlowChat(rowsNew)
        })
    
    useEffect(() => {
        getAnswer();
        getFlowChat();
    }, [])


    const onChangeChecked = id => {
        setListFields(listFields.map(field => {
            if (field.id_answer === id) {
                return {
                    ...field,
                    enabled: field.enabled === 1 ? 0 : 1,
                }
            }
            return field
        }))
    }

    return (
        <div className="form-row">
            {listFields.map((item, key) => (
                <FieldWelcome
                    item={item}
                    itemFlowChat={rowsFlowChat?.length > 0 &&
                        rowsFlowChat?.find(it => it.type.toLowerCase() === item.type.toLowerCase())
                    }
                    key={item.id_answer || key}
                    onChangeChecked={onChangeChecked}
                />
            ))}
        </div>
    )
}

/* 

// import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap/Button';
// import { toast } from 'react-toastify';
// import Helpers from '../../Components/Helpers/Helpers';
// import { FormCustom, TextArea, CheckBox } from '../../Components/Form/Form'

// const [rowWhatsapp, setRowWhatsapp] = useState({
    //     p_message: '',
    //     p_id_answer: '',
    //     p_type: 'WHATSAPP',
    //     p_enabled: 1
    // })
    // const [rowFacebook, setRowFacebook] = useState({
    //     p_message: '',
    //     p_id_answer: '',
    //     p_type: 'FACEBOOK',
    //     p_enabled: 1
    // })
    // const [loadSubmitWhatsapp, setLoadSubmitWhatsapp] = useState(false)
    // const [loadSubmitFacebook, setLoadSubmitFacebook] = useState(false)

                // let findWhatsapp = res.data.find(r => r.type === 'WHATSAPP')
            // if (findWhatsapp) {
            //     setRowWhatsapp({
            //         p_message: findWhatsapp.message,
            //         p_id_answer: findWhatsapp.id_answer,
            //         p_type: 'WHATSAPP',
            //         p_enabled: findWhatsapp.enabled
            //     })
            // }
            // let findFacebook = res.data.find(r => r.type === 'FACEBOOK')
            // if (findFacebook) {
            //     setRowFacebook({
            //         p_message: findFacebook.message,
            //         p_id_answer: findFacebook.id_answer,
            //         p_type: 'FACEBOOK',
            //         p_enabled: findFacebook.enabled
            //     })
            // }

            
    // ! Se movio function para fieldWelcome.jsx
    // const onSubmitWhatsapp = ({ data }) => {
    //     setLoadSubmitWhatsapp(true)
    //     Controllers.answer.answer_insert_update(data).then(res => {
    //         Helpers.toast.construct({ ...res, toast })
    //         if (res.response === 'success') {
    //         }
    //     }).catch(req => Helpers.promise.catch({ req, toast }))
    //         .finally(() => {
    //             setLoadSubmitWhatsapp(false)
    //         })
    // }

    // const onSubmitFacebook = ({ data }) => {
    //     setLoadSubmitFacebook(true)
    //     Controllers.answer.answer_insert_update(data).then(res => {
    //         Helpers.toast.construct({ ...res, toast })
    //         if (res.response === 'success') {
    //         }
    //     }).catch(req => Helpers.promise.catch({ req, toast }))
    //         .finally(() => {
    //             setLoadSubmitFacebook(false)
    //         })
    // }
            <div className="col-md-6 mb-2">
                <Card>
                    <Card.Header className="justify-content-between">
                        <span></span>
                    </Card.Header>
                    <Card.Body>
                        <FormCustom dataSubmit={rowWhatsapp} onSubmit={onSubmitWhatsapp} loadSubmit={loadSubmitWhatsapp}>
                            <TextArea
                                text="Mensaje"
                                placeholder="Mensaje"
                                classNameParent="col-12 mb-2"
                                invalid="El campo es obligatorio."
                                defaultValue={rowWhatsapp.p_message}
                                onChange={e => setRowWhatsapp({ ...rowWhatsapp, p_message: e.currentTarget.value })}
                                rows="10"
                            />
                            <CheckBox
                                id="enabled-whatsapp"
                                checked={parseInt(rowWhatsapp.p_enabled) === 1}
                                onChange={e => setRowWhatsapp({ ...rowWhatsapp, p_enabled: e.currentTarget.checked ? 1 : 0 })}
                                text="Activar"
                            />
                        </FormCustom>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-md-6">
                <Card>
                    <Card.Header className="justify-content-between">
                        <span>Respuesta Automática Facebook</span>
                    </Card.Header>
                    <Card.Body>
                        <FormCustom dataSubmit={rowFacebook} onSubmit={onSubmitFacebook} loadSubmit={loadSubmitFacebook}>
                            <TextArea
                                text="Mensaje"
                                placeholder="Mensaje"
                                classNameParent="col-12 mb-2"
                                invalid="El campo es obligatorio."
                                defaultValue={rowFacebook.p_message}
                                onChange={e => setRowFacebook({ ...rowFacebook, p_message: e.currentTarget.value })}
                                rows="10"
                            />
                            <CheckBox
                                id="enabled-facebook"
                                checked={parseInt(rowFacebook.p_enabled) === 1}
                                onChange={e => setRowFacebook({ ...rowFacebook, p_enabled: e.currentTarget.checked ? 1 : 0 })}
                                text="Activar"
                            />
                        </FormCustom>
                    </Card.Body>
                </Card>
            </div>
*/