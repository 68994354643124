/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
// import DropdownButton from "react-bootstrap/esm/DropdownButton";
// import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
// import Dropdown from "react-bootstrap/esm/Dropdown";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import Card from "react-bootstrap/esm/Card";
import { useSelector } from "react-redux";
import Picker from "emoji-picker-react";
import Tabs from "react-bootstrap/Tabs";
// import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import { Row } from "react-bootstrap";
import {
  SwitchToggleChecked,
  FormCustom,
  TextArea,
  Select,
  Input,
} from "../../Components/Form/Form";
import EditableElement from "../../Components/common/EditableElement";
import SweetAlert from "../../Components/SweetAlert/SweetAlert";
import FormatDate from "../../Components/common/FormatDate";
import TildesAzules from "../../assets/Tildes azules-1.png";
import Helpers from "../../Components/Helpers/Helpers";
import TransImage from "../../assets/trans.png";
import Controllers from "../../Api/Controllers";
import FormUpdate from "./FormUpdate";
import sleep from "../../utils/sleep";
import Buttons from "./Buttons";
import { messageCacheDefault } from "../../db/state";
import SendMessange from "../../Components/messages/SendMessange";
import MessageRight from "../../Components/messages/MessageRight";
import Informative from "../../Components/messages/Informative";
import MessageLeft from "../../Components/messages/MessageLeft";
import PContacts from "../../Components/placeholder/PContacts";
// import Programmed from "../../Components/messages/Programmed";
import Reactivate from "../../Components/buttons/Reactivate";
import { StateContext } from "../../context/StateContext";
import NewMessage, {
  RenderModalImage,
} from "../../Components/modals/NewMessage";
// import ProgramButton from "./program-button";
import Pending from "../../Components/messages/Pending";

import { SavedAnswerButton } from "./components/saved-answer";
import useGetTemplates from "../templates/hooks/use-get-templates";
import EventEditAddForm from "./modals/event-edit-add-form";

const styleCard = {
  borderRadius: 0,
};

const styleProfileImage = {
  borderRadius: 50,
};

const heightStartMessage = "65px";
const heightContent = "500px";
const heightBox = "65px";

export default function MultiAgent(props) {
  const [openEventForm, setOpenEventForm] = useState(false);
  const [chatPending, setChatPending] = useState([]);
  // const [schedukedMessages, setSchedukedMessages] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  let [imagesSend, setImagesSend] = useState([]);
  let [images, setImages] = useState([]);
  let [filesSend, setFilesSend] = useState([]);
  const [activeCache, setActiveCache] = useState(false);
  const [loadCRM, setLoadCRM] = useState(false);

  const [messageCache, setMessageCache] = useState(messageCacheDefault);
  const [loadAgent, setLoadAgent] = useState(false);
  const [loadSend, setLoadSend] = useState(false);
  const [loadSendImage, setLoadSendImage] = useState(false);
  const [agent, setAgent] = useState(undefined);
  const [agents, setAgents] = useState([]);
  const [multiAgent, setMultiAgent] = useState(false);
  const [filterChat, setFilterChat] = useState({
    value: 2,
    label: "Todos los chats",
  });

  // const idCustomers = useSelector((store) => store.session.id_customers);
  // const username = useSelector((store) => store.session.username);
  const [startWrite, setStartWrite] = useState(false);
  const [loadFile, setLoadFile] = useState(false);
  const [messageSend, setMessageSend] = useState("");
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState({});
  const [row, setRow] = useState({
    p_message: "",
    p_id_chat: "",
  });
  const [showSendCRM, setShowSendCRM] = useState(false);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const [searchChat, setSearchChat] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [ready, setReady] = useState(false);
  const [loadingSeeMore, setLoadingSeeMore] = useState(false);
  const [files, setFiles] = useState([]);
  const [limit, setLimit] = useState(1);
  const [chatsHeader, setChatsHeader] = useState([]);
  const [viewImage, setViewImage] = useState({
    enabled: false,
    imageUrl: "",
  });

  const store = useSelector((store) => store.session);

  const [soundActive, setSoundActive] = useState(
    parseInt(store.notification_multiagente) === 1
  );

  const [modalTransfer, setModalTransfer] = useState(false);
  const [transferChat, setTransferChat] = useState({
    id_customers_contact: "",
    current_chat: {},
    comment: "",
  });
  const [customersContactList, setCustomersContactList] = useState([]);
  const [loadSubmitTransfer, setLoadSubmitTransfer] = useState(false);

  const id_agente_real = localStorage.getItem("id_customer") || "";

  const { state, func } = useContext(StateContext);

  const { data: listTemplates } = useGetTemplates({
    templateType: "all",
    arrayMap: true,
  });

  const cacheMessageClock = (message) => {
    setActiveCache(message ? true : false);
    setMessageCache({
      ...messageCache,
      message,
    });
    messagesEndRef?.current?.scrollIntoView(false);
    document
      .querySelector("#message-scroll")
      .scrollTo(0, document.querySelector("#message-scroll").scrollHeight);
  };

  const getAgentChat = () => {
    Controllers.chat_agent
      .get_chat_agent({
        p_id_customers_contact: id_agente_real,
      })
      .then((result) => {
        let formData = { p_limit: 0 };
        const rows =
          result.data.length > 0
            ? result.data[0].agents.map((item) => ({
                label: item.name_contact,
                value: item.id_customers_contact,
              }))
            : [];
        if (rows.length > 0) {
          formData.p_all = 1;
        }
        loadAll(formData);
        const activeMultiAgent =
          rows.length > 0
            ? rows.length === 1
              ? String(rows[0].value) !== String(id_agente_real)
              : true
            : false;
        setMultiAgent(activeMultiAgent);
        setAgents(rows);
      })
      .finally(() => {
        setLoadAgent(false);
      });
  };

  const loadAll = (body) => {
    setReady(false);
    setIsLoading(true);
    Promise.all([
      // Controllers.chat.get_chat(),
      Controllers.chat.get_chat_post(body),
      Controllers.file.get_file(),
      Controllers.customers_contact.get_customers_contact(),
    ])
      .then((res) => {
        setChats(res[0].data.chats);
        setChatsHeader(res[0].data.header);
        setFiles(res[1].data.filter((d) => parseInt(d.enabled_file) === 1));
        setCustomersContactList(
          res[2].data
            .filter((c) => {
              return (
                parseInt(c.enabled_contact) === 1 && parseInt(c.is_agent) === 1
              );
            })
            .map((r) => {
              return {
                label: r.name_contact,
                value: r.id_customers_contact,
              };
            })
        );
      })
      .finally(() => {
        setReady(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAgentChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isObs, setIsObs] = useState(false);
  const [loadingChats, setLoadingChats] = useState(false);
  const [chatAdded, setChatAdded] = useState([]);

  useEffect(() => {
    if (ready) {
      setTimeout((_) => {
        let divChat = document.getElementById("observer_chats");
        let lazyImageObserver = new IntersectionObserver(
          function (entries, observer) {
            entries.forEach(function (entry) {
              if (entry.isIntersecting) {
                setIsObs(true);
              }
            });
          },
          {
            rootMargin: "0px 0px 0px 0px",
          }
        );

        lazyImageObserver.observe(divChat);
      }, 0);
    }
  }, [ready]);

  useEffect(
    (_) => {
      if (isObs) {
        setLoadingChats(true);
        setIsObs(false);
      }
    },
    [isObs]
  );

  const _handleSeeMore = () => {
    if (!loadingSeeMore) {
      setLoadingSeeMore(true);
      let formData = {
        p_limit: limit,
      };
      if (agent?.value) {
        formData.p_id_agent = agent?.value;
      }
      if (filterChat.value === 2) {
        formData.p_all = 1;
      }
      if (filterChat.value === 1) {
        formData.p_id_agent = id_agente_real;
      }
      // eslint-disable-next-line default-case
      switch (`${key}`) {
        case "2":
          formData.p_search_type = "CRM";
          break;
        case "1":
          formData.p_search_type = "ATENDIDOS";
          break;
        case "0":
          formData.p_search_type = "NO_ATENDIDOS";
          break;
        case "-1":
          formData.p_search_type = "TODO";
          break;
      }
      Controllers.chat
        .get_chat_post(formData)
        .then((res) => {
          if (res.data.chats.length > 0) {
            setLimit(limit + 1);
            document
              .querySelector(".list-chats")
              .scrollTo(
                0,
                document.querySelector(".list-chats").scrollHeight / 2
              );
            setChatAdded(res.data.chats);
          } else {
            setLimit(null);
          }
        })
        .finally(() => {
          setLoadingSeeMore(false);
        });
    }
  };

  useEffect(
    (_) => {
      setLoadingChats(false);
    },
    [limit, loadingChats]
  );

  useEffect(
    (_) => {
      if (chatAdded.length) {
        const rows = chatAdded.filter(
          (it) => !chats.find((item) => item.id_chat === it.id_chat)
        );
        setChats([...chats, ...rows]);
        setChatAdded([]);
      }
    },
    [chatAdded, chats]
  );

  useEffect(() => {
    if (state.type) {
      switch (state.type) {
        case "NEW_CHAT":
          if (
            chats.some(
              (c) => parseInt(c.id_chat) === parseInt(state?.data?.id_chat)
            )
          ) {
            setChats(
              chats.map((c) => {
                if (parseInt(c.id_chat) === parseInt(state?.data?.id_chat)) {
                  c = state.data;
                }
                return c;
              })
            );

            if (
              parseInt(currentChat.id_chat) === parseInt(state?.data?.id_chat)
            ) {
              setCurrentChat(state.data);
            }
          } else {
            setChats([...chats, state.data]);
          }
          setTimeout(() => {
            func.dataChat({}, "");
          }, 100);
          scrollDownMessage();

          break;

        case "MESSAGE":
          setChats(
            chats
              .map((c) => {
                if (
                  parseInt(c.id_chat) === parseInt(state.data.detail.id_chat)
                ) {
                  if (parseInt(currentChat.id_chat) === parseInt(c.id_chat)) {
                    state.data.detail.seen = 1;
                    Controllers.chat.chat_seen_all({
                      p_id_chat: c.id_chat,
                    });
                  }

                  c.send_message_react = state.data.chat.send_message_react;
                  c.expired_whatsapp = state.data.chat.expired_whatsapp;
                  c.status_chat = 0;
                  c.message = state.data.detail.message;
                  c.date_created = state.data.detail.date_created;
                  c.messages = [...c.messages, state.data.detail];
                  c.id_freshchat = "test1";
                }
                return c;
              })
              .sort(function (a, b) {
                return new Date(b.date_created) - new Date(a.date_created);
              })
          );
          setTimeout(() => {
            func.dataChat({}, "");
          }, 100);
          scrollDownMessage();
          break;

        default:
          break;
      }
      Controllers.chat.chat_header().then((res) => {
        setChatsHeader(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type]);

  useEffect(
    (_) => {
      document.title = `(${
        chats.filter((c) => parseInt(c.status_chat) === 0).length
      }) Multiagente - Tecnicom`;
    },
    [chats]
  );

  const heightBoxMessage = useCallback(() => {
    if (startWrite) {
      return {
        heightMessage: heightStartMessage,
        rows: 1,
        heightContentMessage: `calc(${heightContent} - ${heightBox} - ${heightStartMessage})`,
        heightContentData: `calc(${heightContent} - ${heightBox}`,
      };
    } else {
      return {
        heightMessage: heightBox,
        rows: 1,
        heightContentMessage: `calc(${heightContent} - ${heightBox} - ${heightBox})`,
        heightContentData: `calc(${heightContent} - ${heightBox}`,
      };
    }
  }, [startWrite]);

  const scrollDownMessage = (_) => {
    setTimeout(() => {
      messagesEndRef?.current?.scrollIntoView(false);
      document
        .querySelector("#message-scroll")
        .scrollTo(0, document.querySelector("#message-scroll").scrollHeight);
    }, 200);
  };

  const [key, setKey] = useState(-1);
  const [modalMessage, setModalMessage] = useState(false);

  useEffect(() => {
    if (currentChat.id_chat) {
      Controllers.chat
        .get_chat_time({
          p_chat: currentChat.id_chat,
        })
        .then((res) => {
          setChatPending(
            res.data.filter((item) => item.status_log === "PENDING")
          );
        })
        .catch(() => setChatPending([]));
    } else {
      setChatPending([]);
    }
  }, [currentChat, state.type]);

  const sendMessage = ({ idFile, idChat }, msg) => {
    if (idFile) {
      setMessageSend(msg);
      Controllers.chat
        .send_message_file({
          p_id_file: idFile,
          p_id_chat: idChat,
        })
        .then((res) => {
          Helpers.toast.construct({ ...res, toast });
        })
        .finally(() => setMessageSend(""));
    }
  };

  const chatFilters = useCallback(
    (_) => {
      return chats
        .filter((c) => {
          if (parseInt(key) >= 0) {
            if (search) {
              let matchMessage = c.messages.some((m) => {
                if (m?.message) {
                  return m.message.toLowerCase().includes(search.toLowerCase());
                }

                return m;
              });

              return (
                parseInt(c.status_chat) === parseInt(key) &&
                (c.name_chat.toLowerCase().includes(search.toLowerCase()) ||
                  c.number_chat.toLowerCase().includes(search.toLowerCase()) ||
                  c.seller.name_contact.toLowerCase().includes(search) ||
                  matchMessage)
              );
            }

            return parseInt(c.status_chat) === parseInt(key);
          }
          if (search) {
            /* let matchMessage = c.messages.some(m => {
            if (m?.message) {
                return m.message.toLowerCase().includes(search.toLowerCase())
            }
            return m
        }) */

            return (
              c.name_chat.toLowerCase().includes(search.toLowerCase()) ||
              c.number_chat.toLowerCase().includes(search.toLowerCase()) ||
              c.seller.name_contact.toLowerCase().includes(search)
            ); /*  || matchMessage */
          }

          return true;
        })
        .sort(function (a, b) {
          return new Date(b.date_created) - new Date(a.date_created);
        });
    },
    [chats, key, search]
  );

  const sumChats = useCallback(
    (_) => {
      let total = 0;
      chatsHeader.forEach((c) => {
        total += parseInt(c.qty);
      });
      return total;
    },
    [chatsHeader]
  );

  const cboCustomerContact = useCallback(() => {
    if (!transferChat.id_customers_contact) {
      return "";
    }

    let finded = customersContactList.find(
      (j) => parseInt(j.value) === parseInt(transferChat.id_customers_contact)
    );
    if (!finded) {
      return "";
    }

    return finded;
  }, [transferChat.id_customers_contact, customersContactList]);

  const handleTransfer = (_) => {
    setTransferChat({
      comment: "",
      id_customers_contact: "",
      current_chat: currentChat,
    });
    setModalTransfer(true);
  };

  const handleSubmitImages = async () => {
    try {
      setLoadSend(true);
      setLoadSendImage(true);
      if (imagesSend.length > 0) {
        for (let i = 0; i < imagesSend.length; i++) {
          const image = imagesSend[i];
          if (image.p_image) {
            let formData = new FormData();
            formData.append("p_id_chat", currentChat.id_chat);
            formData.append("p_image", image.p_image);
            await Controllers.chat.send_image(formData);
          }
        }
      }
      if (filesSend.length > 0) {
        setLoadFile(true);
        for (let i = 0; i < filesSend.length; i++) {
          const file = filesSend[i];
          let formData = new FormData();
          formData.append("p_id_chat", currentChat.id_chat);
          formData.append("p_image", file);
          await Controllers.chat.send_image(formData);
        }
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      getChats(0);
      setImagesSend([]);
      setFilesSend([]);
      setImages([]);
      setLoadFile(false);
      document
        .querySelector("#message-scroll")
        .scrollTo(0, document.querySelector("#message-scroll").scrollHeight);
      setLoadSendImage(false);
      setLoadSend(false);
    }
  };

  useEffect(() => {
    if (messageSend) {
      messagesEndRef.current?.scrollIntoView(false);
    }
  }, [messageSend]);

  const handleSubmit = () => {
    if (imagesSend.length > 0 || filesSend.length > 0) {
      setLoadSend(true);
      handleSubmitImages();
    }
    const msg = row.p_message.trimStart();
    if (!loadSend && msg) {
      setLoadSend(true);
      setMessageSend(row.p_message);
      Controllers.chat
        .chat_det_insert(row)
        .then((res) => {
          if (res.response !== "success") {
            Helpers.toast.construct({ ...res, toast });
            return;
          }
          setChats(
            chats.map((c) => {
              if (parseInt(c.id_chat) === currentChat.id_chat) {
                c.message = row.p_message;
                c.status_chat = 1;
                c.messages.push({
                  message: row.p_message,
                  from_user: 1,
                  hour_message: Helpers.date.get({
                    format: "%d/%m/%Y %H:%i %a",
                  }),
                });
              }

              return c;
            })
          );
          scrollDownMessage();
          setRow({ ...row, p_message: "" });
          let contentTarget = document.getElementById("imageUpload");
          contentTarget.innerHTML = "";
          Controllers.chat
            .chat_header()
            .then((res) => setChatsHeader(res.data));
        })
        .then(() =>
          document
            .querySelector("#message-scroll")
            .scrollTo(0, document.querySelector("#message-scroll").scrollHeight)
        )
        .catch((_) =>
          Helpers.toast.construct({
            response: "warning",
            message: "No se ha podido enviar el mensaje.",
            toast,
          })
        )
        .finally(() => {
          setMessageSend("");
          setLoadSend(false);
        });
    } else {
      setMessageSend("");
      setRow({ ...row, p_message: "" });
      let contentTarget = document.getElementById("imageUpload");
      contentTarget.innerHTML = "";
    }
  };

  const searchSocialNetwork = (res) => {
    let icon = "",
      color = "";

    if (res === "FACEBOOK") {
      icon = "fab fa-facebook";
      color = "#1094F4";
    } else if (res === "WHATSAPP") {
      icon = "fab fa-whatsapp";
      color = "#0DC143";
    } else if (res === "TELEGRAM") {
      icon = "fab fa-telegram";
      color = "#32AADE";
    } else if (res === "IG") {
      icon = "fab fa-instagram";
      color = "#833AB4";
    } else {
      icon = "fa fa-globe";
      color = "";
    }

    return { icon, color };
  };

  const getChats = async (p_limit) => {
    let formData = {
      p_limit,
    };
    if (agent?.value) {
      formData.p_id_agent = agent?.value;
    }
    if (filterChat.value === 2) {
      formData.p_all = 1;
    }
    if (filterChat.value === 1) {
      formData.p_id_agent = id_agente_real;
    }
    // eslint-disable-next-line default-case
    switch (`${key}`) {
      case "2":
        formData.p_search_type = "CRM";
        break;
      case "1":
        formData.p_search_type = "ATENDIDOS";
        break;
      case "0":
        formData.p_search_type = "NO_ATENDIDOS";
        break;
      case "-1":
        formData.p_search_type = "TODO";
        break;
    }
    const res = await Controllers.chat.get_chat_post(formData);

    if (p_limit === 0) {
      setChats(res.data.chats);
    } else {
      setChatAdded(res.data.chats);
    }
    if (currentChat?.id_chat && res.data.chats.length !== 0) {
      const c = res.data.chats.find(
        (c) => parseInt(c.id_chat) === parseInt(currentChat?.id_chat)
      );
      while (!c) {
        return await getChats(p_limit + 1);
      }
      setCurrentChat(c);
    }
    return;
  };

  const _handleUpdateData = async (deleteMsg) => {
    try {
      await getChats(0);
      const res = await Controllers.chat.chat_header();
      setChatsHeader(res.data);
    } catch (error) {
      Helpers.toast.construct({
        response: "warning",
        message:
          "No se ha podido actualizar la lista por favor recargue la pagina",
        toast,
      });
    } finally {
      scrollDownMessage();
      setModalMessage(false);
      if (!deleteMsg) {
        Helpers.toast.construct({
          response: "success",
          message: "Mensaje enviado",
          toast,
        });
      } else {
        Helpers.toast.construct({
          response: "success",
          message: "Mensaje pendiente eliminado",
          toast,
        });
      }
    }
  };

  const _handleSearchChat = (index) => {
    let p_search_type = "TODO";
    const type = index || key;
    // eslint-disable-next-line default-case
    switch (`${type}`) {
      case "2":
        p_search_type = "CRM";
        break;
      case "1":
        p_search_type = "ATENDIDOS";
        break;
      case "0":
        p_search_type = "NO_ATENDIDOS";
        break;
      case "-1":
        p_search_type = "TODO";
        break;
    }
    let formData = {
      p_search_type,
      p_limit: 0,
    };
    formData = search
      ? {
          ...formData,
          p_search: search,
        }
      : formData;
    setLimit(1);
    if (agent?.value) {
      formData.p_id_agent = agent?.value;
    }
    if (filterChat.value === 2) {
      formData.p_all = 1;
    }
    if (filterChat.value === 1) {
      formData.p_id_agent = id_agente_real;
    }
    if (!index) {
      setSearchChat(true);
    }
    loadAll(formData);
  };

  const _handleCloseSearch = () => {
    if (searchChat) {
      let formData = {
        p_limit: 0,
      };
      if (agent?.value) {
        formData.p_id_agent = agent?.value;
      }
      if (filterChat.value === 2) {
        formData.p_all = 1;
      }
      if (filterChat.value === 1) {
        formData.p_id_agent = id_agente_real;
      }
      loadAll(formData);
    }
    setSearchChat(false);
    setSearch("");
  };

  const removeImage = (index) => {
    setImages(images.filter((_, key) => key !== index));
    setImagesSend(imagesSend.filter((_, key) => key !== index));
  };

  const handleFile = (file) => {
    setFilesSend([...filesSend, file]);
  };

  const messageCurrent =
    activeCache && currentChat?.messages
      ? [...currentChat.messages, messageCache]
      : currentChat?.messages || [];

  const handleReportAgent = async () => {
    let data = {
      p_id_agent: "",
    };
    if (filterChat.value === 2) {
      data.p_all = 1;
    } else if (filterChat.value === 3) {
      data.p_id_agent = agent.value;
    } else {
      data.p_id_agent = id_agente_real;
    }
    Controllers.chat
      .chat_report_agent(data)
      .then(({ data, response }) => {
        if (response === "success") {
          var link = document.createElement("a");
          link.href = data.file;
          link.download = data.filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        Helpers.toast.construct({
          response: "warning",
          message: "No se ha podido descargar reporte",
          toast,
        });
      });
  };

  const handleSendCRM = useCallback(async () => {
    if (!loadCRM) {
      try {
        setLoadCRM(true);
        const res = await Controllers.chat.send_crm({
          p_id_chat: currentChat.id_chat,
        });
        setChats(
          chats.map((c) => {
            if (parseInt(c.id_chat) === currentChat.id_chat) {
              c.status_chat = 2;
            }

            return c;
          })
        );
        Helpers.toast.construct({ ...res, toast });
        if (res.response === "success") {
          setShowSendCRM(false);
        }
      } catch (req) {
        Helpers.promise.catch({ req, toast });
      } finally {
        setLoadCRM(false);
      }
    }
  }, [chats, currentChat.id_chat, loadCRM]);

  return (
    <>
      <RenderModalImage
        {...viewImage}
        onClose={() => {
          setViewImage({
            enabled: false,
            imageUrl: "",
          });
        }}
      />

      {multiAgent ? (
        <header>
          <Row className="d-flex">
            <Select
              value={filterChat}
              text="tipo de chat"
              options={[
                {
                  value: 1,
                  label: "Mi Chats",
                },
                {
                  value: 2,
                  label: "Todos los chats",
                },
                {
                  value: 3,
                  label: "Por agente",
                },
              ]}
              classNameParent="col-6 w-100 mb-2"
              onChange={(e) => {
                setFilterChat(e);
                switch (e.value) {
                  case 1:
                    setLimit(1);
                    loadAll({
                      p_limit: 0,
                      p_id_agent: id_agente_real,
                    });
                    break;

                  case 2:
                    setLimit(1);
                    loadAll({
                      p_all: 1,
                      p_limit: 0,
                    });
                    break;

                  default:
                    break;
                }
              }}
            />
            {filterChat?.value === 3 && (
              <Select
                text="Seleccione agente"
                value={agent}
                isLoading={loadAgent}
                options={agents || []}
                classNameParent="w-100 col-6 mb-2"
                onChange={(e) => {
                  setAgent(e);
                  setLimit(1);
                  loadAll({
                    p_limit: 0,
                    p_id_agent: e.value,
                  });
                }}
              />
            )}
          </Row>
        </header>
      ) : null}

      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <div
            onClick={handleReportAgent}
            style={{
              maxHeight: 35,
              maxWidth: 35,
              cursor: "pointer",
              backgroundColor: "#D8572B",
              display: "inline-block",
              fontWeight: 400,
              textAlign: "center",
              verticalAlign: "middle",
              userSelect: "none",
              border: "1px solid #D8572B",
              padding: "0.256rem 0.55rem",
              fontSize: "1rem",
              lineHeight: 1.5,
              borderRadius: "0.25rem",
              color: "#ffffff",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
          </div>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => {
              if (!isLoading) {
                setCurrentChat({});
                setKey(k);
                _handleSearchChat(k);
              }
            }}
          >
            <Tab eventKey={-1} title={`Todos (${sumChats()})`}></Tab>
            <Tab
              eventKey={0}
              title={`No Atendidos (${
                chatsHeader.some((c) => parseInt(c.status_chat) === 0)
                  ? chatsHeader.find((c) => parseInt(c.status_chat) === 0)?.qty
                  : 0
              })`}
            ></Tab>
            <Tab
              eventKey={1}
              title={`Atendidos (${
                chatsHeader.some((c) => parseInt(c.status_chat) === 1)
                  ? chatsHeader.find((c) => parseInt(c.status_chat) === 1)?.qty
                  : 0
              })`}
            ></Tab>
            <Tab
              eventKey={2}
              title={`Enviados a CRM (${
                chatsHeader.some((c) => parseInt(c.status_chat) === 2)
                  ? chatsHeader.find((c) => parseInt(c.status_chat) === 2)?.qty
                  : 0
              })`}
            ></Tab>
          </Tabs>
        </div>
        <div className="align-self-center">
          <Button
            onClick={(_) => {
              Controllers.chat.change_notification({
                p_notification_multiagente: soundActive ? 0 : 1,
              });
              setSoundActive(!soundActive);
            }}
            className="mr-3"
            size="sm"
            variant={soundActive ? "dark" : "outline-secondary"}
          >
            <i
              className={
                soundActive ? "far fa-bell-slash mr-2" : "far fa-bell mr-2"
              }
            ></i>
            <span>{soundActive ? "Desactivar sonido" : "Activar sonido"}</span>
          </Button>
          <Button size="sm" onClick={(_) => setModalMessage(true)}>
            Nuevo Mensaje
          </Button>
        </div>
      </div>
      <div className="multiagente_content">
        {/* {lista de contactos} */}
        <div className="list-chats">
          <div className="p-2" style={{ display: "flex" }}>
            <Input
              onChange={(e) => {
                if (!e.target.value && searchChat) {
                  _handleCloseSearch();
                }
                setSearch(e.currentTarget.value);
              }}
              classNameParent="input-search"
              placeholder="Buscar..."
              value={search}
              type="text"
            />
            {search || searchChat ? (
              <Button
                size="sm"
                className="ml-1 mr-1"
                onClick={_handleCloseSearch}
                style={{ maxWidth: 30 }}
              >
                <span>x</span>
              </Button>
            ) : null}
          </div>
          <Card style={styleCard}>
            {isLoading ? (
              <PContacts />
            ) : (
              React.Children.toArray(
                chatFilters()?.map((c, i) => {
                  const { color, icon } = searchSocialNetwork(c.resource);
                  let showMessageComplete = c.message?.toString()?.length < 30;
                  const messageView = c?.message
                    ? c.message
                    : c.messages[c.messages.length - 1].photo
                    ? c.from_user === 1
                      ? "Archivo recibida"
                      : "Archivo enviada"
                    : "";

                  return (
                    <>
                      <Card.Body
                        style={{
                          cursor: "pointer",
                          height: 90,
                          position: "relative",
                        }}
                        className={
                          "d-flex chat_item " +
                          (c.id_chat === currentChat.id_chat
                            ? "chat_selected "
                            : "")
                        }
                        onClick={(_) => {
                          let hasSeen = false;
                          setChats(
                            chats.map((cc) => {
                              if (
                                parseInt(cc.id_chat) === parseInt(c.id_chat)
                              ) {
                                if (
                                  cc.messages.filter(
                                    (mm) =>
                                      parseInt(mm.seen) === 0 &&
                                      parseInt(mm.from_user) === 0
                                  ).length > 0
                                ) {
                                  hasSeen = true;
                                  cc.messages = cc.messages.map((mm) => {
                                    mm.seen = 1;
                                    return mm;
                                  });
                                }
                              }
                              return cc;
                            })
                          );
                          if (hasSeen) {
                            Controllers.chat.chat_seen_all({
                              p_id_chat: c.id_chat,
                            });
                          }
                          setCurrentChat(c);
                          setRow({
                            p_id_chat: c.id_chat,
                          });
                          sleep(500);
                          document
                            .querySelector("#message-scroll")
                            .scrollTo(
                              0,
                              document.querySelector("#message-scroll")
                                .scrollHeight
                            );
                          scrollDownMessage();
                        }}
                      >
                        <div className="pr-3">
                          {/* <span>{i + 1}</span> */}
                          <img
                            alt=""
                            width="45"
                            style={styleProfileImage}
                            src={
                              c.photo_chat
                                ? c.photo_chat
                                : Helpers.config.resourcesUrl({
                                    url: "/images/dashboard/not-profile-picture.png",
                                  })
                            }
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <h6
                            style={{
                              fontSize: 12,
                              fontWeight: "600",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "90%",
                              width: "100%",
                            }}
                          >
                            {c?.name_chat || c?.number_chat || ""}
                          </h6>

                          <small
                            style={{
                              width: "100%",
                              fontSize: 10,
                              maxWidth: "90%",
                              fontWeight: "400",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            className="text-muted"
                          >
                            {showMessageComplete
                              ? messageView
                              : (c?.message
                                  ? c?.message?.toString().substr(0, 25)
                                  : "") + "..."}
                          </small>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 10,
                            color,
                            fontSize: 20,
                          }}
                        >
                          <i className={icon}></i>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "35px",
                            fontSize: 10,
                            color: "#b2b2b2",
                          }}
                        >
                          <img
                            width="13"
                            alt=""
                            style={styleProfileImage}
                            src={
                              c.flag_source === "WSP_MASIVO"
                                ? "/assets/iconos/msg-masivo.png"
                                : c.flag_source === "RECEPCION"
                                ? "/assets/iconos/msg-recibido.png"
                                : "/assets/iconos/msg-enviado.png"
                            }
                          />
                        </div>
                        <div
                          className="badge badge-dark"
                          style={{
                            position: "absolute",
                            bottom: 2,
                            left: 10,
                            fontSize: 10,
                          }}
                        >
                          {c?.seller?.name_short
                            ? c?.seller?.name_short
                            : c?.seller?.name_contact}
                        </div>
                        <div
                          /* className="badge badge-dark" */ style={{
                            position: "absolute",
                            bottom: parseInt(c.status_chat) === 2 ? 7 : 5,
                            right: parseInt(c.status_chat) === 2 ? 14 : 12,
                            fontSize: parseInt(c.status_chat) === 2 ? 14 : 12,
                            width: 12,
                            height: 12,
                            borderRadius: 12,
                          }}
                          className={
                            parseInt(c.status_chat) === 0
                              ? "bg-danger"
                              : parseInt(c.status_chat) === 1
                              ? "bg-success"
                              : "text-info"
                          }
                        >
                          {parseInt(c.status_chat) === 2 ? (
                            <i className="fas fa-check-circle"></i>
                          ) : (
                            ""
                          )}
                        </div>
                        {c.messages.filter(
                          (mm) =>
                            parseInt(mm.seen) === 0 &&
                            parseInt(mm.from_user) === 0
                        ).length > 0 ? (
                          <div
                            /* className="badge badge-dark" */ style={{
                              position: "absolute",
                              bottom: 24,
                              right: 7,
                              fontSize: 12,
                              width: 22,
                              height: 22,
                              borderRadius: 12,
                            }}
                            className="d-flex justify-content-center align-items-center text-white bg-success"
                          >
                            {
                              c.messages.filter(
                                (mm) =>
                                  parseInt(mm.seen) === 0 &&
                                  parseInt(mm.from_user) === 0
                              ).length
                            }
                          </div>
                        ) : (
                          ""
                        )}
                        <FormatDate date={c.date_created || ""} />
                      </Card.Body>
                      {i + 1 === chats.length ? "" : <hr className="p-0 m-0" />}
                    </>
                  );
                })
              )
            )}
          </Card>
          <div
            id="observer_chats"
            style={{ height: 30, width: "100%", textAlign: "center" }}
          >
            {loadingSeeMore ? (
              <>
                <PContacts />
                <i className="fa fa-circle-notch fa-spin text-primary mr-2 text-center" />
                <span>Cargando ...</span>
              </>
            ) : (
              ""
            )}
          </div>

          {isLoading ? null : search ? (
            <Button
              size="sm"
              style={{ margin: "5px auto", width: 120 }}
              onClick={_handleSearchChat}
            >
              Ver más
            </Button>
          ) : loadingSeeMore ? null : (
            <Button
              size="sm"
              style={{ margin: "10px auto", width: 120 }}
              onClick={_handleSeeMore}
            >
              Ver más
            </Button>
          )}
        </div>

        {/* {lista de mensaje} */}
        <div
          style={{
            border: "1px solid #EBEBE9",
            height: "73vh",
          }}
        >
          <div
            className="d-flex p-2"
            style={{
              backgroundColor: "#F2F3F1",
              minHeight: heightBox,
            }}
          >
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex">
                <div>
                  {currentChat?.id_chat ? (
                    <img
                      width="50"
                      alt=""
                      style={styleProfileImage}
                      src={
                        currentChat?.photo_chat
                          ? currentChat.photo_chat
                          : Helpers.config.resourcesUrl({
                              url: "/images/dashboard/not-profile-picture.png",
                            })
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="align-self-center ml-3">
                  <h6 className="m-0">
                    {currentChat?.name_chat} {currentChat?.number_chat}
                  </h6>
                </div>
              </div>
              <div className="align-self-center d-flex">
                {currentChat?.id_chat ? (
                  <SwitchToggleChecked
                    classNameParent={"align-self-center mr-4"}
                    id={"id_chat_" + currentChat.id_chat}
                    textCheck="Apagar Bot"
                    textUnChecked="Encender Bot"
                    checked={parseInt(currentChat.chat_off) === 1}
                    onChange={(e) => {
                      cacheMessageClock(
                        e.currentTarget.checked
                          ? "Chatbot activado"
                          : "Chabot desactivado"
                      );
                      setChats(
                        chats.map((c2) => {
                          if (
                            parseInt(c2.id_chat) ===
                            parseInt(currentChat.id_chat)
                          ) {
                            c2.chat_off = e.currentTarget.checked ? 1 : 0;
                          }
                          return c2;
                        })
                      );
                      const body = {
                        p_id_chat: currentChat.id_chat,
                        p_chat_off: e.currentTarget.checked ? 1 : 0,
                      };
                      Controllers.chat
                        .chat_off(body)
                        .then(console.log)
                        .catch(console.error)
                        .finally(() => cacheMessageClock(""));
                    }}
                  />
                ) : (
                  ""
                )}
                {currentChat?.id_chat && (
                  <div className="align-self-center mr-4">
                    <img
                      alt=""
                      width={30}
                      src={TransImage}
                      onClick={handleTransfer}
                      className="cur-pointer"
                    />
                  </div>
                )}
                {currentChat?.id_chat &&
                  parseInt(currentChat?.status_chat) === 0 && (
                    <div className="align-self-center">
                      <img
                        onClick={(_) => {
                          cacheMessageClock(
                            "El chat ha sido marcado como atendido"
                          );
                          setChats(
                            chats.map((c) => {
                              if (parseInt(c.id_chat) === currentChat.id_chat) {
                                c.status_chat = 1;
                              }
                              return c;
                            })
                          );
                          Controllers.chat
                            .finished_chat({
                              p_id_chat: currentChat.id_chat,
                            })
                            .then((res) => {
                              if (res.response !== "success") {
                                Helpers.toast.construct({ ...res, toast });
                                return;
                              }

                              setChats(
                                chats.map((c) => {
                                  if (
                                    parseInt(c.id_chat) === currentChat.id_chat
                                  ) {
                                    c.status_chat = 1;
                                  }

                                  return c;
                                })
                              );

                              Controllers.chat.chat_header().then((res) => {
                                setChatsHeader(res.data);
                              });
                            })
                            .catch((_) =>
                              Helpers.toast.construct({
                                response: "warning",
                                message: "No se ha pddido enviar el mensaje.",
                                toast,
                              })
                            )
                            .finally(() => cacheMessageClock(""));
                        }}
                        src={TildesAzules}
                        alt=""
                        width={30}
                        className="cur-pointer"
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div
            className="p-2 bg-white multiagente_content_message"
            id="message-scroll"
            style={{
              backgroundImage: `url(${Helpers.config.resourcesUrl({
                url: "/images/dashboard/chat_fondo.png",
              })})`,
              overflowY: "auto",
            }}
          >
            {React.Children.toArray(
              messageCurrent.map((m) => {
                if (m.type === "INFORMATIVO") {
                  return <Informative {...m} />;
                }
                if (parseInt(m.is_message_intern) === 1) {
                  return <MessageRight m={m} />;
                }
                return <MessageLeft m={m} />;
              })
            )}
            {chatPending.map((msg) => (
              <Pending
                {...msg}
                templates={listTemplates}
                update={_handleUpdateData}
              />
            ))}
            {/* mensaje programador */}
            {/* {schedukedMessages.length > 0 &&
              schedukedMessages.map((it, i) => <Programmed {...it} key={i} />)} */}

            {messageSend && <SendMessange message={messageSend} />}
            <div ref={messagesEndRef} />
          </div>
          <div style={{ backgroundColor: "#F2F3F1", position: "relative" }}>
            {loadFile ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span>Enviando documento...</span>
                <i className="fa fa-clock-o"></i>
              </div>
            ) : (
              filesSend.length > 0 &&
              filesSend.map((file, i) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                  key={i}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-file-pdf"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                    <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                  </svg>
                  <span>{file.name || "Archivo"}</span>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      margin: "0 5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "100px",
                    }}
                    onClick={() =>
                      setFilesSend(filesSend.filter((_, ii) => ii !== i))
                    }
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
              ))
            )}
            {images?.length > 0 ? (
              <div id={loadSendImage ? "send-image-loading" : "send-image"}>
                {loadSendImage ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span>Enviando...</span>
                    <i className="fa fa-clock-o"></i>
                  </div>
                ) : (
                  images.map((image, index) => (
                    <div className="contain-image-item">
                      <button
                        className="btn-delete"
                        onClick={() => removeImage(index)}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </button>
                      <img
                        className="imagen-Gallery"
                        src={`${image.uri}`}
                        alt=""
                      />
                    </div>
                  ))
                )}
              </div>
            ) : null}
            {openMenu && (
              <Buttons
                images={images}
                setImages={setImages}
                imagesSend={imagesSend}
                handleFile={handleFile}
                currentChat={currentChat}
                templates={listTemplates}
                close={() => setOpenMenu(false)}
                messages={currentChat.messages}
                setImagesSend={setImagesSend}
              />
            )}
            <div
              className="p-3 d-flex align-items-center"
              style={{
                backgroundColor: "#F2F3F1",
                height: heightBoxMessage().heightMessage,
              }}
            >
              {parseInt(currentChat.chat_off) === 0 ? (
                <>
                  {currentChat.resource === "WHATSAPP" &&
                  parseInt(currentChat.expired_whatsapp) === 1 ? (
                    ""
                  ) : (
                    <div
                      className="align-self-center cur-pointer"
                      style={{
                        width: "5%",
                        position: "relative",
                      }}
                    >
                      {showEmoji ? (
                        <span
                          className="fa fa-times"
                          onClick={(_) => setShowEmoji(false)}
                        ></span>
                      ) : (
                        <span onClick={(_) => setShowEmoji(true)}>🙂</span>
                      )}

                      <div
                        style={{
                          position: "absolute",
                          top: -343,
                          display: showEmoji ? "inline-block" : "none",
                        }}
                      >
                        <Picker
                          onEmojiClick={(event, emojiObject) => {
                            let contentTarget =
                              document.getElementById("imageUpload");
                            contentTarget.innerHTML = `${contentTarget.innerHTML} ${emojiObject.emoji}`;
                            setRow({
                              ...row,
                              p_message:
                                (row.p_message ? row.p_message : "") +
                                " " +
                                emojiObject.emoji,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className="align-self-center d-flex"
                    style={{ width: "100%" }}
                  >
                    {
                      currentChat.resource === "WHATSAPP" &&
                      parseInt(currentChat.expired_whatsapp) === 1 ? (
                        <div className="d-flex flex-column w-100">
                          <Reactivate
                            currentChat={currentChat}
                            updateData={_handleUpdateData}
                            templates={listTemplates}
                            sendMessageReact={parseInt(
                              currentChat.send_message_react
                            )}
                          />
                        </div>
                      ) : (
                        <>
                          <div className="contain-input">
                            <EditableElement
                              onChange={(text) =>
                                setRow({ ...row, p_message: text })
                              }
                              onChangeImage={(image) =>
                                setImages([...images, image])
                              }
                              onChangeImageSend={(image) =>
                                setImagesSend([...imagesSend, image])
                              }
                            >
                              <div className="input-div" id="imageUpload"></div>
                            </EditableElement>
                            {row.p_message ? null : (
                              <div className="input-message">
                                Escribe un mensaje aquí
                              </div>
                            )}
                          </div>
                          <div className="contain-btns">
                            <SavedAnswerButton
                              options={files}
                              onClick={(file) => {
                                sendMessage(
                                  {
                                    idFile: file.id_file,
                                    idChat: currentChat.id_chat,
                                  },
                                  file.description_file
                                );
                              }}
                            />
                            {/* <DropdownButton
                              as={ButtonGroup}
                              key={"up"}
                              ml={3}
                              id={`dropdown-button-drop-${"up"}`}
                              drop={"up"}
                              variant="secondary"
                              title=""
                              s
                            >
                              {currentChat?.id_chat
                                ? React.Children.toArray(
                                    files.map((f) => {
                                      return (
                                        <Dropdown.Item
                                          eventKey="1"
                                          onClick={(_) => {
                                            sendMessage(
                                              {
                                                idFile: f.id_file,
                                                idChat: currentChat.id_chat,
                                              },
                                              f.description_file
                                            );
                                          }}
                                        >
                                          {f.description_file}
                                        </Dropdown.Item>
                                      );
                                    })
                                  )
                                : ""}
                            </DropdownButton> */}
                            <Button
                              style={{
                                width: 35,
                                padding: 0,
                                margin: "0 3px",
                                borderRadius: 5,
                              }}
                              onClick={handleSubmit}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-send-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                              </svg>
                            </Button>
                            <Button
                              style={{
                                borderRadius: 5,
                                padding: 0,
                                width: 35,
                              }}
                              onClick={() => setOpenMenu(!openMenu)}
                            >
                              {openMenu ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-x"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-plus"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                              )}
                            </Button>
                          </div>
                        </>
                      )
                      // </FormCustom>
                    }
                  </div>
                </>
              ) : parseInt(currentChat.chat_off) === 1 ? (
                <label className="text text-info">
                  Chatbot está encendido para esta conversación.
                </label>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {/* {informacion de contacto} */}
        {currentChat.id_chat ? (
          <div
            style={{
              border: "1px solid #EBEBE9",
              height: "73vh",
              maxWidth: 200,
              minWidth: 200,
            }}
          >
            <div
              className="d-flex p-2"
              style={{
                backgroundColor: "#F2F3F1",
                minHeight: heightBox,
              }}
            >
              <div className="align-self-center ml-3">
                <h6 className="m-0">Información de contacto</h6>
              </div>
            </div>
            <div
              className="p-2 bg-white multiagente_form"
              style={{
                overflowY: "auto",
              }}
            >
              {React.Children.toArray(
                currentChat?.form?.map((m, i) => {
                  if (
                    ["TEXT", "EMAIL", "SELECT", "TEXTAREA"].includes(m.type)
                  ) {
                    return (
                      <>
                        <FormUpdate
                          idChat={currentChat?.id_chat}
                          message={m}
                          onCallback={({ currentValue }) => {
                            setChats(
                              chats.map((c) => {
                                if (currentChat.id_chat === c.id_chat) {
                                  c.form = c.form.map((m2, i2) => {
                                    if (i2 === i) {
                                      m2.value = currentValue;
                                    }

                                    return m2;
                                  });
                                }

                                return c;
                              })
                            );
                          }}
                        />
                      </>
                    );
                  }
                  return "";
                })
              )}
              {React.Children.toArray(
                currentChat?.formAdd?.map((m, i) => {
                  if (
                    m.type === "TEXT" ||
                    m.type === "EMAIL" ||
                    m.type === "SELECT" ||
                    m.type === "TEXTAREA"
                  ) {
                    return (
                      <>
                        <FormUpdate
                          idChat={currentChat?.id_chat}
                          message={m}
                          onCallback={({ currentValue }) => {
                            setChats(
                              chats.map((c) => {
                                if (currentChat.id_chat === c.id_chat) {
                                  c.formAdd = c.formAdd.map((m2, i2) => {
                                    if (i2 === i) {
                                      m2.value = currentValue;
                                    }

                                    return m2;
                                  });
                                }

                                return c;
                              })
                            );
                          }}
                        />
                      </>
                    );
                  }
                  return "";
                })
              )}
              {currentChat?.id_chat && (
                <Button size="sm" onClick={(_) => setShowSendCRM(true)}>
                  Enviar a CRM
                </Button>
              )}
              {currentChat?.id_chat && (
                <Button
                  size="sm"
                  disabled={!currentChat?.sperant_customer_id}
                  className={
                    currentChat?.sperant_customer_id ? "" : "btn-secondary"
                  }
                  style={{ marginTop: 10 }}
                  onClick={() => setOpenEventForm(true)}
                >
                  Crear evento
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </div>

      <EventEditAddForm
        open={openEventForm}
        chatId={currentChat.id_chat}
        onClose={() => setOpenEventForm(false)}
      />

      <SweetAlert
        icon="delete"
        show={showSendCRM}
        showCancelButton
        loadSubmit={loadCRM}
        onCancel={(_) => setShowSendCRM(false)}
        onConfirm={handleSendCRM}
        title={<>¿Desea enviar lead a CRM?</>}
        subtitle={<>Envío de lead a CRM</>}
        confirmButtonText="¡Sí, Enviar!"
        cancelButtonText="¡Cancelar!"
      />

      {/* Modal de nuevo mensaje */}
      <NewMessage
        enabled={modalMessage}
        onClose={() => setModalMessage(false)}
        options={listTemplates}
        update={_handleUpdateData}
      />

      <Modal
        show={modalTransfer}
        onHide={(_) => setModalTransfer(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title as="div">Transferencia de Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormCustom
            onSubmit={(_) => {
              if (!transferChat.id_customers_contact) {
                Helpers.toast.construct({
                  response: "warning",
                  message: "Debe de seleccionar Agente",
                  toast,
                });
                return;
              }
              // cacheMessageClock('')
              setLoadSubmitTransfer(true);
              Controllers.chat
                .chat_transfer({
                  p_id_customers_contact: transferChat.id_customers_contact,
                  p_id_chat: transferChat.current_chat.id_chat,
                  p_message: transferChat.comment,
                  p_id_customers_contact_current:
                    transferChat.current_chat.id_seller,
                })
                .then((res) => {
                  Helpers.toast.construct({
                    response: "success",
                    message: "Transferencia realizada",
                    toast,
                  });
                  setCurrentChat({});
                  // loadAll()
                  setModalTransfer(false);
                });
            }}
            textButton="Transferir Chat"
            loadSubmit={loadSubmitTransfer}
          >
            <Select
              options={customersContactList.filter(
                (c) => parseInt(c.value) !== parseInt(currentChat.id_seller)
              )}
              value={cboCustomerContact()}
              classNameParent="col-12 mb-2"
              text="Agente"
              onChange={(e) =>
                setTransferChat({
                  ...transferChat,
                  id_customers_contact: e.value,
                })
              }
              required
            />
            <TextArea
              required
              classNameParent="col-12 mb-2"
              type="text"
              icon="fa fa-comment"
              placeholder="Comentario adicional"
              text="Comentario adicional"
              value={transferChat.comment}
              onChange={(e) =>
                setTransferChat({
                  ...transferChat,
                  comment: e.currentTarget.value,
                })
              }
              rows={5}
            />
          </FormCustom>
        </Modal.Body>
      </Modal>
    </>
  );
}
