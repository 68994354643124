import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import DatePicker from "react-datepicker";

import Portal from "../../Views/MultiAgent/Portal";

export default function InputDateTime(props) {
  const [excludeTimes, setExcludeTimes] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [initial, setInitial] = useState(false);
  const [hours, setHours] = useState("");

  const { onChange, close } = props;

  useEffect(() => {
    if (!initial) {
      setInitial(true);
      const date = new Date();
      setStartDate(date);
    }
  }, []);

  const handleSubmit = () => {};

  return (
    <Portal>
      <div
        style={{
          background: "#ffffff",
          borderRadius: 10,
          padding: 20,
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            fontSize: "1.2em",
            fontWeight: "600",
            color: "#1b1b1b",
            marginBottom: 10,
          }}
        >
          Programar envío de mensaje
        </span>
        <div className="contain_date">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "1em",
                fontWeight: "500",
                color: "#b6b6b6",
                marginBottom: 10,
              }}
            >
              Fecha
            </span>
            <DatePicker
              showIcon
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: 5,
            }}
          >
            <span
              style={{
                fontSize: "1em",
                fontWeight: "500",
                color: "#b6b6b6",
                marginBottom: 10,
              }}
            >
              Hora
            </span>
            <input
              type="time"
              onChange={({ target: { value } }) => setHours(value)}
              value={hours}
            />
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "10px 5px", borderRadius: 5 }}
            variant="secondary"
            onClick={close}
          >
            <span style={{ margin: "0 10px", fontWeight: "500" }}>
              Cancelar
            </span>
          </Button>
          <Button
            style={{ margin: "10px 5px", borderRadius: 5 }}
            onClick={handleSubmit}
          >
            <span style={{ margin: "0 10px", fontWeight: "500" }}>
              Programar envío
            </span>
          </Button>
        </div>
      </div>
    </Portal>
  );
}
