import React from 'react'
import cacheSend from '../../assets/clock.png'

export default function Informative(props) {
  const {
    hexa_color,
    timeCache,
    message,
  } = props
  
  return (
    <div className="d-flex justify-content-center" >
      <div
        style={{
          backgroundColor: hexa_color || '#ffffff',
          justifyContent: 'center',
          position: 'relative',
          alignItems: 'center',
          textAlign: 'center',
          display: 'flex',
          width: '80%',
        }}
        className="p-2 rounded mb-4"
      >
        <span
          className="text-muted"
          style={{
            color: '#5b5b5b',
            fontSize: '9px',
            marginRight: 5,
          }}>
          {message}
        </span>
        {timeCache && <img src={cacheSend} alt="cache send" width={9} height={9} />}
      </div>
    </div>
  )
}
