import React from "react";

function ServicesContainer({ children }) {
  return (
    <>
      <div className="container-fluid">
        <div className="row">{children}</div>
      </div>
    </>
  );
}

export default ServicesContainer;
