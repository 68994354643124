const boxShadow = '0 1px 5px 0 rgba(0,0,0,.2)'
const primary = parseInt(localStorage?.theme) === 2 ? '#231357' : '#D8572B'
const bgPrimaryLight = `rgba(55,106,135, .5)`
const primaryGradient = `linear-gradient(118deg, ${primary}, #52ca79)`
const widthFull = '220px'
const widthResize = '60px'
const widthWithPadding = '200px'
const widthResizeWithPadding = '40px'
const heightHeader = '60px'
const heightFooter = '20px'

export {
  boxShadow, primary, bgPrimaryLight, primaryGradient, widthFull, widthResize, heightHeader, heightFooter, widthWithPadding, widthResizeWithPadding
}