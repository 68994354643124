/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ProgramButton from "./program-button";
import ButtonMicFill from "../../Components/buttons/ButtonMicFill";

export default function Buttons(props) {
  const refImage = useRef(null);

  const {
    close,
    images,
    setImages,
    imagesSend,
    setImagesSend,
    handleFile,
    templates,
    messages,
    currentChat,
  } = props;

  return (
    <div
      style={{
        boxShadow: "0px -5px 5px #eeeeee",
        borderTop: "1px solid #d6d6d6",
        backgroundColor: "#F2F3F1",
        position: "absolute",
        padding: "10px 15px",
        display: "grid",
        top: "-40px",
        right: 0,
        left: 0,
      }}
    >
      <div
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonMicFill currentChat={currentChat} />
        {/* programar mensaje */}
        <ProgramButton
          onClose={close}
          messages={messages}
          templates={templates}
          currentChat={currentChat}
        />
        {/* input de image */}
        <div style={{ borderRadius: 5, width: 35, height: 35, padding: 0 }}>
          <label
            htmlFor="txt_image_add_send"
            style={{
              backgroundColor: "#D8572B",
              display: "inline-block",
              fontWeight: 400,
              textAlign: "center",
              verticalAlign: "middle",
              userSelect: "none",
              border: "1px solid #D8572B",
              padding: "0.375rem 0.75rem",
              fontSize: "1rem",
              lineHeight: 1.5,
              borderRadius: "0.25rem",
              color: "#ffffff",
            }}
          >
            <i className="fa fa-paperclip"></i>
            <input
              ref={refImage}
              type="file"
              style={{ display: "none" }}
              id="txt_image_add_send"
              onChange={(e) => {
                close();
                const file =
                  e.currentTarget.files.length > 0
                    ? e.currentTarget.files[0]
                    : null;
                if (file?.type?.includes("image")) {
                  setImages([
                    ...images,
                    {
                      uri: URL.createObjectURL(e.currentTarget.files[0]),
                    },
                  ]);
                  let p_image = e.currentTarget.files[0];
                  setImagesSend([
                    ...imagesSend,
                    {
                      p_image,
                    },
                  ]);
                } else if (file) {
                  handleFile(file);
                }
                close();
              }}
              accept="application/pdf, image/*"
            />
          </label>
        </div>
      </div>
    </div>
  );
}
