import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { Link, useParams } from 'react-router-dom';
import { FormCustom, Input, Select } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder/lib';
import { useSelector } from 'react-redux';
import { PieChart } from 'react-minimal-pie-chart';
import Feed from '../Comment/Feed';

const FeedHistory = _ => {
    const [rows, setRows] = useState([])
    const [ready, setReady] = useState(false)
    const [rowsSliceNumber, setRowsSliceNumber] = useState(0)

    const [projects, setProject] = useState([])

    const [groups, setGroups] = useState([])

    useEffect(() => {
        if (!ready) {
            Promise.all([
                Controllers.feed.get_feed_history(),
                Controllers.project.get_project(),
                Controllers.group.get_group_public()
            ]).then(res => {
                setRows(res[0].data)

                let half = res[0].data.length / 2
                if (half - parseInt(half) !== 0) {
                    half++
                    half = parseInt(half)
                }
                
                setRowsSliceNumber(half)
                setProject(res[1].data)
                setGroups(res[2].data)
                setReady(true)
            }).catch(_ => setReady(true))
        }
    }, [ready])

    const changeResponse = e => {
        let { response, id } = e.currentTarget.dataset

        Controllers.comment.feed_response_update({
            p_response: response,
            p_id: id
        })

        setRows(rows.map(f => {
            if (f.id === id) {
                f.response = response
            }
            return f
        }))
    }

    const changeProject = ({ e, id }) => {
        Controllers.comment.feed_project_update({
            p_id_project: e.value,
            p_id: id
        })

        setRows(rows.map(f => {
            if (f.id === id) {
                f.id_project = e.value
            }
            return f
        }))
    }

    const changeFlow = ({ e, id }) => {
        Controllers.comment.feed_group_update({
            p_id_group: e.value,
            p_id: id
        })

        setRows(rows.map(f => {
            if (f.id === id) {
                f.id_group = e.value
            }
            return f
        }))
    }

    return <>
        <Card className="mb-4">
            <Card.Header className="justify-content-between">
                <span>Feed Historial</span>
            </Card.Header>
        </Card>
        <div className="form-row">
            <ReactPlaceholder ready={ready} showLoadingAnimation rows={5}>
                {rows.length > 0 ? <>
                    <div className="col-12 col-md-6">
                        {React.Children.toArray(rows.slice(0, rowsSliceNumber).map(f => <Feed isHistory feed={f} changeResponse={changeResponse} projects={projects} groups={groups} changeProject={changeProject} changeFlow={changeFlow} /> ))}
                    </div>
                    <div className="col-12 col-md-6">
                        {React.Children.toArray(rows.slice(rowsSliceNumber, rows.length).map(f => <Feed isHistory feed={f} changeResponse={changeResponse} projects={projects} groups={groups} changeProject={changeProject} changeFlow={changeFlow} /> ))}
                    </div>
                </> : <div className="col-12">
                    <div className="alert alert-warning">
                        No hay feed
                    </div>
                </div>}
            </ReactPlaceholder>
        </div>
    </>
}

export default FeedHistory