import React, { useEffect, useState } from 'react'
import { Card, Row } from 'react-bootstrap'
import { toast } from "react-toastify";

import Controllers from '../../Api/Controllers'
import AgentSelection from '../../Components/common/AgentSelection'

import FormCustom from '../../Components/Form/FormCustom'
import Select from '../../Components/Form/Select'
import Helpers from '../../Components/Helpers/Helpers';
import useWindowSize from '../../hooks/useWindowSize'

export default function AgentSupervisor() {
  const [agents, setAgents] = useState([])
  const [loadSubmit, setLoadSubmit] = useState(false)
  const [loadAgent, setLoadAgent] = useState(false)

  const size = useWindowSize()

  const [form, setForm] = useState({
    id_customers_contact: undefined,
    name_contact: undefined,
    agents: [],
  })

  useEffect(() => {
    setLoadAgent(true)
    Controllers.customers_contact.get_customers_contact()
      .then(res => setAgents(res.data))
      .finally(() => setLoadAgent(false))
  }, [])
  
  const onSubmit = ({data}) => {
    setLoadSubmit(true)
    const form = {
      p_id_customers_contact: data.id_customers_contact,
      p_name_contact: data.name_contact,
      p_agents: data.agents,
    }
    Controllers.chat_agent.chat_agent_insert_update(form)
      .then(res => {
          Helpers.toast.construct({ ...res, toast });
      })
      .finally(() => setLoadSubmit(false))
  }

  const value = () => form?.id_customers_contact ? ({
    value: form.id_customers_contact,
    label: form.name_contact
  }) : '';

  const onChangeAgent = ({value, label}) => {
    setLoadAgent(true)
    Controllers.chat_agent.get_chat_agent({
      p_id_customers_contact: value
    })
      .then(result => {
        if (result.data.length > 0) {
          setForm(result.data[0])
        } else {
          setForm({
            id_customers_contact: value,
            name_contact: label,
            agents: []
          })
        }
      })
      .finally(() => {
        setLoadAgent(false)
      })
  }

  const addAgent = item => {
    const exit = form.agents.filter(ag => ag.id_customers_contact === item.id).length > 0;
    if (!exit) {
      setForm({
        ...form,
        agents: [
          ...form.agents,
          {
            id_customers_contact: item.id,
            name_contact: item.name,
          }
        ]
      })
    }
  }

  const deleteAgent = (id) => {
    setForm({
      ...form,
      agents: form.agents.filter(a => a.id_customers_contact !== id),
    })
  }

  return (
    <Card className={`p-4 ${size.width > 700 ? "col-6" : size.width < 500 ? 'col-12' : 'col-8'}`}>
      <FormCustom
        loadSubmit={loadSubmit}
        onSubmit={onSubmit}
        dataSubmit={form}
      >
        <Select
          invalid="El campo es obligatorio."
          value={value()}
          isLoading={loadAgent}
          text="Seleccione agente"
          classNameParent="w-100 mb-2"
          onChange={onChangeAgent}
          options={agents?.length > 0 ? agents?.map(a => ({value: a.id_customers_contact, label: a?.name_contact})) : []}
        />
        <Card.Header className="justify-content-beetwen pl-2 pb-0 h-25 pt-1">
          <span>Seleccionar agente</span>
        </Card.Header>
        <Row className="mx-0 col-12 mb-2" >
          <AgentSelection
            invalid="El campo es obligatorio."
            agents={form?.agents?.length
              ? form.agents.map(item => ({
                  name: item.name_contact,
                  id: item.id_customers_contact
                }))
              : []
            }
            onClickAdd={addAgent}
            deleteAgent={deleteAgent}
          />
        </Row>
      </FormCustom>
    </Card>
  )
}
