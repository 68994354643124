import React, { useEffect, useMemo, useState } from 'react'
import Card from 'react-bootstrap/Card'
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap'
import Controllers from '../../Api/Controllers'
import Helpers from '../../Components/Helpers/Helpers'
import { FormCustom, Input } from '../../Components/Form/Form'
import Button from 'react-bootstrap/esm/Button'

const AlertCrm = _ => {
    const [rows, setRows] = useState([])
    const [isProcessing, setIsProcessing] = useState(true)
    const [filterDates, setFilterDates] = useState({
        p_date_start: Helpers.date.get({ format: '%Y-%m-%d' }),
        p_date_end: Helpers.date.get({ format: '%Y-%m-%d' })
    })

    useEffect(() => {
        if (isProcessing) {
            Controllers.alert.get_alert_crm(filterDates).then(res => {
                setRows(res.data)
                setIsProcessing(false)
            })
        }
    }, [isProcessing, filterDates])

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'name_customers', text: 'Cliente', align: 'center' },
                { name: 'id_lead', text: 'Id', align: 'center' },
                { name: 'name_lead', text: 'Lead', align: 'center' },
                { name: 'phone_lead', text: 'Telefono', align: 'center' },
                { name: 'email_lead', text: 'Email', align: 'center' },
                { name: 'date_created', text: 'Fecha', align: 'center' },
                { name: 'save_on_bd', text: 'Guardado en BD', align: 'center', render: r => <div className={'badge badge-' + (parseInt(r.save_on_bd) === 1 ? 'success' : 'danger')}>
                    {parseInt(r.save_on_bd) === 1 ? 'SI' : 'NO'}
                </div> },
                { name: 'send_crm', text: 'Enviado a CRM', align: 'center', render: r => <div className={'badge badge-' + (parseInt(r.send_crm) === 1 ? 'success' : 'danger')}>
                    {parseInt(r.send_crm) === 1 ? 'SI' : 'NO'}
                </div> },
                { name: 'other_error', text: 'Otro Error', align: 'center', render: r => <div className={'badge badge-' + (parseInt(r.other_error) === 1 ? 'success' : 'danger')}>
                    {parseInt(r.other_error) === 1 ? 'SI' : 'NO'}
                </div> },
                { name: 'message_error', text: 'Observación', align: 'center' },
            ],
        ]}
        rows={rows}
        isProcessing={isProcessing}
    />, [rows, isProcessing])
    
    return <>
    <Card className="mb-4">
        <Card.Header>
            <span>Listado de Leads con Incidencia</span>
        </Card.Header>
        <Card.Body>
            <FormCustom onSubmit={_ => setIsProcessing(true)} viewSubmit={false}>
                <Input
                    text="Fecha Inicio"
                    type="date"
                    value={filterDates.p_date_start}
                    onChange={e => setFilterDates({ ...filterDates, p_date_start: e.currentTarget.value })}
                    classNameParent="col-6"
                />
                <Input
                    text="Fecha Fin"
                    type="date"
                    value={filterDates.p_date_end}
                    onChange={e => setFilterDates({ ...filterDates, p_date_end: e.currentTarget.value })}
                    classNameParent="col-6"
                    append={[
                        <Button type="submit">
                            <i className="fa fa-search"></i>
                        </Button>
                    ]}
                />
            </FormCustom>
        </Card.Body>
    </Card>
    <Card>
        <Card.Body>
            {dtRows}
        </Card.Body>
    </Card>
    </>
}

export default AlertCrm
