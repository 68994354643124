import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import { toast } from "react-toastify";
//
import { codeCountries } from "../../db/code-phone";
import Controllers from "../../Api/Controllers";
import Helpers from "../Helpers/Helpers";
//
import useGetTemplates from "../../Views/templates/hooks/use-get-templates";
import { FormCustom, Select, Input } from "../Form/Form";
import SwitchTemplates from "../buttons/SwitchTemplates";

export const RenderModalImage = (props) => {
  const { enabled, onClose, imageUrl } = props;

  return (
    <Modal show={enabled} onHide={onClose} backdrop="static">
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
          // margin: "10px",
        }}
      >
        <button className="btn-delete" onClick={onClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </button>
        <img
          style={{
            width: "7wh",
            height: "70vh",
            margin: "auto",
            objectFit: "contain",
          }}
          src={imageUrl}
          alt="modal"
        />
      </Modal.Body>
    </Modal>
  );
};

export default function NewMessage(props) {
  const [loading, setLoading] = useState(false);
  const [viewImage, setViewImage] = useState({
    enabled: false,
    imageUrl: "",
  });
  const [templates, setTemplates] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [templateEdit, setTemplateEdit] = useState({
    value: "",
    label: "",
  });
  const [number, setNumber] = useState("");
  const [code, setCode] = useState(51);
  const [btnActive, setBtnActive] = useState("1");

  const { enabled, onClose, update } = props;

  const {data: listTemplates} = useGetTemplates({
    templateType: btnActive === '1' ? 'all' : 'rich_text_params',
    arrayMap: true,
  })

  const resetForm = () => {
    setTemplates([
      {
        value: "",
        label: "",
      },
    ]);
    setTemplateEdit({
      value: "",
      label: "",
    });
    setNumber("");
    setCode(51);
  };

  useEffect(() => {
    resetForm();
  }, [enabled]);

  const sendTemplate = async (template, p_params) => {
    let data = {
      p_number: `+${code}${number}`.replace(/\s/g, ""),
      p_id_plantilla: template.value,
    };

    if (p_params) {
      data.p_params = p_params;
    }
    return await Controllers.chat.whatsapp_new_message(data);
  };

  const handleChangeTemplate = (template) => {
    setTemplateEdit(template);
    const element = document.getElementById("template-edit");
    if (element) {
      let message = template.label.replaceAll(
        "{{",
        "<div contenteditable class='template-div' >"
      );
      message = message.replaceAll("}}", "</div>");
      element.innerHTML = message;
    }
  };

  const handleSubmit = async (e) => {
    try {
      if (!number) {
        Helpers.toast.construct({
          response: "warning",
          message: "Ingresar Número",
          toast,
        });
        return;
      } else {
        setLoading(true);
        await templates.reduce(async (previousPromise, template) => {
          await previousPromise;
          await sendTemplate(template);
          return Promise.resolve();
        }, Promise.resolve());
        update();
        resetForm();
        setLoading(false);
        onClose();
      }
    } catch (error) {
      Helpers.toast.construct({
        response: "success",
        message: "Mensaje enviado",
        toast,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitEdit = async () => {
    try {
      if (!number) {
        Helpers.toast.construct({
          response: "warning",
          message: "Ingresar Número",
          toast,
        });
        return;
      } else if (templateEdit.value === "") {
        Helpers.toast.construct({
          response: "warning",
          message: "Seleccionar y editar plantilla",
          toast,
        });
        return;
      } else {
        setLoading(true);
        const params = document.querySelectorAll(".template-div");
        const p_params = Array.from(params).map((item) => ({
          data: item.innerText,
        }));
        await sendTemplate(templateEdit, p_params);
        update();
        resetForm();
        setLoading(false);
        onClose();
      }
    } catch (error) {
      Helpers.toast.construct({
        response: "success",
        message: "Mensaje enviado",
        toast,
      });
    } finally {
      setLoading(false);
    }
  };

  const codePhoneCountries = useCallback(() => {
    const rows = codeCountries.map((item) => ({label: `${item.label} (+${item.phone})`, value: parseFloat(item.phone)}))
    return rows.sort((a, b) => a.label - b.label)
  }, [])


  return (
    <Modal show={enabled} onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title as="div">Nuevo Mensaje</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RenderModalImage
          {...viewImage}
          onClose={() => {
            setViewImage({
              enabled: false,
              imageUrl: "",
            });
          }}
        />
        <FormCustom
          loadSubmit={loading}
          onSubmit={() =>
            btnActive === "2" ? handleSubmitEdit() : handleSubmit()
          }
          textButton="Enviar mensaje"
          // hiddenBtn={}
        >
          <Select
            options={codePhoneCountries()}
            onChange={({ value }) => setCode(value)}
            value={codePhoneCountries().find((item) => item.value === code)}
            classNameParent="col-4 mb-2"
            text="País"
          />
          <Input
            classNameParent="col-8 mb-2"
            type="text"
            icon="fa fa-phone"
            placeholder="Número"
            text="Número"
            value={number}
            onChange={(e) => setNumber(e.currentTarget.value)}
          />
          {/* {selectTemplateEdit().length > 0 && ( */}
            <SwitchTemplates
              value={btnActive}
              onChange={async (v) => {
                setBtnActive(v);
                setTemplates([
                  {
                    value: "",
                    label: "",
                  },
                ]);
                setTemplateEdit({
                  value: "",
                  label: "",
                });
              }}
            />
          {/* )} */}
          <div
            className="col-12"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {btnActive === "1" ? (
              <>
                {templates.map((template, i) => (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    key={i}
                  >
                    {template.imagen ? (
                      <img
                        onClick={() => {
                          setViewImage({
                            enabled: true,
                            imageUrl: template.imagen,
                          });
                        }}
                        style={{
                          width: 60,
                          height: 60,
                          cursor: "pointer",
                          objectFit: "contain",
                        }}
                        src={template.imagen}
                        alt={template.value}
                      />
                    ) : template.files ? (
                      <strong>
                        <a
                          href={template.files}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            cursor: "pointer",
                            padding: 10,
                            margin: 5,
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-filetype-pdf"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                            />
                          </svg>
                        </a>
                      </strong>
                    ) : null}
                    <Select
                      options={listTemplates}
                      classNameParent={`${
                        template.imagen || template.files
                          ? i >= 1
                            ? "col-9"
                            : "col-10"
                          : i >= 1
                          ? "col-11"
                          : "col-12"
                      } mb-2 px-0`}
                      text={`Plantilla ${i + 1}`}
                      onChange={(value) => {
                        const newTemplates = templates.map((template, ii) =>
                          i === ii ? value : template
                        );
                        setTemplates(newTemplates);
                      }}
                      value={template}
                    />
                    {i >= 1 ? (
                      <Button
                        style={{
                          borderRadius: 5,
                          padding: 0,
                          width: 30,
                          height: 30,
                          margin: "auto 7px 7px",
                        }}
                        onClick={() =>
                          setTemplates(
                            templates.filter((_, index) => index !== i)
                          )
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-x"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </Button>
                    ) : null}
                  </div>
                ))}
                {listTemplates.length > 1 && (
                  <Button
                    style={{
                      borderRadius: 5,
                      padding: "3px 7px",
                      margin: "10px auto",
                      fontSize: 12,
                    }}
                    onClick={() =>
                      setTemplates([
                        ...templates,
                        {
                          value: "",
                          label: "",
                        },
                      ])
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    <span>Agregar plantilla</span>
                  </Button>
                )}
              </>
            ) : (
              <div style={{ display: "grid" }}>
                <Select
                  // options={selectTemplateEdit()}
                  options={listTemplates}
                  classNameParent="col-12 mb-2 px-0"
                  text={`Seleccionar Plantilla para editar`}
                  onChange={(value) => {
                    handleChangeTemplate(value);
                  }}
                  value={templateEdit}
                  defaultValue={templateEdit}
                />
                <div
                  id="template-edit"
                  className={
                    templateEdit.value !== "" ? "contain-template" : ""
                  }
                ></div>
              </div>
            )}
          </div>
        </FormCustom>
      </Modal.Body>
    </Modal>
  );
}
