import React, { useState } from 'react'
import { Input, FormCustom, SwitchToggle } from '../../Components/Form/Form'

const FootageForm = ({ data, onSubmit, disablebSubmit, loadSubmit, idCustomers, idProject }) => {
    const [row, setRow] = useState({
        p_id_footage: data.id_footage,
        p_title_footage: data.title_footage,
        p_description_footage: data.description_footage,
        p_enabled_footage: data.enabled_footage,
        p_image_footage: data.image_footage,
        p_id_project: idProject,
        p_id_customers: idCustomers
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Titulo"
            placeholder="Titulo"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.title_footage}
            onChange={e => setRow({ ...row, p_title_footage: e.currentTarget.value })}
            maxLength={255}
        />
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.description_footage}
            onChange={e => setRow({ ...row, p_description_footage: e.currentTarget.value })}
            maxLength={255}
        />
        <Input
            text="Image URL"
            placeholder="Image URL"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.image_footage}
            onChange={e => setRow({ ...row, p_image_footage: e.currentTarget.value })}
            maxLength={255}
        />
        <SwitchToggle
            id="enabled-footage"
            defaultChecked={parseInt(data.enabled_footage) === 1}
            onChange={e => setRow({ ...row, p_enabled_footage: e.currentTarget.checked ? 1 : 0 })}
        />
    </FormCustom>
}

FootageForm.defaultProps = {
    data: {
        id_footage: '',
        title_footage: '',
        description_footage: '',
        image_footage: '',
        enabled_footage: '0',
        id_project: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default FootageForm;