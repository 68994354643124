import React, { useState, useCallback } from 'react'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'

const Radio = ({ defaultChecked, checked, text, onChange, classNameParent, ...props }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked)

  const propChecked = useCallback(() => {
    return defaultChecked === false || defaultChecked === true ? { defaultChecked: isChecked } : { checked }
  }, [defaultChecked, checked, isChecked])

  const handleChangeChecked = e => {
    onChange(e)
    if (defaultChecked === true || defaultChecked === false) {
      setIsChecked(!isChecked)
    }
  }

  return <div className={classNameParent}>
    <Form.Check
      {...props}
      type="radio"
      custom
      label={text}
      onChange={handleChangeChecked}
      {...propChecked()}
    />
  </div>
}
Radio.defaultProps = {
  disabled: false,
  classNameParent: 'col-12',
  onChange: () => {},
  required: false,
  text: ''
}
Radio.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  classNameParent: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  required: PropTypes.bool,
  text: PropTypes.string
}
export default Radio