import React, { useContext } from 'react'
import { StateContext } from '../context/StateContext';

const http = process.env.REACT_APP_API_URL_CLIENT;

export default function useNotification() {
  const {soundActive} = useContext(StateContext)

  const executeNotification = ({ title, message }) => {
    let notification = new Notification(title, {
      body: message,
      icon: `/favicon.ico`,
    });

    notification.onclick = (e) => {
      e.preventDefault();
      if (window.location.pathname !== "/multiagente") {
        window.history.push("/home");
      }
      notification.close();
    };

    if (soundActive) {
      let myAudio = new Audio(`${http}/assets/audios/multiagente.mp3`);
      myAudio.play();
    }

    setTimeout(() => {
      notification.close();
    }, 3000);
  };

  const permissionNotification = ({ title, message }) => {
    if (!("Notification" in window)) {
    } else if (Notification.permission === "granted") {
      executeNotification({ title, message });
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          executeNotification({ title, message });
        }
      });
    }
  };

  return {
    permissionNotification,
  }
}