import React, { useState } from 'react'
import { useEffect } from 'react'
import Controllers from '../../Api/Controllers'
import { Input, SwitchToggleChecked, FormCustom, Select, text } from '../../Components/Form/Form'
import ReactPlaceholder from 'react-placeholder'
import { useSelector } from 'react-redux'
import Card from 'react-bootstrap/esm/Card'
import Button from 'react-bootstrap/esm/Button'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify';

const TagForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_tag: data.id_tag,
        p_description_tag: data.description_tag,
        p_enabled_tag: data.enabled_tag,
        p_show_in_report_tag: data.show_in_report_tag,
        p_det: data.det
    })

    const masterUser = useSelector(store => store.session.master_user)
    const [ready, setReady] = useState(true)

    return <ReactPlaceholder rows={15} showLoadingAnimation ready={ready}>
        <FormCustom dataSubmit={row} onSubmit={_ => {
            if (row.p_det.length === 0) {
                Helpers.toast.construct({
                    response: 'warning',
                    message: 'Agregar sub etiquetas',
                    toast
                })
                return false
            }
            onSubmit({ data: row })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Descripción"
                placeholder="Descripción"
                classNameParent="col-12 mb-2"
                icon="far fa-user"
                required
                invalid="El campo es obligatorio."
                value={row.p_description_tag}
                onChange={e => setRow({
                    ...row,
                    p_description_tag: e.currentTarget.value
                })}
                maxLength={255}
            />
            <SwitchToggleChecked
                id={'enabled-tag'}
                classNameParent="col-12 mb-2"
                invalid="El campo es obligatorio."
                checked={parseInt(row.p_enabled_tag) === 1}
                onChange={e => setRow({
                    ...row,
                    p_enabled_tag: e.currentTarget.checked ? 1 : 0
                })}
            />
            <SwitchToggleChecked
                id={'p_show_in_report_tag'}
                classNameParent="col-12 mb-2"
                invalid="El campo es obligatorio."
                checked={parseInt(row.p_show_in_report_tag) === 1}
                onChange={e => setRow({
                    ...row,
                    p_show_in_report_tag: e.currentTarget.checked ? 1 : 0
                })}
                textUnChecked="Oculto en Reporte"
                textCheck="Mostrar en Reporte"
            />

            <div className="d-flex justify-content-between col-12 mt-4">
                <div className='align-self-center'>
                    <strong>Listado de Opciones</strong>
                </div>
                <Button size='sm' onClick={_ => {
                    setRow({
                        ...row,
                        p_det: [
                            ...row.p_det,
                            {
                                id_tag_det: '',
                                description_tag_det: '',
                                enabled_tag_det: 0
                            }
                        ]
                    })
                }}>
                    <i className="fa fa-plus mr-2"></i> Agregar
                </Button>
            </div>

            {row.p_det.length === 0 && <div className='mt-2 mb-2 d-flex justify-content-center w-100'>
                <div>No hay items en esta lista</div>
            </div>}
            {React.Children.toArray(row.p_det.map((r, i) => {
                return <div className='col-12 mt-4 mb-2'>
                    <div style={{
                        border: '1px solid rgb(217 217 217)',
                        borderRadius: 5
                    }} className="p-2 position-relative">
                        {!r.id_tag_det && <div style={{
                            position: 'absolute',
                            top: 0, right: 0,
                            padding: 5,
                            cursor: 'pointer'
                        }} onClick={_ => {
                            setRow({
                                ...row,
                                p_det: row.p_det.filter((r2, i2) => {
                                    return i !== i2
                                })
                            })
                        }}>
                            <i className='fa fa-times text-danger'></i>
                        </div>}
                        <Input
                            text="Descripción"
                            placeholder="Descripción"
                            classNameParent="mb-2"
                            icon="far fa-user"
                            required
                            invalid="El campo es obligatorio."
                            value={r.description_tag_det}
                            onChange={e => setRow({
                                ...row,
                                p_det: row.p_det.map((r2, i2) => {
                                    if (i === i2) {
                                        r2.description_tag_det = e.currentTarget.value
                                    }
                                    return r2
                                })
                            })}
                            maxLength={255}
                        />
                        <SwitchToggleChecked
                            id={'enabled-tag-det-' + i}
                            classNameParent=""
                            invalid="El campo es obligatorio."
                            checked={parseInt(r.enabled_tag_det) === 1}
                            onChange={e => setRow({
                                ...row,
                                p_det: row.p_det.map((r2, i2) => {
                                    if (i === i2) {
                                        r2.enabled_tag_det = e.currentTarget.checked ? 1 : 0
                                    }
                                    return r2
                                })
                            })}
                        />
                    </div>
                </div>
            }))}
        </FormCustom>
    </ReactPlaceholder>
}

TagForm.defaultProps = {
    data: {
        id_tag: '',
        description_tag: '',
        enabled_tag: 0,
        show_in_report_tag: 0,
        det: []
    },
    onSubmit: () => { },
    loadSubmit: false
}

export default TagForm;