/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";

//
import useGetTemplates from "../hooks/use-get-templates";
//
import ReactDataTableBootstrap from "../../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap";
import TemplateEditAddForm from "../modals/template-edit-add-form";

const stateDefault = {
  p_template_name: "",
  p_name_space: "",
  p_message_text: "",
  p_template_type: "default",
  p_qty_params: "",
  p_template_image: "",
  p_template_pdf: "",
  p_id_template: "",
  url_image: "",
  url_file: "",
};

export default function TemplateListView() {
  const [currentTemplate, setCurrentTemplate] = useState(stateDefault);

  const [open, setOpen] = useState(false);

  const [templateIdDelete, setTemplateIdDelete] = useState("");

  const { data, isLoading, deleteTemplate, updateTemplates } =
    useGetTemplates();

  const HEAD_TABLE = [
    { name: "id_template", text: "Id", align: "center" },
    { name: "type", text: "Tipo de plantilla" },
    { name: "template_name", text: "Nombres de plantilla" },
    { name: "namespace", text: "Namespace" },
    { name: "name", text: "Mensaje" },
    {
      name: "actions",
      text: <i className="fa fa-cog"></i>,
      align: "center",
      render: (template) => (
        <div>
          <Button
            variant="info"
            className="mr-1"
            size="xs"
            onClick={() => {
              setOpen(true);
              debugger;
              setCurrentTemplate({
                p_id_template: template.id_template,
                p_template_name: template.template_name,
                p_name_space: template.namespace,
                p_qty_params: `${template.qty_params || ""}`,
                p_message_text: template.name,
                p_template_type: template.type,
                p_template_image: "",
                p_template_pdf: "",
                url_image: template.image,
                url_file: template.pdf,
              });
            }}
          >
            <i className="fa fa-edit"></i> Editar
          </Button>
          <Button
            variant="danger"
            size="xs"
            onClick={() => setTemplateIdDelete(template.id_template)}
          >
            <i className="fa fa-trash"></i> Eliminar
          </Button>
        </div>
      ),
    },
  ];

  const onClose = () => {
    updateTemplates();
    setCurrentTemplate(stateDefault);
    setOpen(false);
  };

  return (
    <>
      <Card>
        <Card.Header className="justify-content-between">
          <span>Lista plantillas</span>

          <Button size="sm" variant="success" onClick={(_) => setOpen(true)}>
            Crear plantilla
          </Button>
        </Card.Header>
        <Card.Body>
          <ReactDataTableBootstrap
            head={[HEAD_TABLE]}
            orderDirection="desc"
            rows={data}
            isProcessing={isLoading}
          />
        </Card.Body>
      </Card>

      <TemplateEditAddForm
        currentTemplate={currentTemplate}
        onClose={onClose}
        open={open}
      />

      {/* // ? alert delete template */}
      {!!templateIdDelete && (
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={!!templateIdDelete}
          onHide={() => setTemplateIdDelete("")}
        >
          <Modal.Header closeButton>
            <Modal.Title>Eliminar plantilla</Modal.Title>
          </Modal.Header>
          <Modal.Body>Está seguro que desea eliminar esta plantilla</Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              class="btn btn-danger"
              onClick={() => {
                deleteTemplate(templateIdDelete);
                setTemplateIdDelete("");
              }}
            >
              Eliminar
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                setTemplateIdDelete("");
              }}
            >
              Cerrar
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
