import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';

import Input from '../Form/Input'
import ButtonArrow from './ButtonArrow';

export default function FlatListSearch(props) {
  const [search, setSearch] = useState('');
  const [list, setList] = useState([]);

  const { rows, fields, onClick, subText, arrow, select } = props

  useEffect(() => {
    const filterList = rows.filter(item => {
      let text;
      fields.forEach(element => {
        text = `${text} ${item[element]}`
      });
      if (text.toLowerCase().includes(search.toLowerCase())) {
        return item
      }
    })
    setList(filterList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, rows.length, rows])

  return (
    <div className="flat-list">
      <div className="p-2 d-flex">
        {arrow && <ButtonArrow classNameParent="col-1" />}
        <Input
          classNameParent="col-11"
          type="text"
          placeholder="Buscar..."
          onChange={e => setSearch(e.currentTarget.value)}
        />
      </div>
      <div className="pt-2- d-flex flex-column" style={{ overflow: 'auto', maxHeight: '72.5vh'}}>
        {list?.length > 0 && list.map((item, key) => (
          <Card
            key={ key }
            style={{borderRadius: 0, borderBottom: '1px solid #eeeeee'}}
            className={`p-3 col-example text-left button-flat-list ${select === item[fields[0]] ? 'active' : ''}`}
            onClick={ () => onClick(item) }>
            <span style={{
              fontWeight: '600',
              color: '#8b8b8b',
              fontSize: 13
            }}>
              {item[fields[0]]}
            </span>
            {subText && (
              <span style={{
                color: '#b6b6b6',
                fontSize: 12
              }}>
                {item[subText]}
              </span>
            )}
          </Card>
        ))}
      </div>
    </div>
  )
}
