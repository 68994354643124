import styled from 'styled-components';

const Box = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  
  background-image: url(${props => props.backgroundImage});
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  width: 100%;
`

Box.Container = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  max-width: 500px;
`

Box.Content = styled.div`
  padding: 20px 10px
`

Box.Logo = styled.img`
  max-width: 130px;
`

export default Box