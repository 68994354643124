import React from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from "react-redux";
import ReactPlaceholder from 'react-placeholder/lib'
import Helpers from '../Helpers/Helpers'

export default function PercentageCard(props) {
  const store = useSelector((store) => store);

  const { item, ready } = props;

  const hasPermission = (page) => {
    let permissions = store.session.permissions;

    let has = false;

    permissions?.forEach((tpm) => {
      if (has) {
        return false;
      }

      tpm?.childrens?.forEach((tp) => {
        if (has) {
          return false;
        }

        tp?.childrens?.forEach((tp) => {
          if (has) {
            return false;
          }

          if (tp?.a_href === page) {
            has = true;
          }
        });
      });
    });

    return has;
  };

  const socialNetwork = item?.title?.toLowerCase() || ''

  const url = hasPermission(`leads-${socialNetwork}-servicios`) ? `leads-${socialNetwork}-servicios` : `leads-${socialNetwork}`

  return (
    <div className="col-12 col-md-4 mb-3">
      <Card>
        <Card.Body className="pl-5 pr-5">
          <ReactPlaceholder ready={ready} rows={3} showLoadingAnimation>
            <a
              className="d-flex justify-content-between text-decoration-none"
              href={ Helpers.config.convertUrl({ url }) }
            >
              <div className="d-flex align-self-center" style={{color: item.color}}>
                <i className={`fab fa-${socialNetwork} mr-2 fa-2x align-self-center`}></i>
                <strong className="align-self-center">{item?.title || ''}</strong>
              </div>
              <div style={{ fontSize: 30 }}>
                |
              </div>
              <div className="align-self-center">
                {item?.qty || 0} - {item?.percentage || '0.00%'}
              </div>
            </a>
          </ReactPlaceholder>
        </Card.Body>
      </Card>
    </div>
  )
}
