/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
//
import useGetTemplates from "../hooks/use-get-templates";
//
import FormCustom from "../../../Components/Form/FormCustom";
import Helpers from "../../../Components/Helpers/Helpers";
import TextArea from "../../../Components/Form/TextArea";
import Select from "../../../Components/Form/Select";
import Input from "../../../Components/Form/Input";
import { RenderModalImage } from "../../../Components/modals/NewMessage";

const stateDefault = {
  p_template_name: "",
  p_name_space: "",
  p_message_text: "",
  p_template_type: "default",
  p_qty_params: "",
  p_template_image: "",
  p_template_pdf: "",
  p_id_template: "",
  url_image: "",
  url_file: "",
};

export default function TemplateEditAddForm({
  onClose,
  open,
  currentTemplate,
}) {
  const [previewImg, setPreviewImg] = useState("");
  const [messageWarning, setMessageWarning] = useState("");
  const [viewImage, setViewImage] = useState({
    enabled: false,
    imageUrl: "",
  });

  const [createForm, setCreateForm] = useState(stateDefault);

  const types = useMemo(
    () => [
      {
        label: "Default",
        value: "default",
      },
      {
        label: "Imagen",
        value: "rich_text_image",
      },
      {
        label: "PDF",
        value: "rich_text_pdf",
      },
      {
        label: "Parámetros",
        value: "rich_text_params",
      },
    ],
    []
  );

  const { isLoading, postTemplate } = useGetTemplates();

  const editTemplate = useCallback(
    () => !!currentTemplate.p_id_template,
    [currentTemplate.p_id_template]
  );

  const handleClose = useCallback(() => {
    setViewImage({
      enabled: false,
      imageUrl: "",
    });
    setCreateForm(stateDefault);
    setMessageWarning("");
    setPreviewImg("");
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (editTemplate() && open) {
      setMessageWarning("");
      setPreviewImg("");
      setCreateForm({
        ...currentTemplate,
        p_template_type: currentTemplate.p_template_type || "default",
      });
    } else if (currentTemplate.p_template_name && !open) {
      setMessageWarning("");
      setPreviewImg("");
      setCreateForm(stateDefault);
    }
  }, [currentTemplate, editTemplate, open]);

  const handleOnChange = useCallback(
    (name, value) => setCreateForm({ ...createForm, [name]: value }),
    [createForm]
  );

  const handleOnChangeUpload = useCallback(
    (event) => {
      const files = event.target.files;
      const size = event.target.files[0].size;
      if (size > 5000000) {
        setMessageWarning("La imagen debe de tener un peso máximo de 5 MB");
        setPreviewImg("");
        handleOnChange("p_template_image", "");
      } else {
        setPreviewImg(URL.createObjectURL(files[0]));
        setMessageWarning("");
        handleOnChange("p_template_image", files);
      }
    },
    [handleOnChange]
  );

  const handleSubmit = useCallback(() => {
    const formData = new FormData();
    if (
      !createForm.p_template_name ||
      !createForm.p_name_space ||
      !createForm.p_message_text ||
      !createForm.p_template_type ||
      !createForm.p_template_name
    ) {
      Helpers.toast.construct({
        response: "warning",
        message: "Campos requerido",
        toast,
      });
      return;
    }
    formData.append("p_template_name", createForm.p_template_name);
    formData.append("p_name_space", createForm.p_name_space);
    formData.append("p_message_text", createForm.p_message_text);
    formData.append("p_template_type", createForm.p_template_type);

    if (createForm.p_template_type === "rich_text_params") {
      formData.append("p_qty_params", createForm.p_qty_params);
    }
    if (createForm.p_template_type === "rich_text_pdf") {
      if (createForm.p_template_pdf) {
        formData.append("p_template_pdf", createForm.p_template_pdf[0]);
      }
    }
    if (createForm.p_template_type === "rich_text_image") {
      if (createForm.p_template_image) {
        formData.append("p_template_image", createForm.p_template_image[0]);
      }
    }

    if (createForm.p_id_template) {
      formData.append("p_id_template", createForm.p_id_template);
    }

    postTemplate(formData, handleClose, editTemplate());
  }, [createForm, postTemplate, editTemplate, handleClose]);

  return (
    <>
      <Modal
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={open}
        onHide={() => {
          setCreateForm(stateDefault);
          onClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Crear plantilla</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!messageWarning && (
            <div
              class="alert"
              style={{
                color: "#856404",
                backgroundColor: "#fff3cd",
                borderColor: "#ffeeba",
              }}
              role="alert"
            >
              {messageWarning}
            </div>
          )}
          <FormCustom
            loadSubmit={isLoading}
            onSubmit={handleSubmit}
            textButton={
              editTemplate() ? "Actualizar cambio" : "Crear plantilla"
            }
          >
            <Select
              options={types}
              disabled={isLoading}
              onChange={({ value }) => handleOnChange("p_template_type", value)}
              value={types.find(
                (item) => item.value === createForm.p_template_type
              )}
              classNameParent="col-12 mb-2"
              invalid="El campo es obligatorio."
              text="Tipo de plantilla"
            />
            <Input
              classNameParent="col-12 mb-2"
              type="text"
              disabled={isLoading}
              placeholder="Nombre de plantilla"
              text="Nombre de plantilla"
              value={createForm.p_template_name}
              invalid="El campo es obligatorio."
              onChange={(e) =>
                handleOnChange("p_template_name", e.currentTarget.value)
              }
            />
            <Input
              classNameParent="col-12 mb-2"
              type="text"
              placeholder="Namespace"
              invalid="El campo es obligatorio."
              text="Namespace"
              disabled={isLoading}
              value={createForm.p_name_space}
              onChange={(e) =>
                handleOnChange("p_name_space", e.currentTarget.value)
              }
            />
            {createForm.p_template_type === "rich_text_params" && (
              <Input
                classNameParent="col-12 mb-2"
                type="number"
                disabled={isLoading}
                placeholder="Cantidad de parámetros"
                invalid="El campo es obligatorio."
                text="Cantidad de parámetros"
                value={createForm.p_qty_params}
                onChange={(e) =>
                  handleOnChange("p_qty_params", e.currentTarget.value)
                }
              />
            )}
            <div
              className="col-12 mb-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {(createForm.url_image && !createForm.p_template_image) ||
              !!previewImg ? (
                <img
                  style={{
                    width: 45,
                    height: 45,
                    cursor: "pointer",
                    objectFit: "contain",
                  }}
                  onClick={() => {
                    setViewImage({
                      enabled: true,
                      imageUrl: createForm.url_image,
                    });
                  }}
                  src={createForm.url_image || previewImg}
                  alt="image preview"
                />
              ) : null}
              {createForm.p_template_type === "rich_text_image" && (
                <Input
                  classNameParent="col-10"
                  type="file"
                  disabled={isLoading}
                  accept=".png, .jpg"
                  placeholder="Carga imagen"
                  text="Carga imagen"
                  invalid="El campo es obligatorio."
                  onChange={handleOnChangeUpload}
                />
              )}
            </div>
            <div
              className="col-12 mb-2"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {createForm.url_file && !createForm.p_template_pdf ? (
                <strong>
                  <a
                    href={createForm.url_file}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      cursor: "pointer",
                      padding: 5,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      class="bi bi-filetype-pdf"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                      />
                    </svg>
                  </a>
                </strong>
              ) : null}
              {createForm.p_template_type === "rich_text_pdf" && (
                <Input
                  classNameParent="col-10"
                  type="file"
                  accept=".pdf"
                  placeholder="Cargar PDF"
                  text="Cargar PDF"
                  invalid="El campo es obligatorio."
                  onChange={(event) => {
                    const files = event.target.files;
                    const size = event.target.files[0].size;

                    if (size > 10000000) {
                      setMessageWarning(
                        "El PDF debe de tener un peso máximo de 10 MB"
                      );
                      setPreviewImg("");
                    } else {
                      setMessageWarning("");
                      handleOnChange("p_template_pdf", files);
                    }
                  }}
                />
              )}
            </div>
            <TextArea
              rows="7"
              text="Mensaje"
              placeholder="Mensaje"
              classNameParent="col-12 mb-2"
              invalid="El campo es obligatorio."
              defaultValue={createForm.p_message_text}
              onChange={(e) =>
                handleOnChange("p_message_text", e.currentTarget.value)
              }
            />
          </FormCustom>
        </Modal.Body>
      </Modal>

      <RenderModalImage
        {...viewImage}
        onClose={() => {
          setViewImage({
            enabled: false,
            imageUrl: "",
          });
        }}
      />
    </>
  );
}
