import React, { useState } from "react";
import {
  Input,
  FormCustom,
  SwitchToggle,
  TextArea,
} from "../../Components/Form/Form";

const CustomersForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
  const [row, setRow] = useState({
    p_id_customers: data.id_customers,
    p_name_customers: data.name_customers,
    p_key_customers: data.key_customers,
    p_enabled_customers: data.enabled_customers,
    p_fb_identifier_app: data.fb_identifier_app,
    p_fb_secret_app: data.fb_secret_app,
    p_fb_access_token: data.fb_access_token,
    p_fb_page_id: data.fb_page_id,
    p_token_freshchat: data.token_freshchat,
    p_actor_id_freshchat: data.actor_id_freshchat,
    p_phone: data.phone,
  });

  return (
    <FormCustom
      dataSubmit={row}
      onSubmit={onSubmit}
      disablebSubmit={disablebSubmit}
      loadSubmit={loadSubmit}
    >
      <Input
        text="Descripción"
        placeholder="Descripción"
        classNameParent="col-12 mb-2"
        icon="far fa-comment-alt"
        required
        invalid="El campo es obligatorio."
        defaultValue={data.name_customers}
        onChange={(e) =>
          setRow({ ...row, p_name_customers: e.currentTarget.value })
        }
        maxLength={150}
      />
      <Input
        text="Clave"
        placeholder="Clave"
        classNameParent="col-12 mb-2"
        icon="far fa-comment-alt"
        required
        invalid="El campo es obligatorio."
        defaultValue={data.key_customers}
        onChange={(e) =>
          setRow({ ...row, p_key_customers: e.currentTarget.value })
        }
        maxLength={100}
        pattern="[a-zA-Z0-9]{1,100}"
      />
      <Input
        text="Telefono"
        placeholder="Telefono"
        classNameParent="col-12 mb-2"
        icon="far fa-comment-alt"
        required
        invalid="El campo es obligatorio."
        defaultValue={data.phone}
        onChange={(e) => setRow({ ...row, p_phone: e.currentTarget.value })}
        maxLength={18}
      />
      <small className="text-muted col-12">Estado</small>
      <SwitchToggle
        id="enabled-customers"
        defaultChecked={parseInt(data.enabled_customers) === 1}
        onChange={(e) =>
          setRow({
            ...row,
            p_enabled_customers: e.currentTarget.checked ? 1 : 0,
          })
        }
      />
      <div className="col-12">
        <hr />
      </div>
      <small className="text-muted col-12">App Facebook</small>
      <Input
        text="Identificador APP"
        placeholder="Identificador APP"
        classNameParent="col-12 mb-2"
        icon="fab fa-facebook"
        defaultValue={data.fb_identifier_app}
        onChange={(e) =>
          setRow({ ...row, p_fb_identifier_app: e.currentTarget.value })
        }
        maxLength={150}
      />
      <Input
        text="Secret APP"
        placeholder="Secret APP"
        classNameParent="col-12 mb-2"
        icon="fab fa-facebook"
        defaultValue={data.fb_secret_app}
        onChange={(e) =>
          setRow({ ...row, p_fb_secret_app: e.currentTarget.value })
        }
        maxLength={150}
      />
      <TextArea
        rows={5}
        text="Access Token APP"
        placeholder="Access Token APP"
        classNameParent="col-12 mb-2"
        icon="fab fa-facebook"
        defaultValue={data.fb_access_token}
        onChange={(e) =>
          setRow({ ...row, p_fb_access_token: e.currentTarget.value })
        }
        maxLength={255}
      />
      <Input
        text="Fb Id"
        placeholder="Fb Id"
        classNameParent="col-12 mb-2"
        icon="fab fa-facebook"
        defaultValue={data.fb_page_id}
        onChange={(e) =>
          setRow({ ...row, p_fb_page_id: e.currentTarget.value })
        }
        maxLength={150}
      />
      <div className="col-12">
        <hr />
        <p>Configuración Whatsapp</p>
      </div>
      <TextArea
        rows={6}
        text="Token FreshChat"
        placeholder="Token FreshChat"
        classNameParent="col-12 mb-2"
        icon="fab fa-whatsapp"
        defaultValue={data.token_freshchat}
        onChange={(e) =>
          setRow({ ...row, p_token_freshchat: e.currentTarget.value })
        }
      />
      <Input
        text="Actor Id"
        placeholder="Actor Id"
        classNameParent="col-12 mb-2"
        icon="fab fa-whatsapp"
        defaultValue={data.actor_id_freshchat}
        onChange={(e) =>
          setRow({ ...row, p_actor_id_freshchat: e.currentTarget.value })
        }
        maxLength={150}
      />
    </FormCustom>
  );
};

CustomersForm.defaultProps = {
  data: {
    id_customers: "",
    name_customers: "",
    key_customers: "",
    enabled_customers: "0",
    fb_identifier_app: "",
    fb_secret_app: "",
    fb_access_token: "",
    fb_page_id: "",
    token_freshchat: "",
    actor_id_freshchat: "",
  },
  onSubmit: () => {},
  loadSubmit: false,
};

export default CustomersForm;
