const getDateStartOfMonth = () => {
  const fechaInicio = new Date();
  return new Date(fechaInicio.getFullYear(), fechaInicio.getMonth(), 1);
};

const getDateEndOfMonth = () => {
  const fechaFin = new Date();
  return new Date(fechaFin.getFullYear(), fechaFin.getMonth() + 1, 0);
};

export { getDateStartOfMonth, getDateEndOfMonth };
