/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const CommentHistory = ({ comments }) => {
  return (
    <>
      <div className="form-row">
        {comments.length > 0 ? (
          <>
            <div className="col-12">
              {React.Children.toArray(
                comments.map((f) => (
                  <div>
                    <div
                      style={{
                        borderRadius: 10,
                        backgroundColor: "#F0F2F5",
                      }}
                      className="d-flex justify-content-between p-2 mb-2"
                    >
                      <div className="d-flex flex-column">
                        <div>
                          <strong className="text-primary">
                            {f.from_name}
                          </strong>
                          <small className="text-muted">
                            {" "}
                            - {f.date_created}
                          </small>
                        </div>
                        <span>{f.message}</span>
                        {f.media ? (
                          <img src={f.media} className="img-fluid" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        <a
                          href={f.permalink_url}
                          className="align-self-start"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-link"></i>
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        borderRadius: 10,
                        backgroundColor: "#F0F2F5",
                      }}
                      className="d-flex flex-column p-2 ml-4 mb-4"
                    >
                      <strong>
                        {parseInt(f.is_cm) === 0
                          ? "Respuesta ChatBot"
                          : "Derivado al CM"}
                      </strong>
                      {parseInt(f.is_cm) === 0 ? (
                        <>
                          <span>{f.response_bot}</span>
                          {f.response_image_bot ? (
                            <img
                              src={f.response_image_bot}
                              className="img-thumbnail mt-2"
                              width="250"
                            />
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </>
        ) : (
          <div className="col-12">
            <div className="alert alert-warning">No hay comentarios</div>
          </div>
        )}
      </div>
    </>
  );
};

export default CommentHistory;
