/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useCallback } from "react";
import { StateContext } from "../context/StateContext";
import useWS from "./useWS";
import useNotification from "./useNotification";
import sleep from "../utils/sleep";

export default function useSocket() {
  const { permissionNotification } = useNotification();
  const { func } = useContext(StateContext);
  const ws = useWS();

  const enableSocket = useCallback(async (id_agent, force) => {
    // ? Inicializar el socket
    let socket = new WebSocket(ws);
    socket.onopen = function () {
      socket.send(
        JSON.stringify({
          command: "subscribe",
          channel: `CLIENTE_${id_agent}`,
        })
      );
    };

    // ? Queda escuchando los error
    socket.addEventListener("error", async (event) => {
      if (force) {
        await sleep(3000);
        return enableSocket(id_agent, force);
      } else {
        console.log(`¡Error! En Conectar con socket del agente ${id_agent}`);
        return null;
      }
    });
    if (socket) {
      window.onunload = function () {
        socket.close();
      };
      return socket;
    }
  }, []);

  const socketMessage = useCallback((socket) => {
    socket.onmessage = (e) => {
      func.update(true);
      let sock = JSON.parse(e.data);
      if (sock.type === "NEW_CHAT") {
        permissionNotification({
          type: "NEW_CHAT",
          title: sock?.data?.name_chat || sock?.data?.number_chat || "",
          message: sock.data.message,
        });
        func.dataChat(sock.data, "NEW_CHAT");
      } else if (sock.type === "MESSAGE") {
        func.dataChat(sock.data, "MESSAGE");
        permissionNotification({
          type: "MESSAGE",
          title: "Nuevo mensaje",
          message: sock.data.detail.message,
        });
      }
      setTimeout(() => {
        func.update(false);
      }, 500);
    };
  }, []);

  return {
    enableSocket,
    socketMessage,
  };
}
