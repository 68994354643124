const messageCacheDefault = {
  audio: null,
  date_created: "",
  date_message: "",
  from_user: 1,
  hexa_color: "#C2E7FF",
  hour_message: "",
  id_chat: 121762536215636136254521461231232432,
  is_message_intern: 0,
  timeCache: true,
  message: "",
  name_contact: null,
  path_file: null,
  photo: null,
  seen: 0,
  type: "INFORMATIVO",
};

const sanCharbelMsg = [
  {
    value: 0,
    label: "Hola se comunicó con San Charbel, ¿como lo puedo ayudar?",
  },
  {
    value: 1,
    label:
      "Hola buenas noches, hemos recibido tu mensaje de interés sobre nuestros proyectos inmobiliarios, Somos Charbel Edificaciones, hemos aperturado el canal chat nocturno, pensando en tu disposición de tiempo, mi horario de atención es de 7pm a 4am, cuéntame ¿cómo te puedo ayudar?",
  },
  {
    value: 2,
    label:
      "Hola 😊, como esta, llegó a revisar la información enviada ?.  Si tiene alguna duda estoy a su disposición.",
  },
  {
    value: 3,
    label: "Hola 😊, como esta, quería saber si llego revisar la cotización",
  },
  {
    value: 4,
    label:
      "Hola, como esta, quería saber si tendría alguna respuesta o si todavía tendría alguna consulta adicional.",
  },
  {
    value: 5,
    label: "¿Tendría alguna consulta?",
  },
  {
    value: 6,
    label: "¿Está todo claro?",
  },
  {
    value: 7,
    label: "Por favor me escribe para poder ayudarle con su consulta.",
  },
  {
    value: 8,
    label:
      "Hola, disculpe la molestía quería saber si llego a revisar lo que le envíe.",
  },
  {
    value: 9,
    label:
      "Quedo atenta a sus comentarios, el call nocturno atiende hasta las 3am 😊 🏠",
  },
];

const sanCharbelEdit = [
  {
    value: 10,
    label:
      "Estimado {{1}} te saluda {{2}} de San Charbel Edificaciones, vimos tu interés en nuestros proyectos.",
  },
  {
    value: 11,
    label: `Estimad@ {{1}} te saluda {{2}} de San Charbel Edificaciones vimos tu interés en nuestros proyectos. \nPor favor compártenos los siguientes datos para brindarte mayor información. \n✅ ¿es su primera vivienda? \n✅ ¿Entrega inmediata o en pre venta? \n✅ ¿Cuál es su presupuesto para la compra de un departamento? \n✅ ¿Cuantas habitaciones?`,
  },
];

// grupotyc_783
const grupoTycFlores = [
  {
    value: 0,
    label:
      "Hola! Buen día, te saluda Luz Flores de la inmobiliaria Grupo T&C, contact center del proyecto *Look And Feel* ubicado en *Av Encalada 1344 Surco,*  vimos que solicitaste información sobre el proyecto, ¿De cuantos dormitorios es el departamento que está buscando?",
  },
  {
    value: 1,
    label:
      "Hola! Buen día, te saluda Luz Flores de la inmobiliaria Grupo T&C, contact center del proyecto *Look And Feel* ubicado en *Av Encalada 1344 Surco,* vimos que solicitaste información sobre el proyecto, te adjunto plano y precio de lo solicitado.",
  },
  {
    value: 2,
    label:
      "Hola! Buen día, te saluda Luz Flores de la inmobiliaria Grupo T&C, contact center del proyecto *Look And Feel* ubicado en *Av Encalada 1344 Surco,* hace poco estuvimos en llamada, te adjunto la información solicitada.",
  },
  {
    value: 3,
    label: `Buen día, le saluda Luz Flores, contact center del proyecto *Look And Feel*, ubicado en la *Av. Encalada 1344, surco*.  \n Anteriormente estuvimos en contacto contigo con respecto a los departamentos, por lo cual quisiéramos ayudarte, si tienes alguna duda o consulta, coméntanos de cuantos dormitorios estás buscando para brindarte algunas otras alternativas y facilidades... 👩🏻‍💻🏗️ \n Para información de nuestros proyectos puede ingresar al siguiente enlace: https://www.grupotyc.com/`,
  },
  {
    value: 4,
    label:
      "Hola! Buen día, te saluda Luz Flores de la inmobiliaria Grupo T&C, contact center del proyecto *Hills Camacho* ubicado en *Av. Javier Prado Este 294, Surco*",
  },
  {
    value: 5,
    label:
      "Hola! Buen día, te saluda Luz Flores de la inmobiliaria Grupo T&C, contact center del proyecto *IQ Work – Oficinas* ubicado en  *Av. Encalada 1344, Surco*",
  },
  {
    value: 6,
    label:
      "Hola 😊, como esta, llegó a revisar la información enviada ?.  Si tiene alguna duda estoy a su disposición.",
  },
  // {
  //   value: 7,
  //   label: "Hola 😊, como esta, quería saber si llego revisar la cotización",
  // },
  {
    value: 8,
    label:
      "Hola, como esta, quería saber si tendría alguna respuesta o si todavía tendría alguna consulta adicional.",
  },
  {
    value: 9,
    label: "¿Tendría alguna consulta ?",
  },
  {
    value: 10,
    label: "¿Está todo claro?",
  },
  {
    value: 11,
    label: "Por favor me escribe para poder ayudarle con su consulta.",
  },
  {
    value: 12,
    label:
      "Hola 😊, disculpe la molestía quería saber si llego a revisar lo que le envíe.",
  },
  {
    value: 13,
    label:
      "Hola, Como está, le escribo para hacerle recordar que hoy tiene una visita agendada en nuestra sala de ventas.",
  },
  {
    value: 14,
    label:
      "Hola, Como está, quería saber cuándo podríamos coordinar una visita a nuestra sala de ventas.",
  },
  {
    value: 15,
    label:
      "Precio de lista, sin descuentos ni promociones. Los descuentos dependen del financiamiento con el que elija trabajar, puede ser con banco (BCP o BBVA) o al contado. ¿Con cuál desea trabajar?",
  },
  {
    value: 16,
    label:
      "Si gusta puedo agendar una cita a sala de ventas para que pueda hablar con la asesora del proyecto y darle una proforma con descuentos y el precio final, luego ella lo deriva con una asesora del banco para que realicen su evaluación bancaria.",
  },
  {
    value: 17,
    label: "¿Le parece bien ese tipo de financiamiento?.",
  },
  {
    value: 18,
    label:
      "De acuerdo, estaré atenta a sus consultas igualmente si desea agendar una cita al proyecto me comenta por este medio 😊",
  },
  {
    value: 19,
    label: `Hola! Buen día, te saluda Luz Flores de la inmobiliaria Grupo T&C, contact center del proyecto *Look And Feel* ubicado en *Av Encalada 1344 Surco*, vimos que solicitaste información sobre el proyecto, te adjunto la información solicitada. \n▶️ *TIPO 8:* 56.12m2 (1 DORMITORIO) Precio desde 413,001 soles \n▶️ *TIPO 9:* 63.51m2 (1 DORMITORIO) Precio desde 449,006 soles \n*Entrega para Julio de este año.* \nPrecio de lista, sin descuentos ni promociones. Los descuentos dependen del financiamiento con el que elija trabajar, puede ser con banco (BCP o BBVA) o al contado. ¿Con cuál desea trabajar?`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/tipo8.png",
  },
  {
    value: 20,
    label: `Hola! Buen día, te saluda Luz Flores de la inmobiliaria Grupo T&C, contact center del proyecto *Look And Feel* ubicado en *Av Encalada 1344 Surco*, vimos que solicitaste información sobre el proyecto, te adjunto nuestro brochure, ¿De cuantos dormitorios es el departamento que esta buscando?',`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/LOOKANDFEEL.pdf",
  },
  {
    value: 21,
    label: `Hola! Buen día, te saluda Luz Flores de la inmobiliaria Grupo T&C, contact center del proyecto *Look And Feel* ubicado en *Av Encalada 1344 Surco*, vimos que solicitaste información sobre el proyecto, te adjunto nuestro brochure, ¿De cuantos dormitorios es el departamento que esta buscando?`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/LOOKANDFEEL.pdf",
  },
  {
    value: 22,
    label: `Hola! Buen día, te saluda Luz Flores de la inmobiliaria Grupo T&C, contact center del proyecto *Look And Feel* ubicado en *Av Encalada 1344 Surco*, vimos que solicitaste información sobre el proyecto, te adjunto nuestra planera, ¿De cuantos dormitorios es el departamento que esta buscando?`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/PLANERALF.pdf",
  },
  {
    value: 23,
    label: `TIPO 1: *76.58m2* (2 DORMITORIOS) Precio desde *S/.703,893 soles*`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/tipo1.png",
  },
  {
    value: 24,
    label: `TIPO 3: *112.57m2* (3 DORMITORIOS) Precio desde *S/. 858,903 soles*`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/tipo3.png",
  },
  {
    value: 25,
    label: `TIPO 4: *125.44* (3 DORMITORIOS) Precio desde *S/. 1,017,761 soles*`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/tipo4.png",
  },
  {
    value: 26,
    label: `TIPO 5: *83.29m2* (3 DORMITORIOS) Precio desde *S/. 689,572 soles*`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/tipo5.png",
  },
  {
    value: 27,
    label: `TIPO 7: *101.97m2* (3 DORMITORIOS) Precio desde *S/. 816,379 soles*`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/tipo6.png",
  },
  {
    value: 28,
    label: `TIPO 8: *56.12m2* (1 DORMITORIO) Precio desde *S/. 413,001 soles*`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/tipo8.png",
  },
  {
    value: 29,
    label: `TIPO 9: *63.51m2* (1 DORMITORIO) Precio desde *S/. 449,006 soles*`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/tipo9.png",
  },
  {
    value: 30,
    label: `TIPO 10: *101.97m2* (3 DORMITORIOS) Precio desde *S/.753,370 soles*`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/tipo10.png",
  },
  {
    value: 31,
    label: `TIPO 21 A *Dpto. 103: 116.48m2* (3 DORMITORIOS) Precio de *S/.851,440 soles* (Piso1)`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/tipo21A.png",
  },
  {
    value: 32,
    label: `TIPO 24 A Dpto. 106: (1 DORMITORIO) Precio de 479,270 soles (piso1)`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/tipo24A.png",
  },
];

// grupotyc_803
const grupoTycMamani = [
  {
    value: 0,
    label: `Buen día, le saluda Alejandra Mamani en representación del Grupo T y C, le adjunto la información solicitada del proyecto Vallejo Living \n - Ubicación: Av. Cesar Vallejo 320-Lince \n - Fecha de entrega: Marzo 2025 \n - Horario de atención: Todos los días de 10 am a 7 pm (toda visita es con previa cita) \n¿De cuántos dormitorios está buscando? Para enviarle la cotización.`,
  },
  {
    value: 1,
    label: `Buen día, le saluda Alejandra Mamani en representación del Grupo T y C, le adjunto la información solicitada del proyecto Hills Camacho \n - Ubicación: Av. Javier Prado Este 294 - Surco \n - Fecha de entrega: Inmediata \n - Horario de atención: Todos los días de 9 am a 6 pm (toda visita es con previa cita) \n¿De cuántos dormitorios está buscando? Para enviarle la cotización.`,
  },
  {
    value: 2,
    label: `Buen dia, le saluda Alejandra del proyecto Palm Living. Solicitó información del proyecto y quería comentarle que por el momento no hemos lanzado planos ni precios. Si gusta puedo sugerirle estos proyectos: \n  1. Hills: Av. Javier Prado Este 294 - Camacho/Surco (Entrega Inmediata) \n  2. Vallejo Living: Av. Cesar Vallejo 320 - Lince (Marzo 2025) \n  3. Look and Feel: Av. Encalada 1344 - Surco (Julio 2023)`,
  },
  {
    value: 3,
    label:
      "Hola 😊, como esta, llegó a revisar la información enviada ?.  Si tiene alguna duda estoy a su disposición.",
  },
  {
    value: 4,
    label: "Hola 😊, como esta, quería saber si llego revisar la cotización",
  },
  {
    value: 5,
    label:
      "Hola 😊, como esta, quería saber si tendría alguna respuesta o si todavía tendría alguna consulta adicional.",
  },
  {
    value: 6,
    label: "¿Tendría alguna consulta?",
  },
  {
    value: 7,
    label: "¿Está todo claro?",
  },
  {
    value: 8,
    label:
      "Hola, Como está, quería saber cuándo podríamos coordinar una visita a nuestra sala de ventas.",
  },
  {
    value: 9,
    label:
      "Buen día, le saluda Alejandra Mamani del proyecto Hills en Av. Javier Prado Este 294. El día de hoy tiene una cita agendada en la sala de ventas y quisiera reconfirmar su asistencia. Espero su confirmación",
  },
  {
    value: 10,
    label: `Buen día, aquí le envío la información solicitada \n*1 dormitorio* (Vista interior) \n-        TIPO 3 de 43.06 m2: 301,000 soles \n-        TIPO 5 de 45.51 m2: 338,000 soles \n\n*2 dormitorios* \n -        TIPO 9 de 57.07 m2: 414,500 soles (Vista Interior) \n -        TIPO 7 de 59.23 m2: 456,700 soles (Vista Exterior) \n\n*3 dormitorios* (Vista exterior) \n-        TIPO 1A de 85.83 m2: 643,800 soles \n-        TIPO 2 de 81.55 m2: 610,200 soles \n-        TIPO 8 de 69.19 m2: 493,800 soles \n\nPrecio de lista, sin descuentos ni promociones. Los descuentos dependen del financiamiento con el que elija trabajar, puede ser con banco (BCP o BBVA), crédito directo con la misma empresa o al contado. ¿Con cuál desea trabajar?`,
  },
  {
    value: 11,
    label: `Buen día, aquí le envío la información solicitada \n\n*1. Crédito Hipotecario:* Nosotros manejamos un financiamiento inteligente con el BCP o BBVA. \n\nNecesitamos una inicial del 10% a la firma de minuta, luego usted tendría que cumplir con una recaudación sin intereses del 15% en pagos prorrateados hasta un mes antes de la fecha de entrega. \n\nUna vez juntado con la inmobiliaria el 25%; recién el banco desembolsará, le entregarán el departamento; y ahí comenzará a financiar con el banco el restante en el plazo pactado y con la tasa que le impongan. \n\n*2. Crédito directo:* Si es crédito directo se requiere como mínimo el 30% de inicial y el 70% en cuotas hasta un mes antes de la fecha de entrega, sin intereses. \n\n¿Con cuál desearía trabajar?`,
  },
  {
    value: 12,
    label: `Buen día, aquí le envío la información solicitada \n\n*2 dormitorios + sala estar* \n-             TIPO 3 de 89.41 m2: 734,000 soles (Vista Av. Javier Prado) \n\n*3 dormitorios* \n-             TIPO 4 de 92.77 m2: 728,771 soles (Vista Av. Javier Prado) \n-             TIPO 9 de 101.58 m2: 834,304 soles (Vista al Club el golf) \n-             TIPO 10 de 123.9 m2: 1,059,300 soles (Vista Club Golf y Av. Javier Prado) \n-             TIPO 8 de 124.04 m2: 1,008,000 soles (Vista Club el golf) \n\nPrecio de lista, sin descuentos ni promociones. Los descuentos dependen del financiamiento con el que elija trabajar, puede ser con banco (BCP o BBVA) o al contado. ¿Con cuál desea trabajar?`,
  },
  {
    value: 13,
    label: `Buen día, aquí le envío la información solicitada \n\n*1 dormitorio* \n-        TIPO 8 de 56.12m2: 427,300 soles \n-        TIPO 9 de 63.51m2: 449,200 soles \n\n*2 dormitorios* \n-        TIPO 1 de 76.58m2: 703,800 soles \n-        TIPO 6 de 76.99m2: 625,500 soles \n\n*3 dormitorios* \n-        TIPO 5 de 83.29m2: 689,000 soles \n-        TIPO 10 de 101.97m2: 791,000 soles \n-        TIPO 3 de 112.57m2: 901,000 soles \n-        TIPO 4 de 125.44: 1,017,000 soles    \n\nPrecio de lista, sin descuentos ni promociones. Los descuentos dependen del financiamiento con el que elija trabajar, puede ser con banco (BCP o BBVA) o al contado. ¿Con cuál desea trabajar?`,
  },
  {
    value: 14,
    label:
      "Buen día, le saluda Alejandra Mamani del proyecto Vallejo Living en Av. Cesar Vallejo 320 - Lince. El día de hoy tiene una cita agendada en la sala de ventas y quisiera reconfirmar su asistencia. Espero su confirmación",
  },
  {
    value: 15,
    label:
      "Buen día, le saluda Alejandra Mamani del proyecto Look and feel en Av. Encalada 1344 - Surco. El día de hoy tiene una cita agendada en la sala de ventas y quisiera reconfirmar su asistencia. Espero su confirmación",
  },
  {
    value: 16,
    imagen:
      "https://dashboardbot.tecnicom.pe/assets/whatsapp/DEMOLITION-OP-1A.jpg",
    label: `🚧¡Demolition cocktail night!🚧 \nTe invitamos a disfrutar de una noche diferente, con descuentos exclusivos y ÚLTIMOS PRECIOS DE PREVENTA, donde podrás conocer tu futuro depa de 1, 2 y 3 dorms. desde 43m2. en Lince a pasos de San Isidro. Contaremos con: \n- Snack Bar 🫒 \n- Open Bar 🍸 \n- Descuentos exclusivos e irrepetibles 🥳 \nTe esperamos: \n⏰Días: viernes 19 y sábado 20 de mayo \n🕟Hora: 04:00 a 10:00 pm \n📍Lugar: Av. César Vallejo 320, Lince \nConfírmanos tu asistencia 📩`,
  },
  {
    value: 17,
    imagen:
      "https://dashboardbot.tecnicom.pe/assets/whatsapp/DEMOLITION-OP-1B.jpg",
    label: `🚧¡Demolition cocktail night!🚧 \nTe invitamos a disfrutar de una noche diferente, con descuentos exclusivos y ÚLTIMOS PRECIOS DE PREVENTA, donde podrás conocer tu futuro depa de 1, 2 y 3 dorms. desde 43m2. en Lince a pasos de San Isidro. Contaremos con: \n- Snack Bar 🫒 \n- Open Bar 🍸 \n- Descuentos exclusivos e irrepetibles 🥳 \nTe esperamos: \n⏰Días: viernes 19 y sábado 20 de mayo \n🕟Hora: 04:00 a 10:00 pm \n📍Lugar: Av. César Vallejo 320, Lince \nConfírmanos tu asistencia 📩`,
  },
  {
    value: 18,
    imagen:
      "https://dashboardbot.tecnicom.pe/assets/whatsapp/DEMOLITION-OP-2A.jpg",
    label: `🚧¡Demolition cocktail night!🚧 \nTe invitamos a disfrutar de una noche diferente, con descuentos exclusivos y ÚLTIMOS PRECIOS DE PREVENTA, donde podrás conocer tu futuro depa de 1, 2 y 3 dorms. desde 43m2. en Lince a pasos de San Isidro. Contaremos con: \n- Snack Bar 🫒 \n- Open Bar 🍸 \n- Descuentos exclusivos e irrepetibles 🥳 \nTe esperamos: \n⏰Días: viernes 19 y sábado 20 de mayo \n🕟Hora: 04:00 a 10:00 pm \n📍Lugar: Av. César Vallejo 320, Lince \nConfírmanos tu asistencia 📩`,
  },
  {
    value: 19,
    imagen:
      "https://dashboardbot.tecnicom.pe/assets/whatsapp/DEMOLITION-OP-2B.jpg",
    label: `🚧¡Demolition cocktail night!🚧 \nTe invitamos a disfrutar de una noche diferente, con descuentos exclusivos y ÚLTIMOS PRECIOS DE PREVENTA, donde podrás conocer tu futuro depa de 1, 2 y 3 dorms. desde 43m2. en Lince a pasos de San Isidro. Contaremos con: \n- Snack Bar 🫒 \n- Open Bar 🍸 \n- Descuentos exclusivos e irrepetibles 🥳 \nTe esperamos: \n⏰Días: viernes 19 y sábado 20 de mayo \n🕟Hora: 04:00 a 10:00 pm \n📍Lugar: Av. César Vallejo 320, Lince \nConfírmanos tu asistencia 📩`,
  },
  {
    value: 20,
    label: `Buen día, le saluda Alejandra Mamani en representación del Grupo T y C, le adjunto la información solicitada del proyecto *Vallejo Living* \n- Ubicación: Av. Cesar Vallejo 320-Lince   \n- Fecha de entrega: Marzo 2025 \n- Horario de atención: Todos los días de 10 am a 7 pm (toda visita es con previa cita) \n¿De cuántos dormitorios está buscando? Para enviarle la cotización. \n*Vallejo Living*`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/VallejoLiving.pdf",
  },
  {
    value: 21,
    label: `Buen día, le saluda Alejandra Mamani en representación del Grupo T y C, le adjunto la información solicitada del proyecto *Hills Camacho* \n-             Ubicación: Av. Javier Prado Este 294 - Surco \n-             Fecha de entrega: Inmediata \n-             Horario de atención: Todos los días de 9 am a 6 pm (toda visita es con previa cita) \n¿De cuántos dormitorios está buscando? Para enviarle la cotización.`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/HILLS.pdf",
  },
  {
    value: 22,
    label: `Buen día, aquí le envío el catálogo de planos del proyecto, ¿Qué tipo es de su interés? Para hacerle una cotización`,
    files:
      "https://dashboardbot.tecnicom.pe/assets/whatsapp/PLANERA_VALLEJO.pdf",
  },
];

const grupoTycMamaniEdit = [
  {
    value: 23,
    label: `{{1}} soy {{2}} de Grupo T y C ,  le recordamos que el día {{3}} tiene una cita para visitar nuestro proyecto {{4}} ubicado en {{5}} \nEn caso de no poder asistir, puede reprogramar la hora o la fecha por este medio.`,
  },
  {
    value: 24,
    label: `{{1}} soy {{2}} de Grupo T y C Le recordamos que el día de {{3}} tiene una cita para visitar nuestro proyecto {{4}} ubicado en {{5}} \nEn caso de no poder asistir, puede reprogramar la hora o la fecha por este medio.`,
  },
  {
    value: 25,
    label: `Hola {{1}} le recordamos que {{2}} tiene una cita para visitar nuestro proyecto {{3}} ubicado en {{4}} \nEn caso de no poder asistir, puede reprogramar la hora o la fecha por este medio.`,
  },
];

//tiempo y casa

const tiempoCasa = [
  {
    value: 2,
    label:
      "🏡 Hola se comunico con *Tiempo y Casa*, su inmobiliaria de confianza en Ica, ¿como lo puedo ayudar ?",
  },
];

const tiempoCasaEdit = [
  {
    label:
      "Hola {{1}} le recordamos que {{2}} tiene una cita para visitar nuestro proyecto {{3}} ubicado en {{4}}. En caso de no poder asistir, puede reprogramar la hora o la fecha por este medio",
    value: 0,
  },
  {
    value: 1,
    label: "Hola {{1}} tendría alguna consulta de la información que le envié.",
  },
];

const urbanProject = [
  {
    value: 0,
    label:
      "Hola, quería saber si llego a revisar la información del proyecto que le envié.",
  },
  {
    value: 1,
    label: "¿Tendría alguna consulta?",
  },
  {
    value: 2,
    label:
      "Hola somos de *Urban Projects*  se registro en nuestras redes sociales, tenemos proyectos Inmobiliarios en Quito y Cumbaya, ¿Cómo lo puedo ayudar?",
  },
];

const cantabria = [
  {
    value: 4,
    label:
      "¿Quisiera información de nuestros dormitorios o del metraje del departamento?",
  },
  {
    value: 8,
    label: `Te saluda Cantabria Inmobiliaria \n\nEstamos seguros que puedes acceder a un crédito hipotecario. \n\nNosotros te ayudamos a conseguirlo. \n\nHaz que tu esfuerzo se convierta de un depa para ti y los que más amas. \n\nEscribeme si tienes interés.`,
  },
  {
    value: 18,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/Cantabria.pdf",
    label: `*¡EXTENDEMOS NUESTROS PRECIOS DE FERIA!* 🥳 \n\n¡Hola 😃! \n\nNo te pierdas nuestros super *DESCUENTOS*. \n\nSabemos que estás interesado en comprar tu próximo depa. \n\nQueremos ayudarte a cumplir este gran logro. 🤩 \n\n📍Visítanos en nuestras salas de ventas de Lunes a Domingo de 10 am a 7 pm. 🕑`,
  },
  {
    value: 19,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/Cantabria.pdf",
    label: `*¡EXTENDEMOS NUESTROS PRECIOS DE FERIA!* 🥳 \n\n¡Hola 😃! \n\nNo te pierdas nuestros super *DESCUENTOS.* \n\nSabemos que estás interesando en comprar nuestros proyectos. \n\nQueremos ayudarte a cumplir este gran logro. 🤩 \n\n📍 Visítanos en nuestras salas de ventas de 10 am a 7pm. 🕑`,
  },
  {
    value: 20,
    label: `🥳*BLACK NIGHT EN CANTABRIA.*🥳 \nTU PRÓXIMO DEPA en los mejores distritos.  \n\nHola 😃! Te esperamos este jueves 21 de septiembre de 7pm a 10 pm en *AMME* para pasar un día increíble lleno de sorpresas. \n\n▪️Accede a PROMOS ÚNICAS. 🤩 \n▪️Ven con una prenda negra y entra a un sorteo. 🎁 \n▪️Disfruta de bebidas y ricos piqueos. 🍸 \n\n📍Calle Amador Merino Reina 340. San Isidro`,
    imagen:
      "https://dashboardbot.tecnicom.pe/assets/whatsapp/eventomultiproyecto.jpg",
  },
  {
    value: 21,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/festidepas1.jpg",
    label: `SE PARTE DEL FESTIDEPAS CANTABRIA 🥳 Tu depa en los mejores distritos. \n\n\n\nHola! 😃 Te invitamos el jueves 23 de nov. de 7 a 10pm en nuestro proyecto AMME para pasar una noche increíble llena de sorpresas y promociones. \n\n\n\n▪️Accede a DSCTOS y CONDICIONES ÚNICAS. 🤩 \n\n▪️Gana PREMIOS por separación 🎁 \n\n\n\n📍Te esperamos en Calle Amador Merino Reina 340 - San Isidro. \n\n\n\nCupos Limitados previo registro.`,
  },
  {
    value: 22,
    label: `¡Hola! 😁 \n\nVive en Miraflores sin límites, vive en Thu. 😎 \n\n*3 dormitorios desde S/730,000* \nCondiciones: \n- Precio final. \n- 10% de inicial en noviembre 2023, desembolso para marzo 2024 y entrega en abril 2024. \n- Desembolso con el BBVA. \n\n📍Visítanos en: Av. Enrique palacios 830, Miraflores \n\nAgenda tu cita.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/thu.jpg",
  },
  {
    value: 23,
    label: `¡Hola! 😁 \n\nVive frente a un parque, vive en Duetto. 🤩 \n\n*3 ambientes desde s/380,000* \nCondiciones: \nPrecio final. \n- Fracciona tu inicial hasta mayo 2024 \n- Desembolso para junio 2024 \n- Desembolso con el BCP. \n\n📍Visítanos en: Av. Alejandro Bertello 846, Urb. pando \n\nAgenda tu cita.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/duetto.jpg",
  },
  {
    value: 24,
    label: `¡Hola! 😁 \n\nVive en San Isidro sin límite, vive en Luup \n\n*3 ambientes desde s/490,000* \nCondiciones: \n- Precio final. \n- Fracciona tu inicial hasta mayo 2024 \n- Desembolso para junio 2024 \n- Desembolso con el BCP. \n\nVisítanos en: Av. Javier Prado Este 457, San Isidro.  \n\nAgenda tu cita`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/luup.jpg",
  },
  {
    value: 25,
    label: `¡Hola!😁 \n\nCierre de sala de ventas en Amme, San Isidro. 😎 \n\n*1 dormitorio desde s/385,000* \nCondiciones: \n- Precio final. \n- 10% de inicial en noviembre 2023 \n- Desembolso para diciembre 2023 \n- Desembolso con el BCP. \n\n📍Visítanos en: Calle Amador Merino Reyna 340 - 346, San Isidro. \n\nAgenda tu cita.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/amme.jpg",
  },
  {
    value: 26,
    label: `Hola!😃 \n\nVive en Miraflores sin límites, vive en Thu. 😎 \n\n1dormitorio (60m2) a s/510,000 🥳 \n*Condiciones:* \nPrecio final. \n10% de inicial en noviembre 2023, desembolso para marzo 2024 y entrega en abril 2024. \nDesembolso con el BBVA. \n\n📍Visítanos en: Av. Enrique palacios 830, Miraflores. \n\nAgenda tu cita.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/thu2.jpg",
  },
  {
    value: 27,
    label: `Hola! 😃 \n\nVive frente al parque, vive en Duetto. 🤩🌳 \n\n2 dormitorios desde s/280,000 \nCondiciones: \nPrecio final. \nFracciona tu inicial hasta mayo 2024. \nDesembolso para junio 2024. \nDesembolso con el BCP. \n\n📍Visítanos en: Av. Alejandro Bertello 846, Urb. Pando \n\nAgenda tu cita.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/duetto2.jpg",
  },
  {
    value: 28,
    label: `Hola! 😃 \n\nCierre de sala de ventas en Amme, San Isidro. 😎 \n\n2 dormitorios desde s/525,000 \nCondiciones: \nPrecio final. \n10% de inicial en noviembre 2023. \nDesembolso para diciembre 2023. \nDesembolso con el BCP. \n\n📍Visítanos en: Calle Amador Merino Reyna 340-346, San Isidro. \n\nAgenda tu cita.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/amme2.jpg",
  },
  {
    value: 29,
    label: `Hola! 😃 \n\nVive en San Isidro sin límites, vive en Luup. 😎 \n\n2 dormitorios desde s/380,000 \nCondiciones: \nPrecio final. \n10% de inicial en noviembre 2023. \nDesembolso para diciembre 2023. \nDesembolso con el BCP. \n\n📍Visítanos en: Av. Javier Prado Este 457, San Isidro. \n\nAgenda tu cita.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/luup2.jpg",
  },
  {
    value: 30,
    label: `Hola😃, \n\nSe parte de nuestro *Meet and Grill*🥩.  \n\nTu depa en el distrito de Santa Beatriz.  \n\nTe invitamos este *sábado 2 de dic. de 12pm a 5pm* a nuestro proyecto *Reserva* 🤩 para pasar una tarde increíble llena de sorpresas y promociones. 🎉 \n\n📍Te esperamos en Av. Arequipa 748 - Santa Beatriz. \n\n*Cupos limitados previo registro.* 😎'`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/grill.jpg",
  },
  {
    value: 31,
    label: `Se parte de nuestro Open Sale en Latir🥳  \n\nTu próximo depa en San Miguel. \n\nHola! 😃 Te invitamos el miércoles 07 de Feb. de 10 am a 7pm a nuestro proyecto Latir para pasar un día llena de sorpresas y promociones. \n\n▪️Accede a DSCTOS y CONDICIONES ÚNICAS.🤩 \n▪️Gana PREMIOS por separación 🎁 \n\n📍Te esperamos en Av. Precursores 490, San Miguel. \n\nCupos Limitados previo registro.`,
    imagen: 'https://dashboardbot.tecnicom.pe/assets/whatsapp/openlatir.jpg'
  },
  {
    value: 32,
    label: `Lanzamos nuestra sala de Ventas y Piloto en Luup🥳  \n\nHola! 😃 Te invitamos a nuestro Luau el jueves 22 de Feb. de 7 a 10pm a nuestro proyecto Luup para pasar una noche increíble llena de sorpresas y promociones. \n\n▪️Accede a DSCTOS y precios solo por ese día.🤩 \n▪️Gana PREMIOS por separación 🎁 \n\n📍Te esperamos en Av. Javier Prado 457, San Isidro. \n\nCupos limitados previo registro.`,
    imagen: 'https://dashboardbot.tecnicom.pe/assets/whatsapp/luauluup.jpg'
  },
  {
    value: 33,
    label: `Lanzamos nuestro nuevo piloto en Duetto🥳  \n\nHola!😃 Te invitamos al Lanzamiento de nuestro nuevo depa piloto en Duetto el día sábado 16 de Marzo de 10 am a 5 pm para pasar un día increíble lleno de sorpresa. \n\n▪️Accede a DSCTOS y precios solo por ese día.🤩 \n▪️Gana PREMIOS por separación 🎁 \n▪️ Disfruta de ricos piqueos. 🍔 \n\n📍Te esperamos en Av. Alejandro Bertello 846, Urb. Pando. \nCupos Limitados previo registro.`,
    imagen: 'https://dashboardbot.tecnicom.pe/assets/whatsapp/duetto846.jpg',
  },
  {
    value: 34,
    label: `Lanzamos nuestra nueva sala de ventas en Duetto.🥳 \n\nVive entre los distritos de San Miguel y Pueblo Libre. 😎  \n\n✅Depas de 1, 2 y 3 dorms. \n✅Excelentes áreas sociales. \n✅En construcción. \n\nVisítanos y consulta por tu próximo depa. \n\n📍Te esperamos en: Av. Alejandro Bertello N° 846, Urb. Pando.`,
    imagen: 'https://dashboardbot.tecnicom.pe/assets/whatsapp/duettolanzamiento.jpg'
  },
  {
    value: 35,
    label: `🫣*NO TE PUEDES PERDER NUESTRO LUAU🌸EN LUUP, SAN ISIDRO.* 🥳 \n\nVive a pasos del centro financiero. 😎 \n\nTe esperamos este jueves 22 de feb. de 7 a 10 pm para pasar una noche increíble. 🤩🎉 \n\n✅DSCTOS solo por ese día. \n✅Gana premios por separación. \n✅Catering y Open bar. \n\n📍Av. Javier Prado 457, San Isidro. \n\nCupos limitados.`,
    imagen: 'https://dashboardbot.tecnicom.pe/assets/whatsapp/luau.jpeg'
  },
  {
    value: 36,
    label: `¡LLEGÓ EL GRAN DÍA!🤩 \n\nRecuerda que te esperamos desde las 🕗 7pm hasta las 10pm para pasar una noche increíble en nuestro Luau. 🎉 \n\n✅DSCTOS solo por hoy. \n✅Precios especiales. \n✅Catering y Open bar. \n\n📍 Av. Javier Prado Este 457, San Isidro.`,
    imagen: 'https://dashboardbot.tecnicom.pe/assets/whatsapp/luauhoy.jpeg'
  }
];

const cantabriaEdit = [
  {
    value: 0,
    label: `Hola  {{1}}  😁 \nNo pudimos reunirnos, espero que haya sido por un pequeño inconveniente y nada grave. \n\nEn Cantabria Inmobiaria siempre estaremos acá para atenderte. \n\nComprar un depa, siempre, es un gran logro a tu esfuerzo. \n\nNo lo aplaces más y visítanos en nuestra sala de ventas. \n\n{{2}} \n\nAtendemos de lunes a domingo de 10am a 7pm \n\n{{3}} Tiene todo para ti y lo que más amas. \n\nEscríbeme para regentar fecha y hora.`,
  },
  {
    value: 1,
    label: `¡Que nada te detenga! \n{{1}} tiene todo para ti y los que más amas. \nAcá te dejo un videito. \n\nEn sala de ventas tenemos una super oferta exclusiva. \n\nVisitanos: \n{{2}} \nAtendemos de lunes a domingo de 10am a 7pm. \n\nEscribeme para agendar fecha y hora.`,
  },
  {
    value: 2,
    label: `Te saluda Cantabria Inmobiliaria. \n\nQuiero contarte un poco sobre mí. \n\n+25 años construyendo espacios de vida. \nSomos muy profesionales en lo que hacemos. \nNuestro edificios son eco amigables y pet friendly \n\nSi decides comprar tu depa con nosotros, te aseguro que cada sol invertido valdrá la pena. \n\nNo lo aplaces más y visítanos en nuestra sala de ventas. \n\n{{1}}  \nAtendemos de lunes a domingo de 10am a 7pm. \n\nEscríbeme para agendar fecha y hora.`,
  },
  {
    value: 3,
    label: `🏠 {{1}} somos *Cantabria Inmobiliaria* nos contactaste por {{2}}, ¿en que te podemos ayudar ?`,
  },
  {
    value: 5,
    label:
      "{{1}} queria saber si pudo revisar la información del proyecto que le envié. *¿Tendría alguna duda?*",
  },
  {
    value: 6,
    label: "{{1}} ¿Todavía tendría interés?",
  },
  {
    value: 7,
    label: `Hola  {{1}} \nTe saluda Cantabria Inmobiliaria \n\nSabemos que estás interesado en comprar un depa en nuestro proyecto  \n{{2}}. \n\nQuiero ayudarte a que alcances este gran logro a tu esfuerzo. \n\nNo lo aplaces más,{{3}} tiene todo para ti y los que más amas. \n\nEscribeme si tienes interés.`,
  },
  {
    value: 9,
    label: `¡Que nada te detenga! \n\n{{1}} tiene todo para ti y los que más amas. \n\nAcá te dejo un videito. \n\nEn sala de ventas tenemos una super oferta exclusiva. \n\nVisitamos: \n {{2}} \nAtendemos de lunes a domingo de 10am a 7pm \n\nEscríbeme para agendar fecha y hora.`,
  },
  {
    value: 10,
    label: `Te saluda Cantabria Inmobiliaria. \n\nQuiero contarte un poco sobre mí. \n\n+25 años construyendo espacios de vida. \nSomos muy profesionales en lo que hacemos. \nNuestros edificios son eco amigables y pet friendly \n\nSi decides comprar tu depa con nosotros, te aseguro que cada sol invertido valdrá la pena. \n\nNo lo aplaces más y visítanos en nuestra sala de ventas. \n\n {{1}} \nAtendemos de lunes a domingo de 10am a 7pm. \n\nEscríbeme para agenda fecha y hora.`,
  },
  {
    value: 11,
    label: `Compra un depa, siempre, es un gran logro a tu esfuerzo. \n\nNo lo aplaces más y visítanos en nuestra sala de ventas.  \n {{1}} \nAtendemos de lunes a domingo de 10 am a 7 pm  \n {{2}} tiene todo para ti y los que más amas. \n\nEscríbeme para agendar fecha y hora`,
  },
  {
    value: 12,
    label: `¡Que nada te detenga!  \n{{1}} tiene todo para ti y los que más amas.  \nAcá te dejo un videito. \n\nEn sala de ventas tenemos una súper oferta exclusiva. \n\nVisítanos:  \n {{2}} \n\nAtendemos de lunes a domingo de 10 am a 7 pm  \n\nEscríbeme para agendar fecha y hora`,
  },
  {
    value: 13,
    labal: `Te saluda Cantabria inmobiliaria. \n\nQuiero contarte un poco sobre mí. \n+25 años construyendo espacios de vida.  \nSomos muy profesionales en lo que hacemos. \nNuestros edificios son eco amigables y pet friendly. \n\nSi decides comprar tu depa con nosotros, te aseguro que cada sol invertido valdrá la pena.  \n\nNo lo aplaces más y visítanos en nuestra sala de ventas.  \n {{1}} \nAtendemos de lunes a domingo de 10 am a 7pm      \n\nEscríbeme para agendar fecha y hora.`,
  },
  {
    value: 14,
    label: "{{1}} ¿Cuándo podríamos agendar la visita al proyecto?",
  },
  {
    value: 15,
    label: `Hola {{1}}!😀 \n\nComprar un depa, siempre, es un gran logro a tu esfuerzo. 🥳 \n\nNo lo aplaces más y visítanos en nuestra sala de ventas. \n📍{{2}} \nAtendemos de lunes a domingo de 10 am  a 7 pm 🕗 \n\n{{3}}, {{4}} tiene todo para ti y los que más amas. \n\nEscríbeme para agendar fecha y hora 😎`,
  },
  {
    value: 16,
    label: `Hola {{1}}! 😀 \n\nTe saluda Cantabria Inmobiliaria. 🏢 \n\nSabemos que estás interesado en comprar un depa en nuestro proyecto  {{2}}, {{3}}.  \n\nQuiero ayudarte a que alcances este gran logro a tu esfuerzo.🤩 \n\nNo lo aplaces más, {{4}}, tiene todo para ti y los que más amas. 😍 \n\nEscríbeme si tienes interés.`,
  },
  {
    value: 17,
    label: `Hola {{1}}! 😀 \n\nNo pudimos reunirnos, espero que haya sido por un pequeño inconveniente y nada grave. \n\nEn Cantabria Inmobiaria siempre estaremos acá para atenderte. 🧏🏻‍♂️ \n\nComprar un depa, siempre, es un gran logro a tu esfuerzo. 😎 \n\nNo lo aplaces más y visítanos en nuestra sala de ventas. \n📍{{2}}  \n\nAtendemos de lunes a domingo de 10am a 7pm 🕑 \n\n {{3}}, {{4}} tiene todo para ti y los que más amas. 😍 \n\nEscríbeme para regentar fecha y hora.`,
  },
];

const barqueros = [
  {
    value: 0,
    label: "Hola somos Barqueros Inmobiliaria, en que lo podemos ayudar",
  },
  {
    value: 1,
    label: "¿Tendría alguna consulta?",
  },
  {
    value: 3,
    label: `Desde tomarte un break   en la terraza o hacer una reu en la zona gourmet , los días en #Brío serán de full relax y diversión.   \n\nAgenda tu cita con nosotros y conoce más acerca del proyecto Brío \nVisítanos en Av. Tizón y Bueno 765 – Jesús María (a dos cuadras del canal 2)',`,
  },
  {
    value: 4,
    label: `¡Vive en Valenti! Un proyecto ecoamigable que cuenta con: sistema de gas natural 🍃, excelente diseño para favorecer la iluminación natural ☀️ y una gran vista al parque. 🌳 \nAgenda tu cita con nosotros y conoce más acerca del proyecto Valenti. \nVisítanos en Calle América 260 – Pueblo Libre',`,
  },
  {
    value: 5,
    label: `¡Así de cerca estás de tu depa propio! 🏡  🎉 Cumple tu sueño y obtén el hogar que tanto buscabas.  \n¡Nosotros te pagamos el taxi hasta nuestra sala de ventas! 😎  \n\nPor favor, indíquenos cuál es su horario de preferencia para coordinar su visita.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/barquero1.png",
  },
  {
    value: 6,
    label: `¡Estás a un paso de cumplir tus sueños del depa propio! \nPor favor, indíquenos cuál es su horario de preferencia para coordinar su visita`,
  },
  {
    value: 7,
    label: `Aun estas a tiempo de agendar tu visita con nuestras asesoras, también puedes visitarnos en Av. Tizón y Bueno 765, Jesús María. Nuestro horario de atención es de lunes a domingo de 10 am a 7 pm. ¡Te esperamos!`,
  },
  {
    value: 8,
    label: `¡Hola, te saluda Diana Urcia, asesora de Barqueros Inmobiliaria. Le comento que estamos próximos a realizar la demolición del Proyecto Brío en Jesús María. \n\n🤩 Sí, como leíste, el depa que buscabas está a un paso de ser una realidad. 🏡 Aprovecha nuestros precios por cierre de preventa y ten el depa que siempre soñaste.    \n\nPara ello, nos gustaría poder contactarlo y brindarle mayor información.  \nPor favor, indíqueme cuál es su horario de preferencia para contactarme con Usted`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/barquero2.png",
  },
  {
    value: 9,
    label: `Hola, te saluda Guadalupe Lamas, asesora de Barqueros Inmobiliaria. Le comento que estamos próximos a realizar la demolición del Proyecto Brío en Jesús María. \n\n🤩 Sí, como leíste, el depa que buscabas está a un paso de ser una realidad. 🏡 Aprovecha nuestros precios por cierre de preventa y ten el depa que siempre soñaste.    \n\nPara ello, nos gustaría poder contactarlo y brindarle mayor información.  \nPor favor, indíqueme cuál es su horario de preferencia para contactarme con Usted.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/barquero2.png",
  },
  {
    value: 10,
    label: `Hola, te saluda Nadia Baquerizo asesora de Barqueros Inmobiliaria. Le comento que ya iniciamos la demolición del Proyecto Valenti 🍃 en Pueblo Libre. \n\n¡Aprovecha nuestras increíbles ofertas por CIERRE DE PREVENTA que tenemos para ti! \n\nPara ello, nos gustaría poder contactarlo y brindarle mayor información.  \nPor favor, indíqueme cuál es su horario de preferencia para contactarme con Usted.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/v1.jpg",
  },
  {
    value: 11,
    label: `Hola, te saluda Magaly Pittman asesora de Barqueros Inmobiliaria. Le comento que ya iniciamos la demolición del Proyecto Valenti 🍃 en Pueblo Libre. \n\n¡Aprovecha nuestras increíbles ofertas por CIERRE DE PREVENTA que tenemos para ti! \n\nPara ello, nos gustaría poder contactarlo y brindarle mayor información.  \nPor favor, indíqueme cuál es su horario de preferencia para contactarme con Usted.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/v1.jpg",
  },
  {
    value: 12,
    label: `¡Estás a un paso de cumplir tus sueños del depa propio! \nPor favor, indíqueme cuál es su horario de preferencia para contactarme con Usted.'`,
  },
  {
    value: 13,
    label:
      "Aun estas a tiempo de agendar tu visita, también puedes visitarnos en Calle America 270, Pueblo Libre, nuestro horario de atención es de lunes a domingo de 10 am a 7 pm. ¡Te esperamos!",
  },
  {
    value: 14,
    label: `🤩 ¡Tiramos Valenti por la ventana! Por aniversario te regalamos el 40% de tu cuota inicial, para que vivas frente al parque como siempre quisiste. 🌳 🎉 \n\nPara ello, nos gustaría poder contactarlo y brindarle mayor información. 🏡 \nPor favor, indíqueme cuál es su horario de preferencia para contactarme con Usted. \nMañana (10 am a 1 pm) \nTarde (2 pm a 7 pm)`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/barqueros22.png",
  },
  {
    value: 15,
    label: `Hola, te saluda Guadalupe Lamas asesora de Barqueros Inmobiliaria. Le comento que ya iniciamos la \nconstrucción del Proyecto Brío 🏡 🎉 en Jesús María. \n¡Aprovecha nuestras increíbles ofertas por CIERRE DE PREVENTA que tenemos para ti! 😎 \nPara ello, nos gustaría poder contactarlo y brindarle mayor información. \nPor favor, indíqueme cuál es su horario de preferencia para contactarme con Usted. \nMañana (10 am a 1 pm) \nTarde (2 pm a 7 pm)`,
    imagen:
      "https://dashboardbot.tecnicom.pe/assets/whatsapp/barquerosbrio.jpg",
  },
  {
    value: 16,
    label: `Hola, te saluda Diana Urcia asesora de Barqueros Inmobiliaria. Le comento que ya iniciamos la \nconstrucción del Proyecto Brío 🏡 🎉 en Jesús María. \n¡Aprovecha nuestras increíbles ofertas por CIERRE DE PREVENTA que tenemos para ti! 😎 \nPara ello, nos gustaría poder contactarlo y brindarle mayor información. \nPor favor, indíqueme cuál es su horario de preferencia para contactarme con Usted. \nMañana (10 am a 1 pm) \nTarde (2 pm a 7 pm)`,
    imagen:
      "https://dashboardbot.tecnicom.pe/assets/whatsapp/barquerosbrio.jpg",
  },
  {
    value: 17,
    label: `🤩 ¡Solo por pocos días! 🌳 🎉 \n\nCompra tu departamento en VALENTI, nuestro proyecto inmobiliario en Pueblo Libre, y el desembolso de tu crédito se realizará recién a finales del 2024 🏡 \n\nPara ello, nos gustaría poder contactarlo y brindarle mayor información. \nPor favor, indíqueme cuál es su horario de preferencia para contactarme con Usted.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/valentin.jpg",
  },
  {
    value: 18,
    label: `¡Gana una cochera con nosotros! \n\n¡Por ÚLTIMOS DÍAS!  \nSepara tu depa y entra al sorteo por 1 cochera en nuestro proyecto Brío \n\nCelebremos juntos, agenda tu cita. \n\nPara ello, nos gustaría poder contactarlo y brindarle mayor información. \nPor favor, indíqueme cuál es su horario de preferencia para contactarme con Usted.`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/brio.jpg",
  },
];

const barquerosEdit = [
  {
    value: 2,
    label:
      "Hola {{1}} te saluda {{2}} de Barqueros Inmobiliaria, le hago recordar que el día {{3}} tiene una visita en el proyecto de {{4}}",
  },
];

const iCasa = [
  {
    value: 0,
    label:
      "Gracias por comunicarse con Icasa, favor escribirnos para poder atenderlo",
  },
  {
    value: 1,
    imagen:
      "https://dashboardbot.tecnicom.pe/assets/whatsapp/icasareferidos.png",
    label: `¡Gana una gift card de hasta S/1,500 por tu referido 🤑!  \n\n\nEste sábado 21 estaremos realizando un open day 🤩 de nuestro nuevo proyecto Brasil 1396 🏗️ en Pueblo Libre, con depas de 1, 2 y 3 dorm. 👈   \n\n\nLleva a tu referido y podrás ganar una gift card por la separación del depa 📄✍🏻. Además, tu referido podrá acceder a descuentos exclusivos, premios 🎁 y conocer nuestro nuevo piloto. \n\n\n¿Le gustaría obtener mayor información del plan de referidos? 🙋🏻‍♂️🙋🏻 \n\nOpción (Sí, quiero más información) (No deseo los s/1,500) `,
  },
];

const viva = [
  {
    value: 0,
    label: `¡Hola! Hace poco nos visitaste en nuestro Centro de Atención y Ventas. Te invitamos a \nresponder esta breve encuesta sobre la atención que te brindamos, además, podrás \nparticipar de un sorteo y ganar el 50% de tu reserva. \nEvalúanos aquí �� {{https://forms.office.com/r/kueQYyYDQp}} \n¡Muchas gracias! \nViva Negocio Inmobiliario'`,
    imagen: "https://dashboardbot.tecnicom.pe/assets/whatsapp/encuestaviva.png",
  },
];

const hours = [
  { value: "00", label: "00" },
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
];

const minutes = [
  { value: "00", label: "00" },
  { value: "15", label: "15" },
  { value: "30", label: "30" },
  { value: "45", label: "45" },
];

const dKasa = [
  {
    value: 0,
    label: `📍 Te presento el proyecto Capri se encuentra ubicado en Av. De Los Precursores 757 San Miguel a una cuadra de av. Elmer Faucett, cerca de av. Escardó  \n\n✅ 20 pisos  \n✅ Todos vista externa  \n✅Estacionamientos  \n✅Ascensores  \n✅Zona Zen  \n✅Zona de juegos para niños  \n✅Área de parrillas  \n\n🗓️ Fecha de entrega Julio del 2025  \n\n*Escríbame si le interesaría* 🤗`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/Capri.pdf",
  },
  {
    value: 1,
    label: `📍 Te presento el proyecto Residencia Grosseto se encuentra ubicado en Jirón Sucre 765 - San Miguel entre prolongación Ayacucho y prolongación Cuzco \n\n✅6 pisos más terraza \n✅estacionamientos \n✅SUM \n✅área de parrillas \n\n🗓️Entrega inmediata \n\n*Escríbeme si te interesaría* 🤗`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/GROSSETO.pdf",
  },
  {
    value: 2,
    label: `📍Te presento el proyecto Allegra se encuentra ubicado en Jr. Grau 355 - San Miguel cerca al Parque Media Luna y Municipalidad de San Miguel  \n\n✅9 pisos \n✅estacionamientos \n✅ascensor \n✅Área de parrillas y SUM \n\n🗓️ Fecha de entrega Enero 2025 \n\n*Escríbeme si te interesaría* 🤗`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/ALLEGRA.pdf",
  },
  {
    value: 3,
    label: `📍 Te presento el proyecto Residencial Brescia se encuentra ubicado en Jirón Américo Vespucio 357- San Miguel* entre la av. De los patriotas y la av. De La Marina.  \n\n✅7 pisos \n✅estacionamientos \n✅Zona de juegos para niños \n✅Zona de parrillas \n\n🗓️*entrega inmediata* \n\n*Escríbeme si te interesaría* 🤗`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/BRESCIA.pdf",
  },
  {
    value: 4,
    label: `📍Te presento nuestro proyecto Residencial Trieste, ubicado en Av. De Los Patriotas 431- San Miguel \n\n✅18 pisos más terraza \n✅Estacionamientos \n✅Zona de juegos para niños \n✅ Zona de parrillas \n\n🗓️Fecha de entrega es en Mayo 2024 \n\n*Escríbeme si te interesaría* 🤗`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/TRIESTE.pdf",
  },
  {
    value: 5,
    label: `📍Te presento nuestro proyecto Residencial Treviso, ubicado en Av. Los Patriotas 291 - San Miguel, a 2 cuadras de la  Av. Escardó, cerca de la UPC de La Marina. \n\n✅18 pisos \n✅Todos con vista externa \n✅Estacionamientos \n✅Zona de juegos para niños \n✅Zona de parrillas \n\n🗓️Fecha de entrega Febrero 2024 \n\n*Escríbeme si te interesaría* 🤗`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/TREVISO.pdf",
  },
  {
    value: 6,
    label: `📍 Te presento el proyecto Residencial Vernazza se encuentra ubicado en Av. coronel Andrés Razuri 364 - San Miguel \n\n✅18 pisos \n✅estacionamientos \n✅Zona de juegos para niños \n✅Zona de parrillas \n\n🗓️Fecha de entrega Mayo 2025 \n\n*Escríbeme si te interesaría* 🤗`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/VERNAZZA.pdf",
  },
  {
    value: 7,
    label: `📍 Te presento el proyecto Residencial Kora se encuentra ubicado en Jr. Pascual de Andagoya 393  - San Miguel \n\n✅Consta de 11 pisos. \n✅Todos con vista externa \n✅ estacionamientos \n\n🗓️Fecha de entrega Diciembre 2024 \n\n*Escríbeme si te interesaría* 🤗`,
    files: "https://dashboardbot.tecnicom.pe/assets/whatsapp/KORA.pdf",
  },
  {
    value: 15,
    label: `¡Hola!🤩 Bienvenido al lanzamiento de nuestra sala de ventas Magna✨. Te esperamos en nuestro evento presencial este sábado 24 de febrero, donde encontrarás descuentos y promociones exclusivas pensadas en ti😉. Por favor, confírmanos el horario de tu asistencia (entre las 4pm y 8pm) y ven conocer el depa de tus sueños🏡.  \n\n👉🏻 Cupos limitados`
  },
  {
    value: 16,
    imagen: 'https://dashboardbot.tecnicom.pe/assets/whatsapp/magna.png',
    label: 'Lanzamiento Magna',
  }
];  

const dKasaEdit = [
  {
    value: 8,
    label:
      "Hola {{1}} como esta, quería saber si llego a revisar la información del proyecto ?",
  },
  {
    value: 9,
    label: "{{1}} quería saber si todavía tendría interés {{2}}",
  },
  {
    value: 10,
    label: "{{1}} tendría alguna consulta {{2}} podría ayudarlo en algo mas ?",
  },
  {
    value: 11,
    label:
      "¡Hola {{1}}! cuéntame, ¿lograste revisar la información del proyecto?",
  },
  {
    value: 12,
    label: `'Hola {{1}}, que tal? \nHace un tiempo nos escribiste mostrando interés en el proyecto {{2}}, queríamos saber si aún sigues en la búsqueda de tu nuevo depa`
  },
  {
    value: 13,
    label: `Hola, {{1}} \nTe saluda {{2}}, asesora comercial de Dkasa 😊`
  },
  {
    value: 14,
    label: 'Hola {{1}} 🚨Te regalamos la cuota inicial de tu nuevo depa🥳. Visítanos este {{2}} en {{3}}. Donde podrás disfrutar de {{4}}. {{5}}',
  }
];

export {
  viva,
  iCasa,
  messageCacheDefault,
  sanCharbelMsg,
  sanCharbelEdit,
  grupoTycFlores,
  grupoTycMamani,
  grupoTycMamaniEdit,
  hours,
  minutes,
  tiempoCasa,
  tiempoCasaEdit,
  urbanProject,
  cantabria,
  cantabriaEdit,
  barqueros,
  barquerosEdit,
  dKasa,
  dKasaEdit,
};
