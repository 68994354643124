import React, { useCallback, useEffect, useState } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, Select } from '../../Components/Form/Form'

const QuotaForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_quota: data.id_quota,
        p_qty_quota: data.qty_quota,
        p_id_customers: data.id_customers
    })

    const [ready, setReady] = useState(false)
    const [customers, setCustomers] = useState([])
    useEffect(() => {
        Controllers.customers.get_customers_public()
        .then(res => {
            setReady(true)
            setCustomers(res.data)
        })
    }, [])

    const customer = useCallback(_ => {
        if (!row.p_id_customers) {
            return ''
        }

        let c = customers.find(c => parseInt(c.id_customers) === parseInt(row.p_id_customers))
        if (!c) {
            return ''
        }

        return {
            label: c.name_customers,
            value: c.id_customers
        }
    }, [row.p_id_customers, customers])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={10}>
        <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Select
                options={customers.map(c => {
                    return {
                        label: c.name_customers,
                        value: c.id_customers
                    }
                })}
                text="Cliente"
                classNameParent="col-12 mb-2"
                icon="far fa-building"
                onChange={e => setRow({ ...row, p_id_customers: e.value })}
                value={customer()}
                disabled={!isNaN(parseInt(data.id_customers))}
            />
            <Input
                text="Cantidad"
                placeholder="Cantidad"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.qty_quota}
                onChange={e => setRow({ ...row, p_qty_quota: e.currentTarget.value })}
                maxLength={255}
            />
        </FormCustom>
    </ReactPlaceholder>
}

QuotaForm.defaultProps = {
    data: {
        id_cuota: '',
        qty_quota: '',
        id_customers: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default QuotaForm;