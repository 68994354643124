import React, { useState, useEffect, useMemo } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ReactDataTableBootstrap from "../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap";
import Controllers from "../../Api/Controllers";
import Helpers from "../../Components/Helpers/Helpers";
import { FormCustom, Input, Select } from "../../Components/Form/Form";
import ReactPlaceholder from "react-placeholder/lib";
import { useSelector } from "react-redux";

const LeadChat = (_) => {
  const [isProcessing, setIsProccesing] = useState(true);
  const [reload, setReload] = useState(true);
  const [rows, setRows] = useState([]);
  const [ready, setReady] = useState(false);
  const [projects, setProjects] = useState([]);
  const [formFilter, setFormFilter] = useState({
    p_id_project: "",
    p_date_start: Helpers.date.get({ format: "%Y-%m-%d" }),
    p_date_end: Helpers.date.get({ format: "%Y-%m-%d" }),
  });

  const masterUser = useSelector((store) => store.session.master_user);

  const project = useMemo(() => {
    if (formFilter.p_id_project == "") {
      return {
        label: "Todas",
        value: "",
      };
    }

    let finded = projects.find(
      (j) => parseInt(j.id_project) === parseInt(formFilter.p_id_project)
    );

    if (!finded) {
      return {
        label: "Todas",
        value: "",
      };
    }

    return {
      label: finded.name_project,
      value: finded.id_project,
    };
  }, [formFilter.p_id_project, projects]);

  useEffect(() => {
    setFormFilter({
      p_id_project: "",
      p_date_start: Helpers.date.get({ format: "%Y-%m-%d" }),
      p_date_end: Helpers.date.get({ format: "%Y-%m-%d" }),
    });
    setReload(true);
  }, []);

  useEffect(() => {
    Controllers.project.get_project_public().then((res) => {
      setProjects([{ name_project: "Todas", id_project: "" }, ...res.data]);
      setReady(true);
    });
  }, []);

  useEffect(() => {
    if (reload) {
      // setShowButtonExcel(false)
      setIsProccesing(true);
      Controllers.lead.get_lead_multiagente(formFilter).then((res) => {
        setRows(res.data);
        /* setFile({
                    file: res.data.file,
                    filename: res.data.filename
                }) */
        // setShowButtonExcel(res.data.body.length > 0)
        setIsProccesing(false);
        setReload(false);
      });
    }
  }, [reload, formFilter]);

  const dtRows = useMemo(() => {
    return (
      <ReactDataTableBootstrap
        head={[rows?.head ? rows.head : []]}
        rows={
          rows?.body
            ? rows.body.map((r, i) => {
                for (const key in r) {
                  if (key.includes("tag_option_")) {
                    let findHead = rows.head.find((h) => h.name === key);

                    if (findHead?.options) {
                      r[key] = (
                        <Select
                          value={
                            r["select_" + key]
                              ? {
                                  value: r["select_" + key],
                                  label: r["select_" + key],
                                }
                              : ""
                          }
                          options={findHead.options.map((opt) => {
                            return {
                              value: opt.description_tag_det,
                              label: opt.description_tag_det,
                            };
                          })}
                          onChange={(e) => {
                            setRows({
                              ...rows,
                              body: rows.body.map((r2, i2) => {
                                if (i === i2) {
                                  r2["select_" + key] = e.value;
                                }
                                return r2;
                              }),
                            });

                            Controllers.chat.chat_form_update({
                              p_id_chat: r.id_chat,
                              p_key_name: key,
                              p_value: e.value,
                            });
                            /* setShowButtonExcel(false) */
                          }}
                        />
                      );
                    }
                  }
                }

                return r;
              })
            : []
        }
        isProcessing={isProcessing}
      />
    );
  }, [rows, isProcessing]);

  return (
    <>
      <Card className="mb-4">
        <Card.Header className="justify-content-between">
          <span>Filtrar Por Fechas</span>
        </Card.Header>
        <Card.Body>
          <ReactPlaceholder
            ready={ready}
            rows={5}
            showLoadingAnimation
            className="mb-4"
          >
            <FormCustom
              onSubmit={(_) => setReload(true)}
              viewSubmit={false}
              className="mb-4"
            >
              {/* <Select
                            icon="far fa-building"
                            classNameParent="col-12 mb-2"
                            text="Proyectos"
                            options={projects.map(p => {
                                return {
                                    label: p.name_project,
                                    value: p.id_project
                                }
                            })}
                            value={project}
                            onChange={e => setFormFilter({ ...formFilter, p_id_project: e.value })}
                        /> */}
              <Input
                icon="far fa-calendar-alt"
                text="Fecha Inicio"
                type="date"
                required
                classNameParent="col-12 col-md-6"
                value={formFilter.p_date_start}
                onChange={(e) =>
                  setFormFilter({
                    ...formFilter,
                    p_date_start: e.currentTarget.value,
                  })
                }
              />
              <Input
                icon="far fa-calendar-alt"
                text="Fecha Fin"
                type="date"
                required
                classNameParent="col-12 col-md-6"
                append={[
                  <Button type="submit">
                    <i className="fa fa-search mt-1"></i> Filtrar Información
                  </Button>,
                ]}
                value={formFilter.p_date_end}
                onChange={(e) =>
                  setFormFilter({
                    ...formFilter,
                    p_date_end: e.currentTarget.value,
                  })
                }
              />
            </FormCustom>
          </ReactPlaceholder>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header className="justify-content-between">
          <span>Leads De Multiagente</span>
          {
            <Button
              size="sm"
              variant="success"
              onClick={(_) => {
                Controllers.lead
                  .get_lead_multiagente_excel(formFilter)
                  .then((res) => {
                    const link = document.createElement("a");
                    link.download = res.data.filename;
                    link.href = res.data.file;
                    link.click();
                  });
              }}
            >
              <i className="fa fa-file-excel"></i> Descargar Reporte
            </Button>
          }
        </Card.Header>
        <Card.Body>{dtRows}</Card.Body>
      </Card>
    </>
  );
};

export default LeadChat;
