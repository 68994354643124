import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';

import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { FormCustom, Input } from '../../Components/Form/Form';
// import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
// import { toast } from 'react-toastify';
// import SweetAlert from '../../Components/SweetAlert/SweetAlert'
// import { Link, useParams } from 'react-router-dom';
// import ReactPlaceholder from 'react-placeholder/lib';
// import { useSelector } from 'react-redux';
// import { PieChart } from 'react-minimal-pie-chart';

const DashboardAdmin = _ => {
    const [rows, setRows] = useState([])
    const [ready, setReady] = useState(false)
    const [formFilter, setFormFilter] = useState({
        p_date_start: Helpers.date.get({ format: '%Y-%m-%d' }),
        p_date_end: Helpers.date.get({ format: '%Y-%m-%d' })
    })

    useEffect(() => {
        if (!ready) {
            Controllers.lead.get_lead_customers(formFilter).then(res => {
                setRows(res.data)
                setReady(true)
            })
        }
    }, [ready, formFilter])

    return <>
        <Card className="mb-4">
            <Card.Header className="justify-content-between">
                <span>Filtrar Por Fechas</span>
            </Card.Header>
            <Card.Body>
                <FormCustom onSubmit={_ => setReady(false)} viewSubmit={false} className="mb-4">
                    <Input
                        icon="far fa-calendar-alt"
                        text="Fecha Inicio"
                        type="date"
                        required
                        classNameParent="col-12 col-md-6"
                        value={formFilter.p_date_start}
                        onChange={e => setFormFilter({ ...formFilter, p_date_start: e.currentTarget.value })}
                    />
                    <Input
                        icon="far fa-calendar-alt"
                        text="Fecha Fin"
                        type="date"
                        required
                        classNameParent="col-12 col-md-6"
                        append={[
                            <Button type="submit">
                                <i className="fa fa-search mt-1"></i> Filtrar Información
                            </Button>
                        ]}
                        value={formFilter.p_date_end}
                        onChange={e => setFormFilter({ ...formFilter, p_date_end: e.currentTarget.value })}
                    />
                </FormCustom>
            </Card.Body>
        </Card>
        <div className="form-row">
            <div className="col-12 col-md-12">
                <Card>
                    <Card.Header>
                        Historial por Cliente
                    </Card.Header>
                    <Card.Body className="d-flex">
                    <div className="table-responsive">
                        <table className="dt-react table table-sm table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th className="bg-info">Leads Totales</th>
                                    <th className="bg-info">Leads Facebook</th>
                                    <th className="bg-info">Leads Web</th>
                                    <th className="bg-info">Leads Whatsapp</th>
                                    <th className="bg-info">Leads Instagram</th>
                                    <th className="bg-primary">Comentarios Totales</th>
                                    <th className="bg-primary">Comentarios ChatBot</th>
                                    <th className="bg-primary">Comentarios CM</th>
                                </tr>
                            </thead>
                            <tbody>
                                {React.Children.toArray(rows.map(r => {
                                    return <tr>
                                        <td className="text-center">{r.name_customers || 0}</td>
                                        <td className="text-center">{r.qty_leads_total || 0}</td>
                                        <td className="text-center">{r.qty_leads_facebook || 0}</td>
                                        <td className="text-center">{r.qty_leads_web || 0}</td>
                                        <td className="text-center">{r.qty_leads_whatsapp || 0}</td>
                                        <td className="text-center">{r.qty_leads_instagram || 0}</td>
                                        <td className="text-center">{r.qty_comments_total || 0}</td>
                                        <td className="text-center">{r.qty_comments_bot || 0}</td>
                                        <td className="text-center">{r.qty_comments_cm || 0}</td>
                                    </tr>
                                }))}
                            </tbody>
                        </table>
                    </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </>
}

export default DashboardAdmin